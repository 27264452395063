export enum CreationType {
  Web,
  IntegrationLayer,
  Plugin,
  WooCommerce,
  XL
}

export enum CreationTypeView {
  CreateWeb = 'Web UI Add product',
  UpdateeWeb = 'Web UI update product',
  CreateIntegrationLayer = 'I.L Add product',
  UpdateIntegrationLayer = 'I.L update product',
  CreateSO = 'Empact SO Add product',
  UpdateSO = 'Empact SO Add product',
  CreateXL = 'XL Add product',
  UpdateXL = 'XL Add product',
}