import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'eci-excel-info-popup',
  templateUrl: './excel-info-popup.component.html',
  styleUrls: ['./excel-info-popup.component.scss']
})
export class ExcelInfoPopupDialog {
  header: string = "Please confirm";
  listOfInfo: any;
  leftBtnText: string = 'Cancel';
  rightBtnText: string = 'Save';
  showRightBtn: boolean = true;

  constructor(
    public dialogRef: MatDialogRef<ExcelInfoPopupDialog>,
  ) {
  }
}
