import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSelect } from '@angular/material/select';
import { MatTableDataSource } from "@angular/material/table";
import { IOrderDetails, IProduct } from '../../../modules/customers-order-management/models/order-details.model';
import { ProductAllocation, ProductAllocationCollapsed } from '../../../modules/customers-order-management/models/product-allocation.interface';
import { ProductAllocationConfirmComponent } from '../../../modules/customers-order-management/components/order-details/order-package-details/product-allocation-confirm/product-allocation-confirm.component';

@Component({
  selector: 'eci-product-allocation-carton',
  templateUrl: './product-allocation-carton.component.html',
  styleUrls: ['./product-allocation-carton.component.scss']
})
export class ProductAllocationCartonComponent implements OnInit {
  cartonform!: FormGroup;
  productList!: IProduct[];
  cartonIndex: number;
  tableData: any;
  form: FormGroup;
  productAllocation: ProductAllocation[];
  userSelectedCarton: any = [];
  userSelectedProduct: any = [];

  displayedColumns: string[] = ['cartons', 'product_name', 'sku', 'not_allocated', 'allocated'];
  displayedColumns2: string[] = ['product_name', 'sku', 'not_allocated', 'allocated'];
  detailedArray: any[] = [];
  collapsedArray: any[] = [];
  collapsedView = false;

  dataSource;
  dataSourceCollapsed;
  detailedView: boolean;
  searchCarton: FormControl = new FormControl('');
  searchProduct: FormControl = new FormControl('');

  @ViewChild("productSelect") productElem: MatSelect;
  @ViewChild("cartonSelect") cartonElem: MatSelect;
  constructor(
    private formBuilder: FormBuilder,
    private dialog: MatDialog,
    public dialogRef: MatDialogRef<ProductAllocationCartonComponent>,
  ) { }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      cartonId: [],
      productId: [],
      totalCartons: []
    });

    this.form.get('cartonId').valueChanges.subscribe(val => {
      this.userSelectedCarton = val;
      this.filterData();
    });

    this.form.get('productId').valueChanges.subscribe(val => {
      this.userSelectedProduct = val;
      this.filterData();
    });

    this.inititalizeData();

    if (!this.detailedView) {
      this.collapsedView = true;
      this.populateData();
    }
  }

  filterData() {
    let newArrayData = this.detailedArray;
    if (this.userSelectedProduct.length > 0) {
      newArrayData = newArrayData.filter((data) => (this.userSelectedProduct !== '') && (this.userSelectedProduct.includes(data.product_id)));
    }
    if (this.userSelectedCarton.length > 0) {
      newArrayData = newArrayData.filter(data => (this.userSelectedCarton !== '') && (this.userSelectedCarton.includes(data.cartons)));
    }
    this.dataSource = newArrayData as ProductAllocation[];
  }

  inititalizeData() {
    this.tableData = this.cartonform.value.cartons;
    this.form.get("totalCartons").setValue(this.cartonform.value.cartons.length);

    if (this.productAllocation.length > 0) {
      // this.productAllocation.forEach((e: ProductAllocation) => {
      //   this.detailedArray.push({
      //     'cartons': e.cartons,
      //     'product_name': e.product_name,
      //     'product_id': e.product_id,
      //     'sku': e.sku,
      //     'total_qty': e.total_qty,
      //     "not_allocated": e.not_allocated,
      //     "allocated": e.allocated
      //   });
      // });

      this.productList.forEach((elepro: any) => {
        this.tableData.forEach((eleData: any, index: any) => {
          const existProductAllocation = this.productAllocation.filter((data) => ((Number(data.cartons) == index) && (Number(data.product_id) == Number(elepro.productId))));
          const singleProductAllocation = this.productAllocation.filter((data) => (Number(data.product_id) == Number(elepro.productId)));

          if (existProductAllocation[0]) {
            let tolQty = 0;
            let notAlcted = 0;
            if (existProductAllocation[0]?.total_qty) {
              if (elepro.units > existProductAllocation[0].total_qty) {
                tolQty = elepro.units;
                notAlcted = elepro.units - existProductAllocation[0].allocated;
              } else {
                tolQty = existProductAllocation[0].total_qty;
                notAlcted = existProductAllocation[0].not_allocated;
              }
            } else {
              tolQty = elepro.units;
              notAlcted = elepro.units;
            }
            this.detailedArray.push({
              'cartons': existProductAllocation[0].cartons,
              'product_name': existProductAllocation[0].product_name,
              'product_id': existProductAllocation[0].product_id,
              'sku': existProductAllocation[0].sku,
              'total_qty': tolQty,
              'not_allocated': notAlcted,
              'allocated': existProductAllocation[0].allocated
            });
          } else {
            let notAlcted = 0;
            if (singleProductAllocation[0]) {
              let totalAlcted = 0;
              singleProductAllocation.forEach((sngPrdAlct: any) => {
                totalAlcted += Number(sngPrdAlct.allocated);
              });
              notAlcted = elepro.units - totalAlcted;
            } else {
              notAlcted = elepro.units;
            }
            this.detailedArray.push({
              'cartons': index,
              'product_name': elepro.description,
              'product_id': elepro.productId,
              'sku': elepro.itemNumber,
              'total_qty': elepro.units,
              'not_allocated': notAlcted,
              'allocated': 0
            });
          }
        });
      });
    }
    else {
      this.productList.forEach((elepro: any) => {
        this.tableData.forEach((eleData: any, index: any) => {
          this.detailedArray.push({
            'cartons': index,
            'product_name': elepro.description,
            'product_id': elepro.productId,
            'sku': elepro.itemNumber,
            'total_qty': elepro.units,
            "not_allocated": elepro.units,
            "allocated": 0
          });
        });
      });
    }

    this.dataSource = this.detailedArray as ProductAllocation[];
    this.dataSourceCollapsed = this.collapsedArray as ProductAllocationCollapsed[];

    this.userSelectedCarton = [this.cartonIndex];
    this.form.get("cartonId").setValue(this.userSelectedCarton);
    this.filterData();
  }
  // WTF: need update logic
  onChangeProductQty(event, data) {
    const index2 = this.detailedArray.indexOf(data);
    // When product_id undefined faced issue https://github.com/Zangula/ECommIn_project_management/issues/4035
    const data2 = this.detailedArray.filter(pro => {
      return !!data?.product_id && pro.product_id == data.product_id;
    })
    if (event.target.value) {
      if ((event.target.value - data.allocated) > data.not_allocated) {
        event.target.value = data.allocated;
        return;
      }
      const index2 = this.detailedArray.indexOf(data);
      if (index2 != -1) {
        this.detailedArray[index2].allocated = event.target.value;
      }
      if (data2.length > 0) {
        let allocated = 0;
        data2.map(item => {
          allocated = allocated + parseInt(item.allocated);
        })

        for (let i = 0; i < data2.length; i++) {
          const index = this.detailedArray.indexOf(data2[i])
          if (index != -1) {
            if (this.detailedArray[index].total_qty != this.detailedArray[index].not_allocated) {
              this.detailedArray[index].not_allocated = this.detailedArray[index].total_qty - allocated;
            }
            else {
              // when user press enter 0
              this.detailedArray[index].not_allocated = this.detailedArray[index].total_qty - event.target.value;
            }
          }
        }
      }
    } else {
      // when user remove value
      const index2 = this.detailedArray.indexOf(data);
      if (index2 != -1) {
        this.detailedArray[index2].allocated = 0;
      }
      if (data2.length > 0) {
        let allocated = 0;
        data2.map(item => {
          allocated = allocated + parseInt(item.allocated)
        })
        for (let i = 0; i < data2.length; i++) {
          const index = this.detailedArray.indexOf(data2[i]);
          if (index != -1) {
            console.log(index)
            this.detailedArray[index].not_allocated = this.detailedArray[index].total_qty - allocated;
          }
        }
      }
    }
  }

  onChangeState(event) {
    this.collapsedView = event.checked;
    this.populateData();
  }

  populateData() {
    this.collapsedArray = [];
    this.productList.forEach((elepro: any) => {
      var allocated = 0;
      var notAllocated = 0;
      this.detailedArray.forEach(element => {
        if (elepro.productId == element.product_id) {
          notAllocated = element.not_allocated;
          allocated += Number(element.allocated);
        }
      });
      this.collapsedArray.push({
        'product_name': elepro.description,
        'sku': elepro.itemNumber,
        "not_allocated": notAllocated,
        "allocated": allocated
      });
    });
  }

  save(): void {
    let isAllocated = []
    this.detailedArray.map(item => {
      if (item.not_allocated > 0) {
        isAllocated.push(item);
      }
    });
    if (isAllocated.length) {
      const dialogRef = this.dialog.open(ProductAllocationConfirmComponent, {
        maxHeight: '600px',
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.dialogRef.close({ type: 'save', data: this.detailedArray });
        }
      });
    } else {
      this.dialogRef.close({ type: 'save', data: this.detailedArray });
    }
  }

  exit(): void {
    this.dialogRef.close({ type: 'close', data: this.detailedArray });
  }
}
