import { Pipe, PipeTransform } from '@angular/core';
import { IConstantItem } from 'src/app/core/models/available-types.interface';
import * as _ from 'lodash';
import { ShipmentType } from '../enums/shipment-type.enum';

@Pipe({ name: 'carrierType' })
export class CarrierTypePipe implements PipeTransform {
    transform(value: any, array: IConstantItem[]) {
        switch (value) {
            case ShipmentType.Air: return 'Carrier Air';
            case ShipmentType.Ocean: return 'Carrier Ocean';
            case ShipmentType.CourierExpress: return 'Courier';
            case ShipmentType.Inland: return 'Inland';
        }
    }
}