import {DatePipe} from '@angular/common';
import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {MatCheckboxChange} from '@angular/material/checkbox';
import {Sort} from '@angular/material/sort';
import {Router} from '@angular/router';
import {PageParams} from '../../../../core/models/page-params.model';
import {SaleOrderInvoiceType} from '../../../../shared/enums/sale-order-invoice-type.enum';
import {EInvManagerTabType} from '../../invoice-management.service';

@Component({
    selector: 'eci-inv-man-table-sale-order',
    templateUrl: './inv-man-table-sale-order.component.html',
    styleUrls: ['./inv-man-table-sale-order.component.scss']
})
export class InvManTableSaleOrderComponent implements OnInit {

    @Input() listOfSelectedItems = [];
    @Input() dataSource;
    @Input() type: EInvManagerTabType;
    @Input() invoiceTypes = [];
    @Input() sumValues;
    @Input() displayedColumns;
    @Input() pageParams: PageParams = new PageParams();
    @Input() statuses = [];

    @Output() pageChange: EventEmitter<PageParams> = new EventEmitter<PageParams>(null);
    @Output() sortRows: EventEmitter<Sort> = new EventEmitter<Sort>(null);
    @Output() action: EventEmitter<any> = new EventEmitter<any>(null);

    @ViewChild('table', { static: true, read: ElementRef }) table: ElementRef;

    constructor(
        private datePipe: DatePipe,
        private router: Router,
    ) {}

    ngOnInit(): void {
    }

    pageChanged(e): void {
        this.pageChange.emit(e);
        this.table.nativeElement.scrollIntoView();
    }

    sortData(e: Sort): void {
        this.sortRows.emit(e);
    }

    dateConvert(e: any): string {
        if (e.order) {
            return this.datePipe.transform(new Date(e.order.createdAt).toISOString().slice(0, 16), 'MM/dd/yyyy');
        }
        if (e.saleOrder) {
            return this.datePipe.transform(new Date(e.saleOrder.orderDate).toISOString().slice(0, 16), 'MM/dd/yyyy');
        }
        if (e.shipmentConsol) {
            return this.datePipe.transform(new Date(e.shipmentConsol.createdAt).toISOString().slice(0, 16), 'MM/dd/yyyy');
        }

    }

    navigateTo(id) {

    }

    getIMType(typeIM: any) {
        switch (this.type) {
            case EInvManagerTabType.so:
                switch (typeIM) {
                    case 'b2c': return 'B2C SO';
                    case 'b2b': return 'B2B SO';
                    case 'reportingTool': return 'Reporting Tool SO';
                }
                break;
            case EInvManagerTabType.shipment:
                return 'Shipment';
            case EInvManagerTabType.consolidate_shipment:
                return 'Consol Shipment';
        }
    }

    getOrderNumber(element) {
        switch (this.type) {
            case EInvManagerTabType.so:
                return element.saleOrder?.orderId;
            case EInvManagerTabType.shipment:
                return element.order?.orderId;
            case EInvManagerTabType.consolidate_shipment:
                return element.shipmentConsol?.consolNumber;
        }
    }

    navigateToOrder(e) {
        switch (this.type) {
            case EInvManagerTabType.so:
                this.router.navigateByUrl(`/sales-order-management/order-details/${e.saleOrder?.id}`);
                break;
            case EInvManagerTabType.shipment:
                this.router.navigateByUrl(`/customers-order/order-details/${e.order?.id}`);
                break;
            case EInvManagerTabType.consolidate_shipment:
                this.router.navigateByUrl(`/consolidate-shipments/consol-shipment-details/${e.shipmentConsol?.id}`);
                break;
        }
    }

    isChecked(id: number): boolean {
        return !!this.listOfSelectedItems.find(el => el.id === id);
    }

    selectionChanged(event: MatCheckboxChange, item): void {
        if (event.checked) {
            if (!this.isChecked(item.id)) {
                this.listOfSelectedItems.push(
                    {
                        id: item.id,
                        type: item.hasOwnProperty('type') ? SaleOrderInvoiceType[item.type] : SaleOrderInvoiceType[item.invoiceType]
                    }
                );
                console.log(this.listOfSelectedItems);
                console.log(item.type);
            }
        } else {
            if (this.isChecked(item.id)) {
                this.listOfSelectedItems.splice(this.listOfSelectedItems.findIndex(el => el.id === item.id), 1);
            }
        }
    }

    navigateToCompany(e) {

    }
}
