import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from '../../../core/services/api.service';

@Injectable()
export class PortsService extends ApiService {

    getOriginPorts(page: number = 1, limit: number = 100, q: string = '', orderType?: number): Observable<any> {
        return this.request({
            method: 'POST',
            path: `ports/getOriginPorts`,
            body: {
                portName: q,
                limit: limit,
                page: page,
                orderType: orderType
            }
        });
    }

    getDestinationPorts(page: number = 1, limit: number = 100, q: string = '', orderType?: number): Observable<any> {
        return this.request({
            method: 'POST',
            path: `ports/getDestinationPorts`,
            body: {
                portName: q,
                limit: limit,
                page: page,
                orderType: orderType
            }
        });
    }

    getOriginAirports(page: number = 1, limit: number = 100, q: string = '', orderType?: number): Observable<any> {
        return this.request({
            method: 'POST',
            path: `air-ports/getOriginPorts`,
            body: {
                portName: q,
                limit: limit,
                page: page,
                orderType: orderType
            }
        });
    }

    getDestinationAirports(page: number = 1, limit: number = 100, q: string = '', orderType?: number): Observable<any> {
        return this.request({
            method: 'POST',
            path: `air-ports/getDestinationPorts`,
            body: {
                portName: q,
                limit: limit,
                page: page,
                orderType: orderType
            }
        });
    }
}