import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {AvailablePackageInterface} from '../models/available-package.interface';
import {ApiService} from './api.service';
import {LocalStorageService} from './local-storage.service';
import {IAvailableTypes} from './../models/available-types.interface';
import {BehaviorSubject, Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {ICountry} from '../models/country.interface';
import {IAvailableCourierItem} from '../models/available-courier.interface';

@Injectable({providedIn: 'root'})
export class ConstantService extends ApiService {

    public availableTypes: BehaviorSubject<IAvailableTypes> = new BehaviorSubject<IAvailableTypes>(null);
    public availablePackages: BehaviorSubject<AvailablePackageInterface[]> = new BehaviorSubject<AvailablePackageInterface[]>(null);
    public availableCountries: BehaviorSubject<ICountry[]> = new BehaviorSubject<ICountry[]>(null);
    public availablePhoneCodes: BehaviorSubject<any[]> = new BehaviorSubject<any[]>(null);
    public availableCourier$: BehaviorSubject<IAvailableCourierItem[]> = new BehaviorSubject<IAvailableCourierItem[]>(null);
    private availablePhoneCodesCache = null;
    private availableCountriesCache = null;
    private availableTypesCache = null;

    private isLoadingTypes = false;

    constructor(
        public http: HttpClient,
        public localStorage: LocalStorageService,
    ) {
        super(http, localStorage);
        this.setAvailableTypes();
        this.setAvailableCountries();
        this.setAvailablePackages();

        this.getCouriersList();
    }

    setAvailableTypes(): void {
        this.request({
            path: `settings/configs`,
            method: 'GET'
        }).subscribe(data => {
            if (!data) {
                return;
            }
            this.availableTypes.next(data.constants);
        });
    }

    setAvailablePackages(): void {
        this.request({
            path: `packages`,
            method: 'GET'
        }).subscribe(data => {
            if (!data) {
                return;
            }
            this.availablePackages.next(data);
        });
    }

    setAvailableCountries(page: number = 1, limit: number = 1000, q: string = ''): void {
        this.request({
            path: `countries`,
            method: 'GET',
            query: {
                page: page,
                limit: limit,
                q: q
            }
        }).subscribe(data => {
            if (!data) {
                return;
            }
            // this.availableCountriesCache = data;
            this.availableCountries.next(data.items);
        });
    }

    getAvailableTypes(): void {
        if (this.availableTypesCache && this.isLoadingTypes === false) {
            this.availableTypes.next(this.availableTypesCache);
        } else {
            if (this.isLoadingTypes === false) {
                this.isLoadingTypes = true;
                this.request({
                    path: `settings/configs`,
                    method: 'GET'
                }).subscribe(data => {
                    this.isLoadingTypes = false;
                    if (!data) {
                        return;
                    }
                    this.availableTypes.next(data.constants);
                    this.availableTypesCache = data.constants;
                });
            }
        }
    }

    getAvailableTContainers(): Observable<any> {
        return this.request({
            path: `settings/container-types`,
            method: 'GET'
        });
    }

    getMothlySubscriptonDiscount(id ?: number): Observable<any> {
        return this.request({
            path: `subscription-orders/subscription-monthly-fees/${id} `,
            method: 'GET',
        });
    }

    getAvailablePhoneCodes(page: number = 1, limit: number = 1000, q: string = ''): Observable<any> {
        return this.request({
            path: `countries`,
            method: 'GET',
            query: {
                phoneCodes: true,
                page: page,
                limit: limit,
                q: q
            }
        });
    }

    getCouriersList(page: number = 1, limit: number = 1000, q: string = ''): void {
        this.request({
            path: `couriers`,
            method: 'GET',
            query: {
                page: page,
                limit: limit,
                q: q
            }
        }).subscribe(data => {
            if (!data) {
                return;
            }
            this.availableCourier$.next(data);
        });
    }

    getAvailableCountries(page: number = 1, limit: number = 1000, isEurope: boolean = false, withTaxRate: boolean = false, q: string = '', isFBA: boolean = false): Observable<any> {
        return this.request({
            path: `countries`,
            method: 'GET',
            query: {
                page: page,
                limit: limit,
                q: q,
                isEurope: isEurope,
                withTaxRate: withTaxRate,
                isFBA: isFBA,
            }
        }).pipe(map(el => el.items));
    }

    getAvailableCountriesForScroll(page: number = 1, limit: number = 1000, isEurope: boolean = false, q: string = '', withTaxRate: boolean = false): Observable<any> {
        return this.request({
            path: `countries`,
            method: 'GET',
            query: {
                page,
                limit,
                q,
                isEurope: isEurope,
                withTaxRate,
                phoneCodes: false
            }
        });
    }

    getPrefixCountries(page: number = 1, limit: number = 1000, q: string = ''): Observable<any> {
        return this.request({
            path: `countries/get-prefix-countries`,
            method: 'GET',
            query: {
                page,
                limit,
                q
            }
        });
    }

    setAvailablePhoneCodes(page: number = 1, limit: number = 1000, q: string = ''): void {
        this.request({
            path: `countries`,
            method: 'GET',
            query: {
                phoneCodes: true,
                page: page,
                limit: limit,
                q: q
            }
        }).subscribe(data => {
            if (!data) {
                return;
            }
            this.availablePhoneCodesCache = data;
            this.availablePhoneCodes.next(data);
        });
    }

    getAvailableStatesById(id: number | string, page: number = 1, limit: number = 1000, q: string = ''): Observable<any> {
        return this.request({
            path: `countries/${id}/states`,
            method: 'GET',
            query: {
                code: id,
                page: page,
                limit: limit,
                q: q
            }
        }).pipe(map(el => el.items));
    }

    getSettingsDetails(name: string): Observable<any> {
        return this.request({
            path: `settings/get-settings-details`,
            method: 'POST',
            body: {
                name
            }
        });
    }

    saveSettings(body): Observable<any> {
        return this.request({
            path: `settings/create-update-settings`,
            method: 'POST',
            body: body
        });
    }

    getMonthlyFees(value): Observable<any> {
        return this.request({
            path: `countries/monthly_fees`,
            method: 'GET',
            query: {
                id: value
            }
        }).pipe(map(el => el));

    }
}
