import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {FilterPipe} from './pipes/filter.pipe';
import { EmpactShopService } from './services/empact-shop.service';
import { UserService } from './services/user.service';
import { LocalStorageService } from './services/local-storage.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AuthService } from './services/auth.service';

@NgModule({
  declarations: [
      FilterPipe,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule
  ],
  providers: [
    UserService,
    EmpactShopService,
    LocalStorageService,
    AuthService
  ],
  exports: [

  ]
})
export class CoreModule { }
