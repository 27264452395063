import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from 'src/app/core/services/api.service';
import { map } from 'rxjs/operators';
import { IContactInfo } from '../models/contact-info';
import { saveAs } from 'file-saver';
import { HttpHeaders } from '@angular/common/http';

@Injectable()
export class CustomerManagementService extends ApiService {

    //#region  CONATACTS
    
    getAllContacts(page: number = 1, limit: number = 100, q: string = '', contactableId?, orderField: string = '', orderDirection: string = ''): Observable<any> {
        if (!contactableId) contactableId = '';
        return this.request({
            path: `contacts`,
            method: 'GET',
            query: { 
                page: page,
                limit: limit,
                contactableId: contactableId,
                orderField: orderField,
                orderDirection: orderDirection
            }
        });
    }

    getAllCompanyContacts(page: number = 1, limit: number = 100, q: string = '', contactableId?, orderField: string = '', orderDirection: string = ''): Observable<any> {
        if (!contactableId) contactableId = '';
        return this.request({
            path: `company-contacts`,
            method: 'GET',
            query: { 
                page: page,
                limit: limit,
                contactableId: contactableId,
                orderField: orderField,
                orderDirection: orderDirection
            }
        });
    }

    getContactById(id: number): Observable<IContactInfo> {
        return this.request({
            path: `contacts/${id}`,
            method: 'GET',
        });
    }
    getCompanyContactById(id: number): Observable<IContactInfo> {
        return this.request({
            path: `company-contacts/${id}`,
            method: 'GET',
        });
    }

    addContact(body): Observable<any> {
        return this.request({
            path: `contacts`,
            method: 'POST',
            body: body
        });
    }

    addCompanyContact(body): Observable<any> {
        return this.request({
            path: `company-contacts`,
            method: 'POST',
            body: body
        });
    }

    updateCompanyContact(id: number, body): Observable<any> {
        return this.request({
            path: `company-contacts/${id}`,
            method: 'PUT',
            body: body
        });
    }

    deleteCompanyContact(id: number): Observable<any> {
        return this.request({
            path: `company-contacts/${id}`,
            method: 'DELETE',
        });
    }

    updateContact(id: number, body): Observable<any> {
        return this.request({
            path: `contacts/${id}`,
            method: 'PUT',
            body: body
        });
    }

    deleteContact(id: number): Observable<any> {
        return this.request({
            path: `contacts/${id}`,
            method: 'DELETE',
        });
    }

    //#endregion

    //#region WAREHOUSES
    
    getWareHouses(page: number = 1, limit: number = 100, q: string = '', companyId?: number, orderField: string = '', orderDirection: string = ''): Observable<any> {
        return this.request({
            path: `warehouses`,
            method: 'GET',
            query: {
                page: page,
                limit: limit,
                q: q,
                companyId: companyId ? companyId : '',
                orderField: orderField,
                orderDirection: orderDirection
            }
        }).pipe(map(el => el.items));
    }


    getWareHousesForScroll(page: number = 1, limit: number = 100, q: string = '', companyId?: number, orderField: string = '', orderDirection: string = ''): Observable<any> {
        return this.request({
            path: `warehouses`,
            method: 'GET',
            query: {
                page: page,
                limit: limit,
                q: q,
                companyId: companyId ? companyId : '',
                orderField: orderField,
                orderDirection: orderDirection
            }
        });
    }

    getWareHousesByOrderType(companyId: number, orderType: number): Observable<any> {
        return this.request({
            path: `warehouses/ordertype`,
            method: 'POST',
            body: {
                type: orderType,
                companyId: companyId
            }
        });
    }

    getWareHousesById(id: number): Observable<any> {
        return this.request({
            path: `warehouses/${id}`,
            method: 'GET',            
        })
    }

    addWareHouses(body): Observable<any> {
        return this.request({
            path: 'warehouses',
            method: 'POST',
            body: body            
        })
    }

    deleteWareHouses(id: number): Observable<any> {
        return this.request({
            path: `warehouses/${id}`,
            method: 'DELETE',
            
        })
    }

    updateWareHouses(id: number, body): Observable<any> {
        return this.request({
            path: `warehouses/${id}`,
            method: 'PUT',
            body: body            
        })
    }

    //#endregion
    
    //#region STRIPE-DETAILS
    
    addStripeAccount(body): Observable<any> {
        return this.request({
            path: `payments/add-account`,
            method: 'POST',
            body: body
        });
    }

    getStripeAccountDetail(companyId: number): Observable<any> {
        return this.request({
            path: `payments/get-stripe-account-detail`,
            method: 'POST',
            body: {
                companyId: companyId
            }
        });
    }

    createStripePaymentIntent(companyId: number, subscriptionOrderId: number = 0, isFirstTimeRegister: boolean = false, isRegistrationFeesRepay: boolean = false, monthlyCharges?: any): Observable<any> {
        // newPaymentRequest is used to update the payment or create a new payment request
        return this.request({
            path: `payments/create-stripe-payment-intent`,
            method: 'POST',
            body: {
                companyId: companyId,
                subscriptionOrderId: subscriptionOrderId,
                isFirstTimeRegister: isFirstTimeRegister,
                isRegistrationFeesRepay: isRegistrationFeesRepay,
                monthlyFeeCharge:monthlyCharges
            }
        });
    }

    createStripePartialPaymentIntent(companyId: number, partnerId: number, packageName: string, existingCustomer: boolean, isFirstTimeRegister: boolean = false): Observable<any> {
        // newPaymentRequest is used to update the payment or create a new payment request
        return this.request({
            path: `payments/create-stripe-partial-payment-intent`,
            method: 'POST',
            body: {
                companyId: companyId,              
                isFirstTimeRegister: isFirstTimeRegister,
                partnerId: partnerId,
                existingCustomer: existingCustomer,
                packageName
            }
        });
    }

    changePartialPaymentStatus(body): Observable<any> {
        return this.request({
            path: `payments/change-stripe-partial-payment-status`,
            method: 'POST',
            body: body
        });
    }

    autoPayMonthlyubscriptionFees(body): Observable<any> {
        // newPaymentRequest is used for auto pay
        return this.request({
            path: `payments/auto-pay-stripe-monthly-fees`,
            method: 'POST',
            body
        });
    }

    changePaymentStatus(body): Observable<any> {
        return this.request({
            path: `payments/change-stripe-payment-status`,
            method: 'POST',
            body: body
        });
    }

    removeSuscriptionOrder(id: number): Observable<any> {
        return this.request({
            path: `subscription-orders/remove/subscription-order/${id}`,
            method: 'DELETE',
        });
    }
    //#endregion

    getClientSecretForTestingPurpose(amount: number): Observable<any> {
        // Get the client-secret
        return this.request({
            path: `payments/get-client-secret-from-stripe`,
            method: 'POST',
            body: {
                amount
            }
        });
    }

    //#region PAYONEER-DETAILS
    
    addPayoneerAccount(body): Observable<any> {
        return this.request({
            path: `payoneer/create-registration-link`,
            method: 'POST',
            body: body
        });
    }

    savePayoneerAccountPayeeId(body): Observable<any> {
        return this.request({
            path: `payoneer/save-payoneer-account-id`,
            method: 'POST',
            body: body
        });
    }

    deletePayoneerAccountPayeeId(body): Observable<any> {
        return this.request({
            path: `payoneer/delete-payoneer-account-id`,
            method: 'POST',
            body: body
        });
    }

    getDeletedPayoneerAccounts(body): Observable<any> {
        return this.request({
            path: `payoneer/payoneer-deleted-account-history`,
            method: 'POST',
            body: body
        });
    }

    restoreDeletedPayoneerAccounts(body): Observable<any> {
        return this.request({
            path: `payoneer/restore-payoneer-deleted-account`,
            method: 'POST',
            body: body
        });
    }

    payoneerStatement(body): Observable<any> {
        return this.requestPdf({
            method: 'POST',
            path: `company/payoneer-statement/generate-pdf`,
            body: body
        }).pipe(map(res => {
            const fileURL = URL.createObjectURL(res);
            window.open(fileURL, '_blank');
        }));
    }

    viewPayoneerStatement(body): Observable<any> {
        return this.request({
            path: `company/payoneer-statement/view-generated-pdf`,
            method: 'POST',
            body: body
        }).pipe(map(res => {
            if (res?.file) {
                window.open(`${res.file}`, '_blank');
            } else {
                const fileURL = URL.createObjectURL(res);
                window.open(fileURL, '_blank');
            }
        }));
    }

    downloadPayoneerStatement(body): Observable<any> {
        return this.request({
            path: `company/payoneer-statement/view-generated-pdf`,
            method: 'POST',
            body: body
        }).pipe(map(res => {
            if (res?.file) {
                
                const headers = new HttpHeaders();
                headers.append('Accept', '*').append('responseType', 'application/pdf').append('Access-Control-Allow-Origin','*');
                this.http.get(res?.file, { headers: headers, responseType: 'blob' }).subscribe(response => {
                    const blob = new Blob([response], { type: 'application/pdf' });
                    saveAs(blob, 'payoneer_statements.pdf');
                });
            } else {
                const fileURL = URL.createObjectURL(res);
                window.open(fileURL, '_blank');
            }
        }));
    }

    sendPayoneerStatement(body): Observable<any> {
        return this.request({
            path: `company/payoneer-statement/send-statement`,
            method: 'POST',
            body: body
        });
    }

    getPayoneerAccountDetail(companyId: number): Observable<any> {
        return this.request({
            path: `payoneer/get-payoneer-account-detail`,
            method: 'POST',
            body: {
                companyId: companyId
            }
        });
    }

    setPayoneerPayout(body): Observable<any> {
        return this.request({
            path: `payoneer/set-mass-payout`,
            method: 'POST',
            body: body
        });
    }

    FindStripePayout(companyId: number): Observable<any>{
        return this.request({
            path: `company-marketplaces/shops/${companyId}`,
            method: 'GET'
        });
    }
    setStripePayout(body): Observable<any> {
        return this.request({
            path: `payments/set-mass-payout`,
            method: 'POST',
            body: body
        });
    }
    //#endregion

    getLoginLink(returnUrl: string, customerId: number): Observable<any> {
        return this.request({
            path: `payments/login-connected-account`,
            method: 'POST',
            body: {
                returnUrl: returnUrl,
                customerId: customerId
            }
        });
    }
    
    setPayoneerPayoutFromReport(body): Observable<any> {
        return this.request({
            path: `payoneer/set-mass-payout-from-reports`,
            method: 'POST',
            body: body
        });
    }
}
