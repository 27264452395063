import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserService } from 'src/app/core/services/user.service';
import { NotificationService } from 'src/app/core/services/notification.service';
import { CouponsService } from 'src/app/core/services/coupons.service';

@Component({
  selector: 'app-coupon-apply-popup',
  templateUrl: './coupon-apply-popup.component.html',
  styleUrls: ['./coupon-apply-popup.component.scss']
})
export class ApplyCouponDialog implements OnInit {

  form: FormGroup;
  header: string = "Apply Coupon";

  constructor(
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<ApplyCouponDialog>,
    public userSvc: UserService,
    private notify: NotificationService,
    private couponService: CouponsService
  ) {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnInit(): void {
    this.form = this.initializeForm();
  }

  onSubmit(): void {
    if (this.form.invalid) return;
    this.applyCoupon();
  }

  applyCoupon(): void {
    if (!this.form.valid) {
      this.notify.showError('Not all fields are feed');
      return;
    }

    this.couponService.applyCoupon(this.form.value).subscribe(() => {
      this.notify.showSuccessMessage('Coupon applied successfully');
      this.dialogRef.close();
    }, err => {
      this.notify.showError(err?.error?.message);    
    });
  }

  initializeForm(): FormGroup {
    return this.formBuilder.group({
      couponCode: [null, [Validators.required]],
    });
  }
}
