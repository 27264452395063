import { Component, OnInit } from '@angular/core';
import {InvoiceTemplateBase} from '../invoce-template-base';

@Component({
  selector: 'eci-shipping-invoice-form',
  templateUrl: './shipping-invoice-form.component.html',
  styleUrls: ['./shipping-invoice-form.component.scss']
})
export class ShippingInvoiceFormComponent extends InvoiceTemplateBase implements OnInit {

  ngOnInit(): void {
      this.model = {
          ...this.template,
          ...this.invoice,
          customerCompany: {
              ...this.template.customerCompany,
              ...this.invoice.customerCompany
          }
      };
  }

}
