import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Observable } from 'rxjs';
import { IAgentInfo } from './../models/agent.interface';

@Injectable()
export class AgentService extends ApiService {
    getAllAgents(page: number = 1, limit: number = 100, q: string = '', contactableId?, orderField: string = '', orderDirection: string = ''): Observable<any> {
        if (!contactableId) contactableId = '';
        return this.request({
            path: `agents`,
            method: 'GET',
            query: { 
                page: page,
                limit: limit,
                companyId: contactableId,
                orderField: orderField,
                orderDirection: orderDirection
            }
        });
    }

    getAgentById(id: number): Observable<IAgentInfo> {
        return this.request({
            path: `agents/${id}`,
            method: 'GET',
        });
    }

    addAgent(body): Observable<any> {
        return this.request({
            path: `agents`,
            method: 'POST',
            body: body
        });
    }

    updateAgent(id: number, body): Observable<any> {
        return this.request({
            path: `agents/${id}`,
            method: 'PUT',
            body: body
        });
    }

    deleteAgent(id: number): Observable<any> {
        return this.request({
            path: `agents/${id}`,
            method: 'DELETE',
        });
    }
}