import { Component, Input, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { UserService } from '../../../core/services/user.service';
import { SaleOrderInvoiceType } from 'src/app/shared/enums/sale-order-invoice-type.enum';

@Component({
  selector: 'sale-order-invoice-edit-form',
  templateUrl: './sale-order-invoice-edit-form.component.html',
  styleUrls: ['./sale-order-invoice-edit-form.component.scss']
})
export class SaleOrderInvoiceEditForm implements OnInit {

  @Input() data;
  @Input() customerCompany;
  isSaleOrderB2B: boolean = false;
  isCommercialInvoice: boolean = false;
  commercialInvoiceType: any = SaleOrderInvoiceType.COMMERCIAL_INVOICE;

  constructor(
    public dialogRef: MatDialogRef<SaleOrderInvoiceEditForm>,
    public userSvc: UserService
  ) {

  }

  ngOnInit(): void {
    if (this.data) {
      this.isSaleOrderB2B = this.data.isB2BSaleOrder;
      this.isCommercialInvoice = (this.data.isB2BSaleOrder && this.data.invoiceType == SaleOrderInvoiceType.COMMERCIAL_INVOICE);
    }
  }

  onSubmit(): void {
    this.dialogRef.close(this.data);
  }

    // The invoice is calculated based on the sales price and needs to be updated according to the cost price
    public updateProductSalePrice(product): void {
        product.price = product.discount > 0 ?
            (product.costPriceUnit - (product.costPriceUnit / 100 * product.discount)) :
            product.costPriceUnit;
    }
}
