export enum SaleOrderInvoiceType {
    IMPORT_INVOICE,
    DOMESTIC_INVOICE,
    EXPORT_INVOICE,
    ReturnGoodsOrder,
    COMMERCIAL_INVOICE,
    VAT,
    PROFORMA_INVOICE,
    SELL_INVOICE,
    IMPORT_INVOICE_AIR,
    EXPORT_INVOICE_AIR,
    DOMESTIC_INVOICE_AIR,
    MONTHLY_SUBSCRIPTION_PAYMENT,
    REGISTRATION_FEES,
    CANCEL_NOTE,
    FINAL_PURCHASE_INVOICE,
    SHIPPING_INVOICE,
    SERVICE_INVOICE,
    CONSOL_SHIPMENT_VAT_INVOICE,
}

