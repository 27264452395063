import { Injectable } from '@angular/core';
import { ICategoryMarketplace } from '../../../core/models/shop-category.interface';
import { ApiService } from '../../../core/services/api.service';
import { Observable } from 'rxjs';

@Injectable()
export class ShopCategoriesService extends ApiService {

    private controllerPath = 'categories-marketplace';

    getAllMainCategories(): Observable<ICategoryMarketplace[]> {
        return this.request({
            path: `${this.controllerPath}/get-root`,
            method: 'GET',
        });
    }

    getAllCategories(): Observable<ICategoryMarketplace[]> {
        return this.request({
            path: `${this.controllerPath}/get-all`,
            method: 'GET',
        });
    }

    createCategory(categoryMarketplace: ICategoryMarketplace): Observable<ICategoryMarketplace>  {
        return this.request({
            path: `${this.controllerPath}`,
            method: 'POST',
            body: categoryMarketplace
        });
    }

    updateCategory(categoryMarketplace: ICategoryMarketplace): Observable<ICategoryMarketplace> {
        return this.request({
            path: `${this.controllerPath}`,
            method: 'POST',
            body: categoryMarketplace
        });
    } 

    deleteCategory(categoryMarketplace: ICategoryMarketplace): Observable<any> {
        return this.request({
            path: `${this.controllerPath}/${categoryMarketplace.id}`,
            method: 'DELETE',
        });
    }
}
