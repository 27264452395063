import { AbstractControl, ValidatorFn } from '@angular/forms';
import parsePhoneNumber from 'libphonenumber-js'

export function cellPhoneValidator(): ValidatorFn {
  return (control: AbstractControl): {[key: string]: any} | null => {
      const phoneString = control.value?.toString();
      const phone = parsePhoneNumber(control.value?.toString() || '');
      if (!phone) {
        return {phone: {value: control.value}};
      }
      if (phone.isValid() && phoneString.length > phone.number.length) {
          return {phone: {value: control.value}}
      }

      return phone && phone.isValid() ? null : {phone: {value: control.value}};
  };
}

export function cellPhoneValidatorNew(): ValidatorFn {   //To validate alphabats in cell
    return (control: AbstractControl): {[key: string]: any} | null => {
        const regex = /[a-zA-Z@~`!@#$%^&*()_=\\\\';:\"\\/?>.<,]/g;
        if (control.value.match(regex)) {
          console.log('The string contains letter(s).');
          return {phoneValid: {value: control.value}}
        }
        const phoneString = control.value?.toString();
        const phone = parsePhoneNumber(control.value?.toString() || '');
        if (!phone) {
          return;
        }
        if (phone.isValid() && phoneString.length > phone.number.length) {
            return {phone: {value: control.value}}
        }
        return phone && phone.isValid() ? null : {phone: {value: control.value}};
    };
  }

export function landlinePhoneValidator(): ValidatorFn {
  return (control: AbstractControl): {[key: string]: any} | null => {
    const phone = parsePhoneNumber(control.value?.toString() || '');
    return phone && phone.isValid() ? null : {phone: {value: control.value}};
  };
}

export function emailValidator(): ValidatorFn {
  return (control: AbstractControl): {[key: string]: any} | null => {
    if (control.value) {
      const valid = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(control.value);
      return valid ? null : {regex: {value: control.value}};
    }
    return null;
  };
}

export const StringIsNumber = value => isNaN(Number(value)) === false;

export const isUndefined = value => value === undefined || value === null;


export function onConfirmDialog(dialogRef, func, name){
    dialogRef.componentInstance.message = `Are you sure you want to delete ${name}. Please confirm deleting`;
    dialogRef.componentInstance.rightBtnText = 'Delete';
    dialogRef.afterClosed().subscribe(value => {
        if (!value) return;
        func();
    });
}

export function addProductConfirm(dialogRef, func){
  dialogRef.componentInstance.message = `Dear customer, your shipment will be created in pending status for approval process by our legal department. You must wait for shipment status "Active" withing the next 24 hours and hold the shipment. Empact will not be responsible for the shipment and will decline it if shipment is being shipped prior to confirmation`;
  dialogRef.componentInstance.rightBtnText = 'Confirm';
  dialogRef.componentInstance.showLeftBtn = false;
  dialogRef.afterClosed().subscribe(value => {
      if (!value) return;
      func();
  });
}
