import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { FormControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import {formConstant} from 'src/config';
import { PayoneerPayinHistoryService } from 'src/app/modules/payoneer-payin/services/payoneer-payin-history.service';
import { IPayoneerPayin } from 'src/app/core/models/payoneerpayin.interface';
import { Router } from '@angular/router';

@Component({
  selector: 'app-vban-popup',
  templateUrl: './vban-popup.component.html',
  styleUrls: ['./vban-popup.component.scss']
})
export class VbanPopupDialog implements OnInit {

  textFieldMaxLength = formConstant.textFieldMaxLength;
  isUpdate: boolean = false;
  payoneerPayInForm: FormGroup;
  info: IPayoneerPayin;

  constructor(
    private formBuilder: FormBuilder, 
    private payoneerPayinHistoryServiceSvc: PayoneerPayinHistoryService,
    public dialogRef: MatDialogRef<VbanPopupDialog>,
    private router: Router,
  ) {

  }

  ngOnInit(): void {
    this.payoneerPayInForm = this.initializeForm();
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onSubmit(): void {
    if (this.payoneerPayInForm.invalid) {
      return;
    }
    this.payoneerPayinHistoryServiceSvc.addPayoutHistory({vbanAccount: this.payoneerPayInForm.value.vbanAccount, company_id: null, shopName: null, isAllocated: 0}).subscribe(data => {
      const currentUrl = this.router.url;
      this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
          this.router.navigate([currentUrl]);
      });  
    });

    this.dialogRef.close(this.payoneerPayInForm.value);
  }

  initializeForm(): FormGroup {
    return this.formBuilder.group({
      vbanAccount: [this.info?.vbanAccount || '', [Validators.required, Validators.maxLength(this.textFieldMaxLength)]],
    });
  }
}
