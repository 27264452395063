import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable()
export class SaleOrderFromXlsxService extends ApiService {

    public resellerCouponsFilters$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    public currentResellerCoupon: number;
    private baseUrl = 'xlsx-sale-order-files';

    getAllSOXlsx(
        page: number = 1,
        limit: number = 100,
        from?: string,
        to?: string,
        companyId?: number,
        shopId?: number
    ): Observable<any> {
        return this.request({
            path: `${this.baseUrl}`,
            method: 'GET',
            query: {
                from: from ? from : '',
                to: to ? to : '',
                page,
                limit,
                companyId: companyId ? companyId : '',
                shopId: shopId ? shopId : ''
            }
        })
    }

    uploadXlsx(body: any): Observable<any> {
        return this.request({
            path: `${this.baseUrl}`,
            method: 'POST',
            body: body
        })
    }

    locateShopId(id: number, body: any): Observable<any> {
        return this.request({
            path: `${this.baseUrl}/${id}/locate-shop`,
            method: 'POST',
            body: body
        })
    }

    sync(body: any): Observable<any> {
        return this.request({
            path: `${this.baseUrl}/sync/xlsx`,
            method: 'POST',
            body: body
        });
    }

    delete(id: number): Observable<any> {
        return this.request({
            path: `${this.baseUrl}/${id}`,
            method: 'DELETE',
        });
    }

    public getOne(id: number): Observable<any> {
        return this.request({
            path: `${this.baseUrl}/${id}`,
            method: 'GET',
        });
    }

    public downloadReport(id: number): Observable<any> {
        return this.request({
            path: `${this.baseUrl}/download-report/${id}`,
            method: 'GET',
        });
    }
}
