import { Component, Input, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { formConstant } from '../../../../config';
import { IAvailableTypes, IConstantItem } from 'src/app/core/models/available-types.interface';
import { ICountry, IState } from 'src/app/core/models/country.interface';
import { ConstantService } from 'src/app/core/services/constant.service';
import { UserService } from 'src/app/core/services/user.service';
import { IOrderListItem } from 'src/app/modules/sales-order-management/models/order-list-item';

@Component({
  selector: 'app-new-order-popup',
  templateUrl: './new-order-popup.component.html',
  styleUrls: ['./new-order-popup.component.scss']
})
export class NewOrderPopupDialog implements OnInit {

  form: FormGroup;
  textFieldMaxLength = formConstant.textFieldMaxLength;

  // locales = LOCATION_TYPES;
  // wareHouseTypes = WAREHOUS_TYPES;
  // counties = COUNTRIES;

  availableTypes: IAvailableTypes;
  countries: ICountry[];
  states: IState[] = [];

  @Input() info: IOrderListItem;

  constructor(
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<NewOrderPopupDialog>,
    private constSvc: ConstantService,
    public userSvc: UserService
  ) {
  }


  get warehouseTypes(): IConstantItem[] {
    if (!this.availableTypes) return;
    return this.availableTypes.WarehouseType;
  }

  get locales(): IConstantItem[] {
    if (!this.availableTypes) return;
    return this.availableTypes.WarehouseLocation;
  }

  get orderTypes(): IConstantItem[] {
    if (!this.availableTypes) return;
    return this.availableTypes.CompanyType; // TODO NEED TO CHANGE WHEN WILL HAVE ENUM
  }

  get customerTypes(): IConstantItem[] {
    if (!this.availableTypes) return;
    return this.availableTypes.CompanyType;
  }

  get orderStatuses(): IConstantItem[] {
    if (!this.availableTypes) return;
    return this.availableTypes.CompanyType; // TODO NEED TO CHANGE TO ENUM WHEN IT WILL BE AVAILABLE
  }

  get paymentStatuses(): IConstantItem[] {
    if (!this.availableTypes) return;
    return this.availableTypes.CompanyType; // TODO NEED TO CHANGE TO ENUM WHEN IT WILL BE AVAILABLE
  }

  ngOnInit(): void {
    this.form = this.initializeForm();
    this.subcribeOnAvailableTypes();
    // this.form.get('country').setValue(this.info?.address?.state?.country?.code);
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onSubmit(): void {
    if (this.form.invalid) {
      return;
    }

    this.dialogRef.close();
  }

  initializeForm(): FormGroup {
    return this.formBuilder.group({
      orderType: ['', [Validators.required]],
      orderDate: ['', [Validators.required]],
      customerName: ['', [Validators.required]],
      customerType: ['', [Validators.required]],
      orderID: ['', [Validators.required]],
      orderStatus: ['', [Validators.required]],
      paymentStatus: ['', [Validators.required]],
      countryOfOrigin: ['', [Validators.required]],
      destinationCountry: ['', [Validators.required]],
      destinationWarehouse: ['', [Validators.required]],
    });
    // return this.formBuilder.group({
    //   name: [this.info?.name || '', [Validators.required, Validators.maxLength(this.textFieldMaxLength)]],
    //   address: [this.info?.address?.name || '', [Validators.required, Validators.maxLength(this.textFieldMaxLength)]],
    //   zipCode: [this.info?.address?.zipCode || '', [Validators.required, Validators.maxLength(this.textFieldMaxLength)]],
    //   city: [this.info?.address?.city || null, [Validators.required]],
    //   country: [this.info?.address?.state?.country?.code || null, [Validators.required]],
    //   state: [this.info?.address?.stateId || null, [Validators.required]],
    //   contact1Name: [this.info?.contact1Name || '', [Validators.required, Validators.maxLength(this.textFieldMaxLength)]],
    //   contact1TelNo: [this.info?.contact1TelNo || '', [Validators.required, Validators.maxLength(this.textFieldMaxLength), Validators.pattern(/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/)]],
    //   contact1CellNo: [this.info?.contact1CelNo || '', [Validators.required, Validators.maxLength(this.textFieldMaxLength), Validators.pattern(/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/)]],
    //   contact2Name: [this.info?.contact2Name || '', [Validators.maxLength(this.textFieldMaxLength)]],
    //   contact2TelNo: [this.info?.contact2TelNo || '', [Validators.maxLength(this.textFieldMaxLength), Validators.pattern(/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/)]],
    //   contact2CellNo: [this.info?.contact2CelNo || '', [Validators.maxLength(this.textFieldMaxLength), Validators.pattern(/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/)]],
    // });
  }

  subcribeOnAvailableTypes(): void {
    this.constSvc.availableTypes.subscribe(data => {
      if (!data) return;
      this.availableTypes = data;
    });

    this.constSvc.getAvailableCountries().subscribe(data => {
      if (!data) return;
      this.countries = data;
    });

    this.form.get('country').valueChanges.subscribe(value => {
      // this.constSvc.getAvailableStatesById(value).subscribe(states => {
      //   if (!states) return;
      //   this.states = states;

      //   let st = this.states.find(el => el.id == this.info?.address?.stateId)
      //   this.form.get('state').setValue(st ? st.id : states[0].id || null);
      // })
    })
  }



  compareWithFn(listOfItems, selectedItem) {
    return listOfItems && selectedItem && listOfItems.id === selectedItem.id;
  }
}
