import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'payinType' })
export class PayoneerPayinTypePipe implements PipeTransform {
    transform(value: any) {
        switch (value) {
            case 0: return 'Free';
            case 1: return 'Inactive';
            case 2: return 'Allocated';
        }
    }
}