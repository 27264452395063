import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output, SimpleChange, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSelect } from '@angular/material/select';
import { IAvailableTypes, IConstantItem } from 'src/app/core/models/available-types.interface';
import { NotificationService } from 'src/app/core/services/notification.service';
import { UserService } from 'src/app/core/services/user.service';
import { ICompany } from 'src/app/modules/customer-registration/models/company.model';
import { RegistrationService } from 'src/app/modules/customer-registration/services/registration.service';
import { FileStatus } from 'src/app/shared/enums/file-status.enum';
import { OrderType } from 'src/app/shared/enums/order-type.enum';
import { Roles } from 'src/app/shared/enums/role-types.enum';
import { IOrderDetails } from '../../../models/order-details.model';

@Component({
  selector: 'eci-main-consolidate-shipments-details',
  templateUrl: './main-consolidate-shipments-details.component.html',
  styleUrls: ['./main-consolidate-shipments-details.component.scss'],
  providers: [DatePipe]
})
export class MainConsolidateShipmentsDetailsComponent implements OnInit {

  @Input() summaryPage = false;
  @Input() info: any;
  @Input() isCreateMode: boolean = true;
  @Input() shipmentOrderType: number = OrderType.Import;
  @Input() availableTypes: IAvailableTypes;
  @Input() hasError: boolean;
  @Input() currentOrderStatus: string | number;
  @Input() showErrors: boolean;

  @Output() infoChange = new EventEmitter<IOrderDetails>();
  @Output() companyIdChange = new EventEmitter<number>();
  @Output() companyInfoChange = new EventEmitter<ICompany>();
  @Output() hasErrorChange = new EventEmitter<boolean>();
  @Output() changeType = new EventEmitter<number>();
  @Output() orderStatusChanged = new EventEmitter<boolean>();
  @Output() getOrder = new EventEmitter<number>();
  @Input() currentOrderType: number;
  @Input() allDocumentNotUploaded: boolean = false;
  searchCustomerName: FormControl = new FormControl('');
  searchorderType: FormControl = new FormControl('');

  form: FormGroup;
  orderType: number;
  today: Date;

  private readonly RELOAD_TOP_SCROLL_POSITION = 10;
  @ViewChild("companySelect") selectElem: MatSelect;

  companyList: any[] = [];
  companyPage: number = 1;

  constructor(
    public userSvc: UserService,
    private formBuilder: FormBuilder,
    private datePipe: DatePipe,
    private regSvc: RegistrationService,
    private notify: NotificationService,
  ) { }

  ngOnInit(): void {
    this.getCompanyList();

    this.form = this.initializeForm(this.info);
    this.info = this.model;
    this.changeType.emit(this.info.type);
    this.infoChange.emit(this.info);
    this.hasErrorChange.emit(this.form.invalid);

    this.form.get('orderType').valueChanges.subscribe(val => {
      if (!val) return;
      this.changeType.emit(val);
      this.orderType = val;
    });

    this.form.get('customerName').valueChanges.subscribe(val => {
      if (!val) return;
      this.companyIdChange.emit(val);
      this.regSvc.getCompanyById(val).subscribe(value => {
        this.companyInfoChange.emit(value);
      })
    });

    this.form.valueChanges.subscribe(_ => {
      this.info = this.model;

      this.infoChange.emit(this.info);
      this.hasErrorChange.emit(!this.form.valid);
    });

    this.today = new Date();
    this.companyIdChange.emit(this.userSvc.isAdmin ? this.info?.companyId : this.userSvc.userStorage?.company?.id);
  }

  ngOnChanges(changes: SimpleChange): void {
    if (changes['showErrors']) {
      if (this.showErrors) this.form?.markAllAsTouched();
    }
  }

  get isAdmin(): boolean {
    return this.userSvc.user.role == Roles.Admin
  }

  get orderTypes(): IConstantItem[] {
    if (!this.availableTypes) return;

    return this.availableTypes.OrderType;
  }

  get orderStates(): IConstantItem[] {
    if (!this.availableTypes) return;
    return this.availableTypes.OrderStatus;
  }

  get paymentStatus(): IConstantItem[] {
    if (!this.availableTypes) return;
    return this.availableTypes.PaymentStatus;
  }

  initializeForm(info: any): FormGroup {
    return this.formBuilder.group({
      orderId: [{ disabled: !this.isCreateMode, value: info?.consolNumber || '' }],
      orderType: [{ disabled: true, value: this.isCreateMode ? this.shipmentOrderType : this.info.type }, [Validators.required]],
      customerName: [{ disabled: !this.isCreateMode, value: (this.userSvc.isAdmin ? info?.companyId.toString() : this.userSvc.userStorage.company.name) || null }, [Validators.required]],
      readyOn: [{ disabled: !this.isCreateMode, value: !!info?.readyOn ? new Date(info?.readyOn) : new Date() }, [Validators.required]],
      status: [{ disabled: false, value: info?.status }],
      shipmentStatus: [{ disabled: false, value: info?.shipmentStatus }],
    });
  }

  get model(): any {
    return {
      ...this.info,
      type: this.form.get('orderType').value,
      companyId: this.userSvc.isAdmin ? parseInt(this.form.get('customerName').value) : parseInt(this.userSvc.userStorage.company.id),
      readyOn: this.form.get('readyOn').value !== "Invalid Date" ? this.datePipe.transform(this.form.get('readyOn').value, 'yyy-MM-dd') : null,
      status: Number(this.form.get('status').value),
      shipmentStatus: Number(this.form.get('shipmentStatus').value),
    }
  }

  registerPanelScrollEvent() {
    const panel = this.selectElem.panel.nativeElement;
    panel.addEventListener("scroll", event => this.loadAllOnScroll(event));
  }

  loadAllOnScroll(event) {
    if (event.target.scrollTop > this.RELOAD_TOP_SCROLL_POSITION) {
      this.companyPage++;
      this.getCompanyList(this.companyPage, 2);
    }
  }

  getCompanyList(limit?, page?): void {
    this.regSvc.getCompanyList(limit, page).subscribe(data => {
      if (!data || !data?.items) {
        return;
      }
      data.items = data.items.filter(element => element.name.length > 0);
      this.companyList = [...data.items];
    });
  }

  changeTo0(field: string): void {
    this.form.get(field).value < 0 ? this.form.get(field).setValue(0) : '';
  }

  updateOrderstatus(newOrderStatus: string): void {
    const isFileNotUploaded = this.info.orderDocuments.find(el => el.isRequired === true && el.status === FileStatus.PENDING);
    if (isFileNotUploaded) {
      this.notify.showError('You cannot change order status until order documents will not be uploaded');
      return;
    }
  }

  orderStatusName(id): string {
    if (!this.availableTypes) return;
    const orderStatus = this.availableTypes.OrderStatus.find(el => el.id == id)
    return orderStatus ? orderStatus.name : '';
  }

  compareWithFn(listOfItems, selectedItem) {
    return listOfItems && selectedItem && listOfItems.id === selectedItem.id;
  }

  scrollProgressBar(): void {
    if (!document.getElementsByClassName('progress-bar')[0]) return;
    document.getElementsByClassName('progress-bar')[0].scrollLeft = 1000000;
    document.getElementsByClassName('progress-bar')[0].scroll();
  }
  addMore(): void {
    if (!document.getElementsByClassName('progress-bar')[0]) return;
    document.getElementsByClassName('progress-bar')[0].scrollLeft += 350;
  }
}
