import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'eci-confirm-popup',
  templateUrl: './confirm-popup.component.html',
  styleUrls: ['./confirm-popup.component.scss']
})
export class ConfirmPopupDialog {
  header: string = "Please confirm";
  message: string = '';
  leftBtnText: string = 'Cancel';
  rightBtnText: string = 'Save';
  showRightBtn: boolean = true;
  showLeftBtn: boolean = true;
  constructor(
    public dialogRef: MatDialogRef<ConfirmPopupDialog>,
  ) {

  }
}
