import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output, SimpleChange, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators, ValidationErrors } from '@angular/forms';
import { IAvailableTypes, IConstantItem } from 'src/app/core/models/available-types.interface';
import { UserService } from 'src/app/core/services/user.service';
import { ICarriersListItem } from 'src/app/modules/carriers-list/components/models/carrier-list-item';
import { IOrderDetails } from '../../../models/order-details.model';
import * as _ from 'lodash';
import { OrderType } from '../../../../../shared/enums/order-type.enum';
import { IAvailableCourierItem } from 'src/app/core/models/available-courier.interface';
import { IAgentInfo } from 'src/app/core/models/agent.interface';
import { AgentService } from 'src/app/core/services/agent.service';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { IPortItem } from '../../../models/ports.model';
import { PortsService } from '../../../services/ports.service';
import { ShipmentType } from 'src/app/shared/enums/shipment-type.enum';
import { CarriersService } from 'src/app/modules/carriers-list/services/carriers.service';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'eci-consolidate-shipments-shipment-details',
  templateUrl: './consolidate-shipments-shipment-details.component.html',
  styleUrls: ['./consolidate-shipments-shipment-details.component.scss'],
  providers: [DatePipe]
})
export class ConsolidateShipmentsShipmentComponent implements OnInit {

  @Input() summaryPage = false;
  @Input() info: IOrderDetails;
  @Input() isCreateMode: boolean = true;
  @Input() availableTypes: IAvailableTypes;
  @Input() availableCarriers: ICarriersListItem[];
  @Input() availableFields: string[];
  @Input() hasError: boolean;
  @Input() actualDate: string;
  @Input() currentOrderType: number;
  @Input() availableCourier: IAvailableCourierItem[];
  @Input() availableAgents: IAgentInfo[];
  @Input() companyId: number;
  @Input() currShipmentType: number;
  @Input() showErrors: boolean;

  @Output() infoChange = new EventEmitter<IOrderDetails>();
  @Output() hasErrorChange = new EventEmitter<boolean>();
  @Output() portDestinationChanged = new EventEmitter<string>();

  @ViewChild(CdkVirtualScrollViewport, { static: false })
  cdkVirtualScrollViewPort: CdkVirtualScrollViewport;

  form: FormGroup;
  minExpectedDate: Date;

  listOfOriginPorts: IPortItem[] = [];
  listOfDestinationPorts: IPortItem[] = [];
  previousePageOrigin: number = 0;
  previousePageDestination: number = 0;
  totalPagesOrigin: number = 1;
  totalPagesDestination: number = 1;
  showPorts: boolean = false;
  searchOrigin: string = '';
  searchDestination: string = '';

  searchCarrier1: FormControl = new FormControl('');
  searchCarrier2: FormControl = new FormControl('');
  searchocarrierServiceType: FormControl = new FormControl('');
  searchagentId: FormControl = new FormControl('');

  originPortFlag = false;
  destinationPortFlag = false;
  carrierListFlag = false;

  carrierSelectedRecord = [];
  destinationPortSelectedRecord = [];
  originPortSelectedRecord = [];

  originSearchEmpty = true;
  destinationSearchEmpty = true;

  constructor(
    public userSvc: UserService,
    private formBuilder: FormBuilder,
    private datePipe: DatePipe,
    private agentSvc: AgentService,
    private portSvc: PortsService,
    private carriersSvc: CarriersService
  ) { }

  get availableCarriersList(): ICarriersListItem[] {
    if (!this.availableCarriers || !this.availableCarriers.length) return;
    const carrier = Object.assign([], this.availableCarriers);
    return carrier.filter(el => {
      const type = this.form.get('carrierServiceType').value;
      return el.type === (type === ShipmentType.Inland ? ShipmentType.CourierExpress : type);
    });
  }

  get carrierType(): IConstantItem[] {
    if (!this.availableTypes) return;

    return this.currentOrderType === OrderType.Domestic ? this.availableTypes.OrderShipmentType.filter(el => el.name !== 'Courier express' && el.name !== 'Ocean') : this.availableTypes.OrderShipmentType;
  }

  get isOcean(): boolean {
    return this.currShipmentType === ShipmentType.Ocean;
  }

  get isAir(): boolean {
    return this.currShipmentType === ShipmentType.Air;
  }

  ngOnInit(): void {
    this.form = this.initializeForm(this.info);

    if (!this.isCreateMode) {
      this.getAvailableCarriers('', 1, 10000, this.info?.shipment?.trackingType);
    }

    this.actualDate = this.info?.shipment?.actualTimeOfArrival;
    if (!this.isCreateMode) {
      this.currShipmentType = this.info.shipment && this.info.shipment.trackingType ? this.info.shipment.trackingType : undefined;    
      this.getListOfDestinationPorts(1, this.info?.shipment?.destinationPort, 1000, this.info.type);
      this.getListOfOriginPorts(1, this.info?.shipment?.originPort, 1000, this.info.type);
    } else {
      this.getPorts();
    }   

    this.form.get('carrierServiceType').valueChanges.subscribe(value => {
      this.currShipmentType = value;
      this.previousCarriersPage = 0;
      this.getAvailableCarriers();
      if (this.currShipmentType === ShipmentType.Ocean || this.currShipmentType === ShipmentType.Air) {
        this.previousePageOrigin = 0;
        this.previousePageDestination = 0;
        this.form.get('originPort').setValidators([Validators.required]);
        this.form.get('destinationPort').setValidators([Validators.required]);      
        this.currentOrderType = this.info?.type;
      }     
      this.getPorts();

      if (this.isShowCouriers) {
        this.form.controls.carrierServiceProvider.setValidators(null);
        this.form.controls.courierName.setValidators(Validators.required);
      }
      else {
        this.form.controls.courierName.setValidators(null);
        this.form.controls.carrierServiceProvider.setValidators(Validators.required);
      }
    });

    if (this.isCreateMode) {
      this.form.get('carrierServiceType').setValue(ShipmentType.Air);
    }

    this.form.get('searchOrigin').valueChanges.pipe(debounceTime(500)).subscribe(value => {
      if (value !== '') {
        this.searchOrigin = value;
        this.previousePageOrigin = 0;
        this.originSearchEmpty = false;
        this.getListOfOriginPorts();
      } else {
        this.originSearchEmpty = true;
        this.getListOfOriginPorts();
      }
    });

    this.form.get('searchDestination').valueChanges.pipe(debounceTime(500)).subscribe(value => {
      if (value !== '') {
        this.searchDestination = value;
        this.previousePageDestination = 0;
        this.destinationSearchEmpty = true;
        this.getListOfDestinationPorts();
      }else{
        this.destinationSearchEmpty = true;
        this.getListOfDestinationPorts();
      }
    });

    this.form.get('originPort').valueChanges.subscribe(value => {
      const countryCode = this.listOfOriginPorts.find(el => el.IATA === value || el.code === value);
      this.portDestinationChanged.emit(countryCode?.countryCode);
    });

    if (!this.isCreateMode) {
      this.getAvailableAgents(this.info.companyId);

      if (this.info.shipment.trackingType === ShipmentType.Ocean || this.info.shipment.trackingType === ShipmentType.Air)
        this.showPorts = true;
    }

    this.searchCarrier1.valueChanges.pipe(debounceTime(500)).subscribe(value => {
      this.previousCarriersPage = 0;
      this.getAvailableCarriers();
    })

    this.form.valueChanges.subscribe(_ => {
      this.info = this.model;
      this.getFormValidationErrors();
      this.infoChange.emit(this.info);
      this.hasErrorChange.emit(!this.form.valid);
    });

    if(this.info){
      this.hasErrorChange.emit(!this.form.valid);
    }
  }

  getFormValidationErrors() {
    Object.keys(this.form.controls).forEach(key => {
      const controlErrors: ValidationErrors = this.form.get(key).errors;
      if (controlErrors != null) {
        Object.keys(controlErrors).forEach(keyError => {
         console.log('Key control: ' + key + ', keyError: ' + keyError + ', err value: ', controlErrors[keyError]);
        });
      }
    });
  }

  private getPorts(): void {
    this.getListOfDestinationPorts();
    this.getListOfOriginPorts();
    this.showPorts = true;
  }

  ngOnChanges(changes: SimpleChange) {
    if (changes['actualDate'] && !!this.actualDate && this.form) {
      this.form.get('actualTimeOfArrival').setValue(new Date(this.actualDate) || '');
    }

    if (changes['companyId']) {
      this.companyId = changes['companyId'].currentValue
      this.getAvailableAgents(this.companyId || this.userSvc.userStorage.company.id);
    }

    if (changes['showErrors']) {
      if (this.showErrors) this.form?.markAllAsTouched();
    }

    this.form?.get('originPort')?.valueChanges.subscribe(data => {
      this.listOfOriginPorts.map(item => {
        if (item.IATA === data || item.code === data) {
          sessionStorage.setItem("city", item.name);
        }
      });
    });

  }

  getAvailableAgents(companyId: number) {
    this.agentSvc.getAllAgents(1, 1000, '', companyId).subscribe(data => {
      this.availableAgents = data.items;
    });
  }


  initializeForm(info: any): FormGroup {
    return this.formBuilder.group({
      shipmentTerms: [''],
      courierName: [{ disabled: false, value: info?.shipment?.slug }],
      carrierServiceType: [{ disabled: true, value: info?.shipment?.carrier?.type || info?.shipment?.trackingType }, [Validators.required]],
      carrierServiceProvider: [{ disabled: !this.isCreateMode, value: info?.shipment?.carrierId }],
      agent: [{ disabled: !this.isCreateMode, value: info?.shipment?.agent }],
      trackingNumber: [{ disabled: false, value: info?.shipment?.trackingNumber }],
      expectedTimeOfArrival: [{ disabled: !this.isCreateMode, value: new Date(info?.shipment?.expectedTimeOfArrival) || '' }, [Validators.required]],
      actualTimeOfArrival: [{ disabled: !this.isCreateMode, value: new Date(info?.shipment?.actualTimeOfArrival) || '' }],
      expectedTimeOfDeparture: [{ disabled: !this.isCreateMode, value: new Date(info?.shipment?.expectedTimeOfDeparture) || '' }, [Validators.required]],
      originPort: [{ disabled: !this.isCreateMode, value: info?.shipment?.originPort || null },],
      destinationPort: [{ disabled: !this.isCreateMode, value: info?.shipment?.destinationPort || null },],
      searchOrigin: [''],
      searchDestination: [''],
      searchCourier: ['']
    });
  }

  get model(): any {
    return {
      ...this.info,
      shipment: {
        expectedTimeOfArrival: this.form.get('expectedTimeOfArrival').value != "Invalid Date" ? this.datePipe.transform(this.form.get('expectedTimeOfArrival').value, 'yyyy-MM-dd') : '',
        expectedTimeOfDeparture: this.form.get('expectedTimeOfDeparture').value != "Invalid Date" ? this.datePipe.transform(this.form.get('expectedTimeOfDeparture').value, 'yyyy-MM-dd') : '',
        carrierId: this.form.get('carrierServiceProvider').value,
        trackingType: this.form.get('carrierServiceType').value,
        agent: this.form.get('agent').value || null,
        originPort: this.form.get('originPort').value || null,
        destinationPort: this.form.get('destinationPort').value || null,
        trackingNumber: this.form.get('trackingNumber').value || null,
      }
    }
  }

  isDatePickerValid(item: string): boolean {
    if (!item) return;
    const el = this.form.get(item);
    return el.value !== null;
  }

  onSelectOriginPort(value) {
    this.listOfOriginPorts.forEach((val: any) => {
      if ((val?.IATA && val.IATA == value) || (val?.code && val.code == value)) {
        this.originPortSelectedRecord = [val];
      }
    });
  }

  onSelectDetinationPort(value) {
    this.listOfDestinationPorts.forEach((val: any) => {
      if ((val?.IATA && val.IATA == value) || (val?.code && val.code == value)) {
        this.destinationPortSelectedRecord = [val];
      }
    });
  }

  onSelectCarrier(value) {
    this.availableCarriers.forEach((val: any) => {
      if (val.id == value) {
        this.carrierSelectedRecord = [val];
      }
    });
  }

  /*onOriginPorts(value): void {
    this.searchOrigin = value;
    this.previousePageOrigin = 0;
    this.getListOfOriginPorts();
  }*/

  /*onDetinationPorts(value): void {
    this.searchDestination = value;
    this.previousePageDestination = 0
    this.getListOfDestinationPorts();
  }*/

  getSlugById(id: any): string {
    if (!this.availableCarriersList) return '';
    const item = this.availableCarriersList.find(el => el.id === id);
    return item ? item.slug : '';
  }

  openChange($event: boolean) {
    if ($event) {
      this.cdkVirtualScrollViewPort.scrollToIndex(0);
      this.cdkVirtualScrollViewPort.checkViewportSize();
    } else {
    }
  }

  getListOfOriginPorts(
    page: number = this.previousePageOrigin + 1,
    q: string = !this.originSearchEmpty ? this.searchOrigin : '',
    limit: number = 20,
    orderType: number = OrderType.Import
  ): void {
    switch (this.currShipmentType) {
      case ShipmentType.Ocean: {
        this.portSvc.getOriginPorts(page, limit, q, orderType).subscribe(data => {
          if (!data) return;
          this.setOriginPorts(data);
        });
      }; break;
      case ShipmentType.Air: {
        this.portSvc.getOriginAirports(page, limit, q, orderType).subscribe(data => {
          if (!data) return;
          this.setOriginPorts(data);
        });
      }; break;
    }
  }

  setOriginPorts(data): void {
    if (data.meta.currentPage > 1) {
      this.listOfOriginPorts = [...this.listOfOriginPorts, ...data.items];
    }
    else {
      if (data.meta.itemCount >= 1) {
        if (this.info?.shipment?.originPort && !this.originPortFlag) {
          this.onEditGetOriginPorts(data.items);
          this.originPortFlag = true;
        } else {
          this.listOfOriginPorts = [...this.originPortSelectedRecord, ...data.items];
        }
      }
    }
    if (data.meta.itemCount >= 20)
      this.previousePageOrigin = data.meta.currentPage;
    this.totalPagesOrigin = data.meta.totalPages;
  }

  onEditGetOriginPorts(selectedData) {
    switch (this.info.shipment.trackingType) {
      case ShipmentType.Ocean: {
        this.portSvc.getOriginPorts(1, 20, '', this.currShipmentType).subscribe(data => {
          this.listOfOriginPorts = [...selectedData, ...data.items];
        });
      }; break;
      case ShipmentType.Air: {
        this.portSvc.getOriginAirports(1, 20, '', this.currShipmentType).subscribe(data => {
          this.listOfOriginPorts = [...selectedData, ...data.items];
        });
      }; break;
    }
  }

  getListOfDestinationPorts(
    page: number = this.previousePageDestination + 1,
    q: string = !this.destinationSearchEmpty ? this.searchDestination : '',
    limit: number = 20,
    orderType: number = OrderType.Import
  ): void {
    switch (this.currShipmentType) {
      case ShipmentType.Ocean: {
        this.portSvc.getDestinationPorts(page, limit, q, orderType).subscribe(data => {
          if (!data) return;
          this.setDestinationPorts(data);
        });
      }; break;
      case ShipmentType.Air: {
        this.portSvc.getDestinationAirports(page, limit, q, orderType).subscribe(data => {
          if (!data) return;
          this.setDestinationPorts(data);
        });
      }; break;
    }
  }

  setDestinationPorts(data): void {
    if (data.meta.currentPage > 1) this.listOfDestinationPorts = [...this.listOfDestinationPorts, ...data.items];
    else {
      if (data.meta.itemCount >= 1) {
        if (this.info?.shipment?.destinationPort && !this.destinationPortFlag) {
          this.onEditGetDestinationPorts(data.items);
          this.destinationPortFlag = true;
        } else {
          this.listOfDestinationPorts = [...this.destinationPortSelectedRecord, ...data.items];
        }
      }
    }
    if (data.meta.itemCount >= 20)
      this.previousePageDestination = data.meta.currentPage;
    this.totalPagesDestination = data.meta.totalPages;
  }

  onEditGetDestinationPorts(selectedData) {
    switch (this.currShipmentType) {
      case ShipmentType.Ocean: {
        this.portSvc.getDestinationPorts(1, 20, '', this.currShipmentType).subscribe(data => {
          this.listOfDestinationPorts = [...selectedData, ...data.items];
        });
      }; break;
      case ShipmentType.Air: {
        this.portSvc.getDestinationAirports(1, 20, '', this.currShipmentType).subscribe(data => {
          if (!data) return;
          this.listOfDestinationPorts = [...selectedData, ...data.items];
        });
      }; break;
    }
  }

  get isShowCouriers(): boolean {
    return this.currShipmentType === ShipmentType.CourierExpress || this.currShipmentType === ShipmentType.Inland;
  }

  previousCarriersPage: number = 0;
  totalCarriersPage: number = 0;

  getAvailableCarriers(
    q: string = this.searchCarrier1.value,
    page: number = this.previousCarriersPage + 1,
    limit: number = 20,
    type: any = this.currShipmentType == 4 ? 3 : this.currShipmentType
  ) {
    this.carriersSvc.getCarriersListForSelection(q, page, limit, type).subscribe(data => {
      if (!data) return;

      if (data.meta.currentPage > 1) {
        this.availableCarriers = [...this.availableCarriers, ...data.items];
      } else {
        if (data.meta.itemCount >= 1) {
          if (this.info?.shipment?.carrier?.name && !this.carrierListFlag) {
            this.onEditGetCarriersListPorts(data.items);
            this.carrierListFlag = true;
          } else {
            this.availableCarriers = [...this.carrierSelectedRecord, ...data.items];
          }
        }
      }
      this.previousCarriersPage = data.meta.currentPage;
      this.totalCarriersPage = data.meta.totalPages;
    });
  }

  onEditGetCarriersListPorts(selectedData) {
    const t: any = this.currShipmentType == 4 ? 3 : this.currShipmentType;
    this.carriersSvc.getCarriersListForSelection('', 1, 20, t).subscribe(data => {
      this.availableCarriers = [...selectedData, ...data.items];
    });
  }

  closedOriginPorts(): void {
    if (!this.form?.controls?.originPort?.value) {
      this.form?.controls?.searchOrigin?.setValue('');
    } else {
      this.form?.controls?.searchOrigin?.setValue(this.form?.controls?.originPort.value);
    }
  }

  closedDestinationPorts(): void {
    /*if (!this.form?.controls?.destinationPort?.value) {
      this.form?.controls?.searchDestination?.setValue('');
    } else {
      this.form?.controls?.searchDestination?.setValue(this.form?.controls?.destinationPort.value);
    }*/
  }

  closedCarriers(): void {
    if (!this.form?.controls?.carrierServiceProvider?.value) {
      this.searchCarrier1?.setValue('');
    } else {
      const name = this.availableCarriers.find(el => el.id === this.form?.controls?.carrierServiceProvider.value)?.name;
      this.searchCarrier1?.setValue(name);
    }
  }
}
