import { Pipe, PipeTransform } from '@angular/core';
import { ProductStatus } from '../enums/product-status.enum';

@Pipe({ name: 'productStatus' })
export class ProductStatusPipe implements PipeTransform {
    transform(value: any) {
        switch (value) {
            case ProductStatus[ProductStatus.Active]: return 'Active';
            case ProductStatus[ProductStatus.NotActive]: return 'Not active';
        }
    }
}