import { Pipe, PipeTransform } from '@angular/core';
import { ProductSearchType } from '../enums/product-search-type.enum';

@Pipe({ name: 'productSearchType' })
export class ProductSearchTypePipe implements PipeTransform {
    transform(value: any) {
        switch (value) {
            case ProductSearchType[ProductSearchType.All]: return 'All';
            case ProductSearchType[ProductSearchType.WithCategory]: return 'With category';
            case ProductSearchType[ProductSearchType.WithoutCategory]: return 'Without category';
        }
    }
}