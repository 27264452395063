import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { FormControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { formConstant } from '../../../../config';
import { ConstantService } from 'src/app/core/services/constant.service';
import { IAvailableTypes, IConstantItem } from 'src/app/core/models/available-types.interface';
import { UserService } from 'src/app/core/services/user.service';
import { cellPhoneValidator } from 'src/app/core/helpers/helpers.function';
import { NotificationService } from 'src/app/core/services/notification.service';
import { AgentService } from 'src/app/core/services/agent.service';
import { IAgentInfo } from 'src/app/core/models/agent.interface';
import { ICountry, IState } from 'src/app/core/models/country.interface';
import { emailValidator } from 'src/app/core/helpers/helpers.function';

const CONTACT_TYPES = ['Agent', 'Source Warehouse', 'Destination Warehouse'];


@Component({
  selector: 'app-agent-popup',
  templateUrl: './agent-popup.component.html',
  styleUrls: ['./agent-popup.component.scss']
})
export class AgentPopupDialog implements OnInit {

  agentForm: FormGroup;
  textFieldMaxLength = formConstant.textFieldMaxLength;

  header: string = "Customer Registration - Freight Forwarding Agent";

  contactPersonTypes = CONTACT_TYPES;
  availableTypes: IAvailableTypes;
  countries: ICountry[];
  ALlcountries: ICountry[];
  states: IState[];
  searchCountry: FormControl = new FormControl('');
  isForUpdate: boolean = false;

  contactableId: number;

  info: IAgentInfo;
  phoneCode: any[];

  constructor(
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<AgentPopupDialog>,
    private constSvc: ConstantService,
    public userSvc: UserService,
    private notify: NotificationService,
    private agentSvc: AgentService
  ) {

  }

  get types(): IConstantItem[] {
    if (!this.availableTypes) return;
    return this.availableTypes.ContactType;
  }

  ngOnInit(): void {
    this.subscribeOnAvailableTypes();
    this.ALlcountries = this.countries;
    // this.agentForm.get('contactRole').setValue(this.findRole(this.info?.contactRole));

    this.constSvc.availablePhoneCodes.subscribe(data => {
      this.phoneCode = data;
    });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onSubmit(): void {
    if (this.agentForm.invalid) return;

    if (this.isForUpdate)
      this.dialogRef.close(this.model);
    else this.addNewAgent(this.model);
  }

  addNewAgent(model): void {
    model['companyId'] = parseInt(this.contactableId?.toString());
    this.agentSvc.addAgent(model).subscribe(_ => {
      this.dialogRef.close(model);
    }, (error => {
      if (!error || !error.error) return;
      switch (error.error.message) {
        case 'AGENT.ERRORS.ALREADY_EXISTS_IN_ECOMMIN': this.notify.showError("User With The Email Already Exist"); break;
        default: this.notify.showError(error.message);
      }
    }));
  }

  cellPhoneValidator = cellPhoneValidator


  initializeForm(): FormGroup {
    return this.formBuilder.group({
      name: [this.info?.name || '', [Validators.required, Validators.maxLength(this.textFieldMaxLength)]],
      companyName: [this.info?.companyName || '', [Validators.required, Validators.maxLength(this.textFieldMaxLength)]],
      cellNumber: [this.info?.telNumber || '', [cellPhoneValidator()]],
      mailAddress: [this.info?.email || '', [Validators.required, Validators.email, emailValidator()]]
    });
  }

  findRole(roleName): number {
    if (!roleName || !this.types) return null;
    return this.types.find(el => el.name == roleName).id;
  }

  subscribeOnAvailableTypes(): void {
    this.constSvc.availableTypes.subscribe(data => {
      if (!data) return;
      this.availableTypes = data;
      this.agentForm = this.initializeForm();
      // this.agentForm.get('contactRole').setValue(this.findRole(this.info?.contactRole));
    })
  }

  compareWithFn(listOfItems, selectedItem) {
    return listOfItems && selectedItem && listOfItems.id === selectedItem.id;
  }

  get model(): IAgentInfo {
    return {
      ...this.info,
      name: this.agentForm.get('name').value,
      companyName: this.agentForm.get('companyName').value,
      telNumber: this.agentForm.get('cellNumber').value,
      email: this.agentForm.get('mailAddress').value,
    }
  }
}
