import { Component, EventEmitter, forwardRef, Input, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Subscription } from 'rxjs';

@Component({
  selector: 'eci-round-checkbox',
  templateUrl: './round-checkbox.component.html',
  styleUrls: ['./round-checkbox.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RoundCheckboxComponent),
      multi: true
    }
  ]
})
export class RoundCheckboxComponent implements ControlValueAccessor {

  sub$: Subscription;
  
  @Input() disabled: boolean;
  @Input() labelText: string = '';

  private _value = false;

  get value(): boolean {
    return this._value;
  }

  set value(val: boolean) {
    this._value = val;
    this.onChange(this._value);
  }

  constructor() { }

  onChange(_: any) {
  }

  writeValue(value: boolean): void {
    this.value = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
  }

  setDisabledState?(isDisabled: boolean): void {
    
  }

  changeState(): void {
    if (this.value) {
      this.value = null;
    } else {
      this.value = true;
    }
  }
}
