import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'eci-hscode-excel-info-popup',
  templateUrl: './hscode-excel-info-popup.component.html',
  styleUrls: ['./hscode-excel-info-popup.component.scss']
})
export class HscodeExcelInfoPopupComponent{
  header: string = "Please confirm";
  data: any = {};
  leftBtnText: string = 'Cancel';
  rightBtnText: string = 'Save';
  showRightBtn: boolean = true;

  constructor(
    public dialogRef: MatDialogRef<HscodeExcelInfoPopupComponent>,
  ) {

  }

}
