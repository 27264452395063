import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'test-user-password',
  templateUrl: './test-user-password.component.html',
  styleUrls: ['./test-user-password.component.scss'],
})
export class TestUserPasswordDialog {

  password: FormControl = new FormControl(undefined, [Validators.required]);
  showPassword: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<TestUserPasswordDialog>,
  ) {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onSubmit(): void {
    if (this.password.invalid) {
      return;
    }

    this.dialogRef.close(this.password.value);
  }

  toggleShowPassword(): void {
    this.showPassword = !this.showPassword;
  }
}
