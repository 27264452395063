import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'filter',
    pure: false
})
export class FilterPipe implements PipeTransform {

    transform(items: any[], searchText: string, isShopName: boolean = false): any {
        if (!items) return [];
        if (!searchText) return items;

        searchText = searchText?.toLowerCase();

        return items.filter(it => {
            if (isShopName) {
                return it.marketplace?.name?.toLowerCase()?.substring(0, searchText.length) === searchText ||
                    it.shopName?.toLowerCase()?.substring(0, searchText.length) === searchText;
            }
            if (it.name) {
                return it.name?.toLowerCase()?.substring(0, searchText.length) === searchText;
            } else if (it.viewValue) {
                return it.viewValue?.toLowerCase()?.substring(0, searchText.length) === searchText;
            } else if (it.originalName) {
                return it.originalName?.toLowerCase()?.substring(0, searchText.length) === searchText;
            } else if (it.city) {
                return it.city?.toLowerCase()?.substring(0, searchText.length) === searchText;
            } else {
                if (typeof it === 'number') {
                    return it?.toString()?.substring(0, searchText.length) === searchText;
                } else {
                    return it?.toLowerCase()?.substring(0, searchText.length) === searchText;
                }
            }
        });
    }
}
