import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'payoutType' })
export class PayoutTypePipe implements PipeTransform {
    transform(value: any) {
        switch (value) {
            case 1: return 'Stripe';
            case 0: return 'Payoneer';
        }
    }
}