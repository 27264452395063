import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output, SimpleChange, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { combineLatest, Subscription } from 'rxjs';
import { IAvailableTypes, IConstantItem } from 'src/app/core/models/available-types.interface';
import { UserService } from 'src/app/core/services/user.service';
import { MeasurementType } from 'src/app/shared/enums/measurement-type.enum';
import { WeightType } from 'src/app/shared/enums/weight-type.enum';
import { IOrderDetails } from '../../../models/order-details.model';
import { IPalette } from '../../../models/palette.interface';
import { CargoType } from 'src/app/shared/enums/cargo-type.enum';
import { ShipmentType } from 'src/app/shared/enums/shipment-type.enum';
import { ICompany } from 'src/app/modules/customer-registration/models/company.model';
import { GlobalSettingsService } from 'src/app/modules/setting/services/global-settings.service';
import { AIR_DENOMINATOR, OCEAN_DENOMINATOR ,MONTHLY_COMMISSION } from 'src/app/shared/constants/denominator-name.cont';
import { ProductAllocation } from 'src/app/modules/customers-order-management/models/product-allocation.interface';
import { IProduct } from 'src/app/modules/customers-order-management/models/order-details.model';
import { NotificationService } from 'src/app/core/services/notification.service';
import { MatDialog } from '@angular/material/dialog';

import { ProductAllocationModalComponent } from 'src/app/shared/components/product-allocation-modal/product-allocation-modal.component';
import { ProductAllocationCartonComponent } from 'src/app/shared/components/product-allocation-carton/product-allocation-carton.component';
import { PalletDetailsTableComponent } from './pallet-details-table/pallet-details-table.component';

const packingTypeArray = [
  { name: 'Single Carton', checked: false },
  { name: 'Pallet', checked: false },
];
@Component({
  selector: 'eci-consolidate-shipments-package-details',
  templateUrl: './consolidate-shipments-package-details.component.html',
  styleUrls: ['./consolidate-shipments-package-details.component.scss'],
  providers: [DatePipe]
})
export class ConsolidateShipmentsPackageDetailsComponent implements OnInit {

  @ViewChild(PalletDetailsTableComponent) palletDetailComponent: PalletDetailsTableComponent;

  displayedColumns: string[] = [
    'packingType',
    'width',
    'height',
    'length',
    'units',
    // 'weight',
    // 'chargeableWeight',
    'grossWeight',
    'unitsOfMeasure',
    'volume',
    'delete'
  ];
  dataSource = [];

  @Input() summaryPage = false;
  @Input() info: any;
  @Input() denominators: any;
  @Input() isCreateMode: boolean = true;
  @Input() availableTypes: IAvailableTypes;
  @Input() hasError: boolean;
  @Input() currentOrderType: number;
  @Input() currShipmentType: number;
  @Input() showErrors: boolean;
  @Input() companyInfo: ICompany;
  @Input() saleOrderProducts: IProduct[];

  pallets: IPalette[] = [];
  sub$: Subscription = new Subscription();
  CargoType = CargoType;
  ShipmentType = ShipmentType;

  availableContainerTypes: IConstantItem[];

  @Output() infoChange = new EventEmitter<IOrderDetails>();
  @Output() hasErrorChange = new EventEmitter<boolean>();

  form: FormGroup;

  currentWeightType: string = WeightType.kg;
  currentMeasuremenrType: string = MeasurementType.cm;
  tempValueGrossWeightLCL:any = "";
  tempValueTotalVolumeLCL:any = "";
  tempValueGrossWeightFCL:any = "";
  tempValueTotalVolumeFCL:any = "";

  packingTypeArray = packingTypeArray;
  packingType = '';
  totalpalet: any = 0
  platnumber: any = 0
  showpalletform: boolean = false
  paletform!: FormGroup;
  cartonform!: FormGroup;
  palets = new FormArray([]);
  catoon = new FormArray([]);
  cartonFormArray = new FormArray([]);
  myProductsList: IProduct[];
  productAllocation: ProductAllocation[] = [];
  constructor(
    public userSvc: UserService,
    private formBuilder: FormBuilder,
    private datePipe: DatePipe,
    private globalSettingsSvc: GlobalSettingsService,
    private notify: NotificationService,
    private dialog: MatDialog,
  ) { }

  ngOnInit(): void {
    this.myProductsList = [];
    this.saleOrderProducts.forEach((prd: any) => {
      this.myProductsList.push({id: prd?.id, description: prd?.description, productId: prd.id, itemNumber: prd?.product?.sku, units: prd?.units, categoryId: prd?.product?.category?.id, price: prd?.price} as IProduct);
    });

    console.log(this.myProductsList);

    this.packingTypeArray.forEach(el => el.checked = false);

    this.paletform = new FormGroup({
      palets: new FormArray([]),
    });

    this.cartonform = new FormGroup({
      cartons: new FormArray([]),
    });

    this.form = this.initializeForm(this.info);
    if (!this.info || !this.info.packageDetails)
      this.pallets = [];
    else {
    this.pallets = this.info.packageDetails.pallets;

      /* new code */
      this.packingType = this.info.packageDetails.packingType;
      if (this.info.packageDetails.packingType === 'SingleCarton') {
        this.changePackingStatus(this.packingTypeArray[0]);
      } else {
        this.changePackingStatus(this.packingTypeArray[1]);
      }
      if(((this.summaryPage && !this.isCreateMode) || (!this.summaryPage && this.isCreateMode)) && !this.info.status)
      {
        this.generatePallatCartonForms();
      }
    }
    if((!this.isCreateMode && this.summaryPage && this.info?.status)){
      this.generatePallatCartonForms();
    }

    this.dataSource = this.pallets;

    combineLatest([
      this.globalSettingsSvc.getSettingByName(AIR_DENOMINATOR),
      this.globalSettingsSvc.getSettingByName(OCEAN_DENOMINATOR),
      this.globalSettingsSvc.getSettingByName(MONTHLY_COMMISSION),
    ]).subscribe(value => {
      if (!value) return;
      this.denominators = {
        airDenominator: parseFloat(value[0]?.value),
        oceanDenominator: parseFloat(value[1]?.value)
      }
    });

    this.form?.valueChanges.subscribe(_ => {
      this.info = this.model;
      this.info.packageDetails.palettes = this.pallets;
      this.info.productAllocation = this.productAllocation;
      // TO DO - Need to check if it's needed. Commented because it generated the error when we are converting
      // info to JSOn to send to backend
    //   if (this.packingType == 'SingleCarton') {
    //     this.info.cartonform = this.cartonform;
    //   } else {
    //     this.info.paletform = this.paletform;
    //   }
      this.infoChange.emit(this.info);
      this.hasErrorChange.emit(this.error);
      this.getFormValidationErrors();
    });
  }

  getFormValidationErrors() {
    Object.keys(this.form.controls).forEach(key => {
      const controlErrors: ValidationErrors = this.form.get(key).errors;
      if (controlErrors != null) {
        Object.keys(controlErrors).forEach(keyError => {
         console.log('Key control: ' + key + ', keyError: ' + keyError + ', err value: ', controlErrors[keyError]);
        });
      }
    });
  }


  generatePallatCartonForms(){
    if (this.info.packageDetails.packingType === 'SingleCarton') {
      this.cartonform = this.info.cartonform as FormGroup;
    } else {
      this.paletform = this.info.paletform as FormGroup;
    }
    this.productAllocation = this.info.productAllocation;
  }

  ngAfterViewInit() {

    if ((!this.isCreateMode && !this.summaryPage && this.info?.status) || (this.isCreateMode && !this.summaryPage && this.info?.status)) {

      if (this.info.packageDetails.packingType === 'SingleCarton') {
        this.changePackingStatus(this.packingTypeArray[0]);
      } else {
        this.changePackingStatus(this.packingTypeArray[1]);
      }

      this.info.shipment?.shipmentConsolPalletCarton.forEach((palletObject, pIndex) => {
        if (this.info.packageDetails.packingType !== 'SingleCarton') {
          this.onChangePallet();
        } else {
          this.onChangeCartons();
        }

        if (this.info.packageDetails.packingType !== 'SingleCarton') {
          for (var j = 0; j < Number(palletObject.noOfCartons); j++) {
            this.changePalletNumber(pIndex);
          }
        }

        palletObject.shipmentConsolPalletCartonDetail.forEach(async (cartonObject, cIndex) => {
          console.log(cartonObject);
          const matchedRecord = this.myProductsList.filter((data: IProduct) => (Number(data.productId) === Number(cartonObject.productId)));
          const allocatedQty = await this.getNotAllocatedProducts(matchedRecord[0]);
          this.productAllocation.push({
            'pallets': palletObject.palletCartonIndex,
            'cartons': cartonObject.cartonIndex,
            'pallet_carton': ((Number(palletObject.palletCartonIndex) * 10) + cartonObject.cartonIndex),
            'product_name': matchedRecord[0].description,
            'product_id': cartonObject.productId,
            'sku': matchedRecord[0].itemNumber,
            'total_qty': matchedRecord[0].units,
            "not_allocated": (Number(matchedRecord[0].units) - allocatedQty),
            "allocated": cartonObject.quantity
          });
        });
      });
      setTimeout(() => {
        this.palletDetailComponent.updateRecords(this.productAllocation, this.pallets);
      }, 2000);
    }
  }

  async getNotAllocatedProducts(product: IProduct) {
    let totalQty = 0;
    this.info.shipment?.shipmentConsolPalletCarton.forEach((palletObject, pIndex) => {
      palletObject.shipmentConsolPalletCartonDetail.forEach((cartonObject, cIndex) => {
        if (Number(cartonObject.productId) === Number(product.productId)) {
          totalQty += Number(cartonObject.quantity);
        }
      });
    });
    return totalQty;
  }

  createPallet(): FormGroup {
    return this.formBuilder.group({
      palletNumber: '',
      palletIndex: '',
      cartonNumbers: new FormArray([]),
    });
  }

  createCarton(): FormGroup {
    return this.formBuilder.group({
      catoon: ''
    });
  }

  palletForm(): FormArray {
    return this.paletform.get('palets') as FormArray;
  }

  addPallet(): void {
    this.palets = this.paletform.get('palets') as FormArray;
    this.palets.push(this.createPallet());
  }

  getcartoons(empIndex: number): FormArray {
    return this.palletForm()
      .at(empIndex)
      .get('cartonNumbers') as FormArray;
  }

  addCarton(empIndex: number) {
    this.getcartoons(empIndex).push(this.createCarton());
  }

  onChangePallet() {
    this.addPallet();
    this.form.get('palletQty').setValue((this.paletform.get('palets').value.length));
  }
  clearFormArray = (formArray: FormArray) => {
    while (formArray.length !== 0) {
      formArray.removeAt(0)
    }
  }

  removePallet(i) {
    this.palletForm().removeAt(i);
    this.form.get('palletQty').setValue((this.paletform.get('palets').value.length));
  }

  removePalletCarton(event, i, j) {
    if (this.productAllocation.length == 0) {
      this.getcartoons(i).removeAt(j);
      this.palletForm().at(i).get('palletNumber').setValue((this.getcartoons(i).value.length));
    } else {
      let flag = 0;
      this.productAllocation.forEach(element => {
        if (Number(element.pallets) == i && Number(element.cartons) == j) {
          if (Number(element.allocated) !== 0) {
            flag++;
          }
        }
      });
      if (flag === 0) {
        this.getcartoons(i).removeAt(j);
        this.palletForm().at(i).get('palletNumber').setValue((this.getcartoons(i).value.length));
      }
      else {
        this.notify.showError('Product is allocated to this Carton');
      }
    }
  }

  removeSingleCarton(event, i) {
    if (this.productAllocation.length == 0) {
      this.cartoonForm().removeAt(i);
      this.form.get('singleCartonQty').setValue((this.cartonform.get('cartons').value.length));
    } else {
      let flag = 0;
      this.productAllocation.forEach(element => {
        if (Number(element.cartons) === i) {
          if (Number(element.allocated) !== 0) {
            flag++;
          }
        }
      });

      if (flag === 0) {
        this.cartoonForm().removeAt(i);
        this.form.get('singleCartonQty').setValue((this.cartonform.get('cartons').value.length));
      }
      else {
        this.notify.showError('Product is allocated to this Carton');
      }
    }
  }

  changePalletNumber(index: any) {
    this.palletForm().at(index).get('palletIndex').setValue(index);
    this.addCarton(index);
    this.palletForm().at(index).get('palletNumber').setValue((this.getcartoons(index).value.length));
  }

  allocateProduct(palletIndex, cartonIndex) {
    console.log(this.paletform);
    const dialogRef = this.dialog.open(ProductAllocationModalComponent, {
      height: '95%',
      width: '900px',
      disableClose: true
    });
    dialogRef.componentInstance.paletform = this.paletform;
    dialogRef.componentInstance.productList = this.myProductsList;
    dialogRef.componentInstance.palletIndex = palletIndex;
    dialogRef.componentInstance.cartonIndex = cartonIndex;
    dialogRef.componentInstance.productAllocation = this.productAllocation;
    dialogRef.componentInstance.detailedView = true;
    dialogRef.afterClosed().subscribe(result => {
      if(result.type === 'save'){
        this.productAllocation = result.data as ProductAllocation[];
        this.palletDetailComponent.updateRecords(this.productAllocation, this.pallets);
      }
    });
  }

  onChangeCartons() {
    //  this.clearFormArray(this.cartoonForm())
    this.catoon = this.cartonform.get('cartons') as FormArray;
    this.catoon.push(this.createCarton());
    this.form.get('singleCartonQty').setValue((this.cartonform.get('cartons').value.length));
  }

  cartoonForm(): FormArray {
    return this.cartonform.get('cartons') as FormArray;
  }

  allocateProductCartons(cartonIndex) {
    console.log(this.cartonform);
    const dialogRef = this.dialog.open(ProductAllocationCartonComponent, {
      height: '95%',
      width: '900px',
      disableClose: true
    });
    dialogRef.componentInstance.cartonform = this.cartonform;
    dialogRef.componentInstance.productList = this.myProductsList;
    dialogRef.componentInstance.cartonIndex = cartonIndex;
    dialogRef.componentInstance.productAllocation = this.productAllocation;
    dialogRef.componentInstance.detailedView = true;
    dialogRef.afterClosed().subscribe(result => {
      if(result.type === 'save'){
        this.productAllocation = result.data as ProductAllocation[];
        this.palletDetailComponent.updateRecords(this.productAllocation, this.pallets);
      }
    });
  }

  get error(): boolean {
    return !this.form?.valid;
  }

  changePackingStatus(packingTypeSingle) {
    this.productAllocation = [];
    this.packingTypeArray.forEach(el => el.checked = false);
    packingTypeSingle.checked = !packingTypeSingle.checked;
    if (packingTypeArray[0].checked) {
      this.packingType = 'SingleCarton';
    } else {
      this.packingType = 'Pallets';
    }
    this.form.get("packType").setValue(this.packingType);
  }


  ngOnChanges(changes: SimpleChange) {
    if (changes['info']) {
      if (this.currShipmentType !== this.info?.shipment?.trackingType) {
        this.currShipmentType = this.info?.shipment?.trackingType;
        if(this.currShipmentType !== ShipmentType.Ocean && this.tempValueGrossWeightLCL !== ""){
          this.changeValues({value:0})
        }
        if(this.currShipmentType == ShipmentType.Ocean && this.tempValueGrossWeightFCL !== ""){
          this.changeValues({value:1})
        }
        if (this.currShipmentType === ShipmentType.Ocean) {
          this.form?.get('containerType').setValidators(Validators.required);
        }
      }
      if (this.currShipmentType === ShipmentType.Ocean && !this.form?.get('cargoType').value) {
        this.form?.get('cargoType').setValue(0);
      }

      if (this.info?.packageDetails?.pallets?.length <= 0 || Number(this.info?.packageDetails?.chargeableWeight) <= 0) {
        this.hasErrorChange.emit(true);
      }
    }

    if (changes['showErrors']) {
      if (this.showErrors) {
        this.form?.markAllAsTouched();
      }
    }
  }

  get isOcean(): boolean {
    return this.currShipmentType === ShipmentType.Ocean;
  }

  get packageTypes(): IConstantItem[] {
    if (!this.availableTypes) return;
    return this.availableTypes.PackingType;
  }

  get weightUnits(): IConstantItem[] {
    if (!this.availableTypes) return;
    return this.availableTypes.UnitsOfMeasure.filter(el => el.id === 1);
  }

  isDatePickerValid(item: string): boolean {
    if (!item) return;
    const el = this.form?.get(item);
    return el.value !== null;
  }

  initializeForm(info: any): FormGroup {
    return this.formBuilder.group({
      totalUnits: [{ disabled: !this.isCreateMode, value: info?.packageDetails?.units || 0 }, [Validators.required]],
      totalPallets: [{ disabled: !this.isCreateMode, value: info?.packageDetails?.pallets?.length || 0 }, [Validators.required]],
      totalWeight: [{ disabled: !this.isCreateMode, value: info?.packageDetails?.weight || 0 }, [Validators.required]],
      totalVolume: [{ disabled: !this.isCreateMode, value: info?.packageDetails?.volume || 0 }, [Validators.required]],
      totalGrossWeight: [{ disabled: !this.isCreateMode, value: parseFloat(info?.packageDetails?.grossWeight)?.toFixed(2) || (0).toFixed(2) }, [Validators.required]],
      volCBM: [{ disabled: !this.isCreateMode, value: info?.packageDetails?.volCBM?.toFixed(2) || (0).toFixed(2) }],
      totalVolWeight: [{ disabled: !this.isCreateMode, value: info?.packageDetails?.weight || 0 }, [Validators.required]],
      totalChargWeight: [{ disabled: !this.isCreateMode, value: info?.packageDetails?.chargeableWeight || 0 }, [Validators.required]],
      packingDate: [{ disabled: !this.isCreateMode, value: new Date(info?.packageDetails?.packingDate) || '' }, [Validators.required]],
      packType: [{ disabled: !this.isCreateMode, value: this.packingType }, [Validators.required]],
      cargoType: [{ disabled: !this.isCreateMode, value: info?.packageDetails?.cargoType !== undefined || info?.packageDetails?.cargoType !== null ? info?.packageDetails?.cargoType : CargoType.FCL }],
      hsSmall: [{ disabled: !this.isCreateMode, value: info?.packageDetails?.oceanPackage?.hsSmall || 0 }],
      hsBig: [{ disabled: !this.isCreateMode, value: info?.packageDetails?.oceanPackage?.hsBig || 0 }],
      dvSmall: [{ disabled: !this.isCreateMode, value: info?.packageDetails?.oceanPackage?.dvSmall || 0 }],
      dvBig: [{ disabled: !this.isCreateMode, value: info?.packageDetails?.oceanPackage?.dvBig || 0 }],
      containerType: [{ disabled: !this.isCreateMode, value: info?.packageDetails?.oceanPackage?.containerType || null },],
      palletQty: [],
      singleCartonQty: [],
    });
  }

  get model(): IOrderDetails {
    return {
      ...this.info,
      packageDetails: {
        units: this.form?.get('totalUnits').value,
        volume: parseFloat(this.form?.get('totalVolume').value),
        grossWeight: this.form?.get('totalGrossWeight').value,
        volCBM: parseFloat(this.form?.get('volCBM')?.value),
        weight: this.form?.get('totalVolWeight').value,
        insuranceRate: 0,
        chargeableWeight: this.form?.get('totalChargWeight').value,
        packingDate: this.form?.get('packingDate').value != "Invalid Date" ? this.datePipe.transform(this.form?.get('packingDate').value, 'yyy-MM-dd') : '',
        packingType: this.packingType,
        pallets: this.pallets,
        productAllocation: this.productAllocation,
        palets: this.paletform.value,
        cartons: this.cartonform.value,
        cargoType: this.currShipmentType === ShipmentType.Ocean ? this.form?.get('cargoType').value : null,
        oceanPackage: this.showContainers ? {
          dvBig: parseInt(this.form?.get('dvBig').value) || 0,
          dvSmall: parseInt(this.form?.get('dvSmall').value) || 0,
          hsBig: parseInt(this.form?.get('hsBig').value) || 0,
          hsSmall: parseInt(this.form?.get('hsSmall').value) || 0,
          containerType: this.form?.get('containerType').value || null
        } : null
      }
    }
  }

  calculateData(el, chaneDataSource: boolean = false): void {
    this.pallets = el;
    this.form?.get('totalUnits').setValue(this.calculateUnits);
    this.form?.get('totalWeight').setValue(this.calculateWeight);
    this.form?.get('totalPallets').setValue(this.pallets.length);
    this.form?.get('totalVolume').setValue(this.calculateVolume.toFixed(3));
    this.form?.get('totalChargWeight').setValue(this.calculateChargeableWeight.toFixed(3));
    this.form?.get('totalGrossWeight').setValue(this.calculateGrossWeight.toFixed(3));

    if (chaneDataSource)
      this.dataSource = [...this.pallets];
  }

  changeValues(event){
    if(event.value == 0){
      this.tempValueTotalVolumeFCL = this.form?.value.totalGrossWeight;
      this.tempValueGrossWeightFCL =  this.form?.value.totalVolume;
      this.form?.get('totalGrossWeight').setValue(this.tempValueGrossWeightLCL);
      this.form?.get('totalVolume').setValue(this.tempValueTotalVolumeLCL);
    }
    if(event.value == 1){
      this.tempValueTotalVolumeLCL = this.form?.value.totalGrossWeight;
      this.tempValueGrossWeightLCL =  this.form?.value.totalVolume;
      this.form?.get('totalGrossWeight').setValue(this.tempValueGrossWeightFCL);
      this.form?.get('totalVolume').setValue(this.tempValueTotalVolumeFCL);
    }
  }

  ngOnDestroy(): void {
    this.sub$.unsubscribe();
  }

  get calculateUnits(): number {
    return this.pallets.reduce((sum, el) => sum + el.units, 0);
  }

  get calculateVolume(): number {
    return this.pallets.reduce((sum, el) => sum + parseFloat(el.volume?.toString()), 0);
  }

  get calculateGrossWeight(): number {
    return this.pallets.reduce((sum, el) => sum + parseFloat(el.grossWeight?.toString()), 0);
  }

  get calculateWeight(): number {
    return this.pallets.reduce((sum, el) => sum + parseFloat(el.weight?.toString()), 0);
  }

  get calculateChargeableWeight(): number {
    const grossW = this.pallets.reduce((sum, el) => sum + parseFloat(el.grossWeight?.toString()), 0);
    const volumaticW = this.pallets.reduce((sum, el) => sum + parseFloat(el.volume?.toString()), 0);
    return grossW > volumaticW ? grossW : volumaticW;
  }

  changeTo0(field: string): void {
    this.form?.get(field).value < 0 ? this.form?.get(field).setValue(0) : '';
  }

  get showContainers(): boolean {
    if (!this.form) return;
    return parseInt(this.form?.get('cargoType').value) === CargoType.FCL && this.currShipmentType === ShipmentType.Ocean;
  }

  get showVolCBM(): boolean {
    if (!this.form) return;
    return parseInt(this.form?.get('cargoType').value) === CargoType.FCL && this.currShipmentType === ShipmentType.Ocean;
  }

  resetPalletsValidators(): void {
    this.form?.get('totalUnits').setValidators(null);
    this.form?.get('totalPallets').setValidators(null);
    this.form?.get('totalWeight').setValidators(null);
    this.form?.get('totalVolume').setValidators(null);
    this.form?.get('totalGrossWeight').setValidators(null);
    this.form?.get('totalVolWeight').setValidators(null);
    this.form?.get('totalChargWeight').setValidators(null);
  }

  setPalletsValidators(): void {
    this.form?.get('totalUnits').setValidators([Validators.required]);
    this.form?.get('totalPallets').setValidators([Validators.required]);
    this.form?.get('totalWeight').setValidators([Validators.required]);
    this.form?.get('totalVolume').setValidators([Validators.required]);
    this.form?.get('totalGrossWeight').setValidators([Validators.required]);
    this.form?.get('totalVolWeight').setValidators([Validators.required]);
    this.form?.get('totalChargWeight').setValidators([Validators.required]);
  }

  get isContainerChoosed(): boolean {
    return this.amountOfContainers > 0;
  }

  get amountOfContainers(): number {
    if (!this.form) return;
    return parseInt(this.form?.get('hsSmall').value) +
      parseInt(this.form?.get('hsBig').value) +
      parseInt(this.form?.get('dvSmall').value) +
      parseInt(this.form?.get('dvBig').value);
  }

  fixDecimal(formControlName: any, numberAfterDot): void {
    const valueToSet = parseFloat(this.form?.get(formControlName)?.value)?.toFixed(numberAfterDot);
    this.form?.get(formControlName)?.setValue(valueToSet);
  }
}
