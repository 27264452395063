import { DatePipe } from '@angular/common';
import { Component } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { NotificationService } from 'src/app/core/services/notification.service';
import { IPayoneerDetails } from 'src/app/modules/customer-management/models/payoneer-details';

@Component({
  selector: 'eci-stripe-payouts',
  templateUrl: './stripe-payout.component.html',
  styleUrls: ['./stripe-payout.component.scss'],
  providers: [DatePipe]
})
export class StripePayoutComponent {

  header: string = "Stripe Payout Details";
  createMode: boolean = false;
  info: any;
  stripeAccounts: any=[];
  clientReferenceId: string = '';
  minimumPayoutAmount: number = 0;
  shopList: any = [];
  shopId: string = '';
  currency : string = '';
  form: FormGroup;
  isDialog: boolean = true;
  stripeAccountId: string = '';
  companyCode: number;

  constructor(
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<StripePayoutComponent>,
    private notify: NotificationService,
  ) {
  }

  ngOnInit(): void {
    this.getInfo();
  }

  getInfo(): void {
    this.form = this.initializeForm(this.info);
  }

  initializeForm(info: IPayoneerDetails): FormGroup {
    return this.formBuilder.group({
      stripeAccountId: new FormControl({ disabled: false, value: ''}, [Validators.required]),
      currency: new FormControl({ disabled: false, value: 'EUR'}, [Validators.required]),
      amount: new FormControl({ disabled: false, value: 0}, [Validators.required, Validators.min(this.minimumPayoutAmount)]),
      description: new FormControl({ disabled: false, value: ''}),
      shopName: new FormControl({ disabled: false, value: ''}, [Validators.required]),
    });
  }

  save(): void {
    if (this.form.invalid) {
      this.notify.showError('Not all fields are filled');
      this.form.markAllAsTouched();
      return;
    }
    this.dialogRef.close(this.prepareBody);
  }

  exit(): void {
    if (!this.isDialog) {
      history.back();
    } else this.dialogRef.close();
  }

  changeShop(event):void {
    const selectedShopData = this.shopList.filter( it => { 
      return it.shopName === event; 
    });
    this.shopId = selectedShopData[0]?.shopId || '';
  }
  changeStripAccount(event):void {
    const selectedStripeData = this.stripeAccounts.filter( it => { 
      return it.stripeAccountId === event; 
    });
    this.stripeAccountId = selectedStripeData[0]?.stripeAccountId || '';
  }
  get prepareBody(): IPayoneerDetails {
    return {
      // ...this.info,
      // ...this.form?.value,
      payee_id: this.form.get('stripeAccountId').value,
      description: this.form.get('description').value,
      currency: this.form.get('currency').value,
      amount: this.form.get('amount').value,
      shopName: this.form.get('shopName').value
    }
  }

  setValidators(): void {
    // this.form.controls.clientReferenceId.setValidators([Validators.required]);
    this.form.controls.stripeAccountId.setValidators([Validators.required]);
    this.form.controls.currency.setValidators([Validators.required]);
    this.form.controls.shopName.setValidators([Validators.required]);
    this.form.controls.amount.setValidators([Validators.required, Validators.min(this.minimumPayoutAmount)]);
  }
}
