import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {EditorComponent} from './editor/editor.component';
import {InvoicesComponent} from './invoices/invoices.component';

const routes: Routes = [
    {
        path: '',
        redirectTo: 'invoices',
        pathMatch: 'full'
    },
    {
        path: 'invoices',
        component: InvoicesComponent,
    },
    {
        path: 'editor',
        component: EditorComponent,
    }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class InvoiceManagerRoutingModule { }
