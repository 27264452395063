import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router, Routes} from '@angular/router';
import {Subscription} from 'rxjs';
import {filter} from 'rxjs/operators';
import {ConstantService} from '../../../core/services/constant.service';
import {FieldList} from '../../new-tax-report-management/components/hideshow-fields-param';

export const Field_List: FieldList[] = [
    {
        key: 'orderType',
        value: false,
        label: 'Order Type'
    },
    {
        key: 'vatId',
        value: false,
        label: 'VAT Id'
    },
    {
        key: 'customerType',
        value: false,
        label: 'Customer Type'
    },
    {
        key: 'stripeAmount',
        value: false,
        label: 'Stripe Amount'
    },
    {
        key: 'stripeRefundAmount',
        value: false,
        label: 'Stripe Refund Amount'
    },
    {
        key: 'mpInvoiceVATAmount',
        value: false,
        label: 'Marketplace Invoice VAT Amount'
    },
    {
        key: 'orderId',
        value: false,
        label: 'Order Id'
    }
];

@Component({
    selector: 'eci-tax-reports',
    templateUrl: './tax-reports.component.html',
    styleUrls: ['./tax-reports.component.scss']
})
export class TaxReportsComponent implements OnInit {
    accountType = '';
    companyId = null;
    links = [
        {
            name: 'IOSS/OSS/UK',
            link: 'ioss-oss'
        },
        {
            name: 'Reports per states',
            link: 'rep-per-states'
        }
    ]

    activeLink = this.links[0].link;

    previousUrl: string = null;
    currentUrl: string = null;

    routeSubscription: Subscription;
    routeSubscriptionIndVat: Subscription;

    constructor(
        private activatedRoute: ActivatedRoute,
        public router: Router,
        public constantService: ConstantService,
    ) {}

    ngOnInit(): void {
        this.accountType = this.activatedRoute.snapshot.data.accountType;
        this.companyId = this.activatedRoute.snapshot.params.companyId;
        const selectedUrl = this.router.url.split('/');
        this.activeLink = selectedUrl[selectedUrl.length - 1];

        switch (this.accountType) {
            case 'empact':
                break;
            case 'ind':
                break;
        }

        // commented because it's creating an error when we are logout
        // bug https://github.com/Zangula/ECommIn_project_management/issues/3569
        // if (this.accountType === 'ind') {
        //     this.routeSubscriptionIndVat = this.router.events.pipe(
        //         filter((event) => event instanceof NavigationEnd)
        //     ).subscribe((event: NavigationEnd) => {
        //         if (event.url != '/') {
        //             this.previousUrl = this.currentUrl;
        //             this.currentUrl = event.url;
        //             if (
        //                 this.previousUrl != this.currentUrl &&
        //                 !this.currentUrl.includes('/rep-tool-tax-reports/ind')
        //             ) {
        //                 this.resetReportSession();
        //                 const req = {
        //                     name: 'TAX_REPORT_VISIBLITY_FIELD_RULE',
        //                     label: 'TAX_REPORT_VISIBLITY_FIELD_RULE Listview Fields setting',
        //                     value: JSON.stringify(Field_List)
        //                 };
        //                 this.constantService.saveSettings(req).subscribe(data => {
        //                     this.routeSubscriptionIndVat && this.routeSubscriptionIndVat.unsubscribe();
        //                     if (!data) {
        //                         return;
        //                     }
        //                 });
        //             }
        //         }
        //     });
        // } else {
        //     this.routeSubscription = this.router.events.pipe(
        //         filter((event) => event instanceof NavigationEnd)
        //     ).subscribe((event: NavigationEnd) => {
        //         if (event.url != '/') {
        //             this.previousUrl = this.currentUrl;
        //             this.currentUrl = event.url;
        //             if (
        //                 this.previousUrl != this.currentUrl
        //                 && !this.currentUrl.includes('/rep-tool-tax-reports/empact')
        //             ) {
        //                 this.resetReportSession();
        //                 const req = {
        //                     name: 'TAX_REPORT_VISIBLITY_FIELD_RULE',
        //                     label: 'TAX_REPORT_VISIBLITY_FIELD_RULE Listview Fields setting',
        //                     value: JSON.stringify(Field_List)
        //                 };
        //                 this.constantService.saveSettings(req).subscribe(data => {
        //                     this.routeSubscription && this.routeSubscription.unsubscribe();
        //                     if (!data) {
        //                         return;
        //                     }
        //                 });
        //             }
        //         }
        //     });
        // }
    }

    resetReportSession() {
        sessionStorage.removeItem("reportdateto");
        sessionStorage.removeItem("reportdatefrom");
        sessionStorage.removeItem("reportType");
        sessionStorage.removeItem("reportCustomerIds");
    }

}
