import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { PageParams } from 'src/app/core/models/page-params.model';
import { UserService } from 'src/app/core/services/user.service';
import { IProduct } from '../../../models/order-details.model';
import { ISaleOrder } from 'src/app/modules/sales-order-management/models/sale-order.interface';
import { SaleOrderService } from 'src/app/modules/sales-order-management/services/sale-order.service';
import { NotificationService } from 'src/app/core/services/notification.service';
import { IAvailableTypes } from 'src/app/core/models/available-types.interface';
import { ShipmentConsolStatus } from 'src/app/shared/enums/shipment-consol-status.enum';
import { FileStatus } from 'src/app/shared/enums/file-status.enum';
import { UploadFileService } from 'src/app/core/services/upload-file.service';

@Component({
  selector: 'eci-consolidate-shipment-packages',
  templateUrl: './consolidate-shipment-packages.component.html',
  styleUrls: ['./consolidate-shipment-packages.component.scss']
})
export class ConsolidateShipmentPackagesComponent implements OnInit {

  pageParams: PageParams = new PageParams();
  @Input() summaryPage = false;
  @Input() hasError: boolean;
  @Input() isCreateMode: boolean;
  @Input() saleOrders: ISaleOrder[];
  @Input() availableTypes: IAvailableTypes;
  @Input() status: number;
  @Output() hasErrorChange = new EventEmitter<boolean>();
  
  currentSort: Sort;
  showLoader: boolean = false;
  ShipmentConsolStatus = ShipmentConsolStatus;

  pageChanged(e: PageParams): void {
    this.pageParams.pageIndex = e.pageIndex;
    this.pageParams.pageSize = e.pageSize;
    // this.getList(e.pageIndex + 1, e.pageSize);
  }

  constructor(
    public userSvc: UserService,
    private saleOrderSvc: SaleOrderService,
    private notify: NotificationService,
    private uploadFileSvc: UploadFileService,
  ) { }

  displayedColumns: string[] = [
    'donwloadDocuments',
    'orderDate',
    'shopName',
    'marketplaceName',
    'marketplaceOrderId',
    'destination',
    'totalOrderVat',
    'totalOrderWithoutVat',
    'trackingNumber',
  ];
  dataSource: MatTableDataSource<IProduct>;

  ngOnInit(): void {
    if (!this.isCreateMode) {
      this.displayedColumns.push('aftershipStatus');
    }
    this.dataSource = new MatTableDataSource<IProduct>(this.saleOrders);
  }

  sortData(sort: Sort): void {
    const data = this.saleOrders.slice();
    if (!sort.active || sort.direction === '') {
      this.dataSource = new MatTableDataSource<IProduct>(this.dataSource.data);
      return;
    }

    this.localSort(sort, data);
    this.currentSort = sort;
  }

  localSort(sort: Sort, data: any): void {
    this.dataSource = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'orderDate': return compare(a?.orderDate, b?.orderDate, isAsc);
        case 'shopName': return compare(a?.marketplace?.saleOrderMarketplace?.shopName, 
            b?.marketplace?.saleOrderMarketplace?.shopName, isAsc);
        case 'marketplaceName': return compare(a?.marketplace?.saleOrderMarketplace?.marketplaceInfo?.name, 
            b?.marketplace?.saleOrderMarketplace?.marketplaceInfo?.name, isAsc);
        case 'marketplaceOrderId': return compare(a?.marketplace?.marketplaceOrderId, 
            b?.marketplace?.marketplaceOrderId, isAsc);
        default: return 0;
      }
    });

    this.dataSource = new MatTableDataSource<IProduct>(this.dataSource.data);
  }

  updateTrackingNumber(element): void {
    this.saleOrderSvc.changeTrackingNumber({
      id: element.id,
      trackingNumber: element.shipmentTrackingNumber
    }).subscribe(value => {
      if (value?.code === 4003) {
        this.notify.showError(value?.message);
      } else {
        this.notify.showSuccessMessage('Updated successfully');
      }
    }, err => {
      this.notify.showError('Something went wrong');
      console.log("ERROR", err);
    })
  }

  downloadDoc(element): void {
    this.saleOrderSvc.downloadFile(element?.id, element?.orderDocuments[0]?.id);
  }

  downloadShow(status: number): boolean {
    return status === FileStatus.UPLOADED;
  }

  uploadShow(status: number): boolean {
    return status == FileStatus.PENDING;
  }

  get documentFileTypes(): string[] {
    return [...this.uploadFileSvc.textFileFormats, ...this.uploadFileSvc.fileFormats]
  }

  
  uploadDoc(el: { saleOrderId: number, id: number, e: any, file: any }): void {
    const files = el.e.dataTransfer ? el.e.dataTransfer.files : el.e.target.files;

    this.saleOrderSvc.uploadFile(files, el.saleOrderId, el.id);

    this.saleOrders.find(saleOrder => saleOrder.id === el.saleOrderId).orderDocuments.find(doc => doc.id === el.id).status = FileStatus.UPLOADED;
  }

  get isShowStatus(): boolean {
    return Number(this.status) === ShipmentConsolStatus.CUSTOMS_CLEARED;
  }

  navigateTo(id){
  }
}

function compare(a: number | string, b: number | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
