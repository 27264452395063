import { Component, OnInit, Inject} from '@angular/core';
import {FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NotificationService } from 'src/app/core/services/notification.service';
import { Router } from '@angular/router';
import { ConstantService } from 'src/app/core/services/constant.service';
import { LocalStorageService } from 'src/app/core/services/local-storage.service';

@Component({
  selector: 'eci-hideshowfields',
  templateUrl: 'hideshowfields.component.html',
  styleUrls: ['hideshowfields.component.scss']
})
export class HideShowFieldsComponent implements OnInit {
  showErrors = false;
  EnableFieldsForm: FormGroup;
  info: any;
  settingName: string;
  indexForSaleOrderTabInPayouts: number = 0;
  constructor(
    public dialogRef: MatDialogRef<HideShowFieldsComponent>,
    private router: Router,
    private constSvc: ConstantService,
    private localStorageSvc: LocalStorageService,
    @Inject(MAT_DIALOG_DATA) private data: any,
  ) {
    this.info = data.fields;
    this.settingName = data.settingName;
    this.indexForSaleOrderTabInPayouts = data?.indexForSaleOrderTabInPayouts || 0;
    if (!!this.indexForSaleOrderTabInPayouts) {
      this.localStorageSvc.set('indexForSaleOrderTabInPayouts', this.indexForSaleOrderTabInPayouts);
    }
  }
  ngOnInit(): void {
    this.constSvc.getSettingsDetails(this.settingName).subscribe(data => {
      if (data.value) {
        this.info = JSON.parse(data.value);
      }
    });
  }

  onNoClick(): void {
    this.dialogRef.close(this.info);
  }

  SaveFieldsSetting(): void{
    const req = {
      name: this.settingName,
      label: this.settingName+' Listview Fields setting',
      value: JSON.stringify(this.info)
    };
    this.constSvc.saveSettings(req).subscribe(data => {
      if (!data) { return; }
      const currentUrl = this.router.url;
      this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
          this.router.navigate([currentUrl]);
      });
    });
    this.dialogRef.close(this.info);
  }
}
