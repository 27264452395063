import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PageParams } from '../../models/page-params.model';

@Component({
  selector: 'eci-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.scss']
})
export class PaginatorComponent{

  constructor() { }

  @Input() pageParams: PageParams = new PageParams();

  @Output() pageChange: EventEmitter<PageParams> = new EventEmitter<PageParams>(null);

  pageChanged(e): void {
    this.pageParams.pageSize = e.pageSize;
    this.pageParams.pageIndex = e.pageIndex
    this.pageChange.emit(this.pageParams);
  }

}
