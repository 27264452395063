export enum ShipmentStatus {
    NEW = 1, // PAYMENT_PENDING
    IN_PROGRESS, // PAYMENT_FAILED
    CUSTOMER_PENDING_BOOKING,
    PENDING_COLLECTION_AT_ORIGIN,
    SHIPMENT_COLLECTED,
    EXPORT_PROCESS,
    SHIPMENT_BOOKED,
    IN_TRANSIT,
    SHIPMENT_ARRIVED,
    CUSTOM_CLEARANCE_PROCESS,
    CUSTOMS_CLEARED,
    SHIPMENT_DELIVERED,
    ORDER_CLOSED_SHIPMENT_RECOVERED_AT_DESTINATION_WAREHOUSE,
    ORDER_CLOSED_SHIPMENT_RECOVERY_GAPS_AT_DESTINATION_WAREHOUSE,
    CANCELED,
    DRAFT,
    PENDING
}