import { Pipe, PipeTransform } from '@angular/core';
import { CustomerType } from './../enums/customer-type.enum';

@Pipe({ name: 'customerType' })
export class CustomerTypePipe implements PipeTransform {
    transform(value: any) {
        switch (Number(value)) {
            case CustomerType.Sole_proprietorship: return 'Sole proprietorship';
            case CustomerType.Partnership: return 'Partnership';
            case CustomerType.LLP: return 'LLP';
            case CustomerType.LLC: return 'LLC';
            case CustomerType.Series_LLC: return 'Series LLC';
            case CustomerType.C_corporation: return 'C corporation';
            case CustomerType.S_corporation: return 'S corporation';
            case CustomerType.Nonprofit_corporation: return 'Nonprofit corporation';
            case CustomerType.Benefit_corporation: return 'Benefit corporation';
            case CustomerType.L3C: return 'L3C';
            case CustomerType['Reporting Tool']: return 'Reporting Tool';
            default: return '';
        }
    }
}
