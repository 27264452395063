import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { method } from 'lodash';
import { Observable } from 'rxjs';
import { LocalStorageService } from 'src/app/core/services/local-storage.service';
import { ReportType } from 'src/app/shared/enums/report-type.enum';
import { ApiService } from '../../../core/services/api.service';
import { map } from 'rxjs/operators';
@Injectable()
export class HsCodesService extends ApiService {
    public checkRoutItem:boolean = false;
    constructor(
        public http: HttpClient,
        public storageBrowser: LocalStorageService
    ) {
        super(http, storageBrowser);
    }
    getList(page: number = 1, limit: number = 100, q: string = ''): Observable<any> {
        return this.request({
            method: 'GET',
            path: `hs-codes`,
            query: {
                q: q,
                limit: limit,
                page: page
            }
        });
    }

    getListHsCode(q: string = ''): Observable<any> {
        return this.request({
            method: 'GET',
            path: `hs-codes/search-hs-code`,
            query: {
                q: q,
            }
        });
    }
    createfileData (body:any): Observable<any> {
        return this.request({
            path: `hs-codes`,
            method: 'POST',
            body: body
        })
    }

    generateXl(searchValue:any): any {
        this.requestFile({
            path: `hs-codes/genrate-excel`,
            method: 'GET',
            query: {
                q: searchValue
            }
        }, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet').subscribe(res => {
            const a = document.createElement('a')
            const objectUrl = URL.createObjectURL(res)
            a.href = objectUrl
            a.download = 'hscode.xlsx';
            a.click();
            URL.revokeObjectURL(objectUrl);
        });
    }

    public applyHsCodes(body:any):Observable<any> {
        this.checkRoutItem = true;
        return this.request({
            path:'hs-codes/apply-hs-code',
            method:'POST',
            body:body
        })
    }

    public hsCodeStatus(id): any{
        return this.request({
            method: 'GET',
            path: `hs-codes/hs-code-status/${id}`           
        });
    }

    public updateHsCodes(id, s):Observable<any> {
        return this.request({
            path:`hs-codes/update-hs-code`,
            method:'POST',
            body: {
                id: id,
                status: s
            }
        })
    }
 }