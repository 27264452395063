import { Component, OnInit } from '@angular/core';
import { Validators, FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { LocalStorageService } from 'src/app/core/services/local-storage.service';
import { NotificationService } from 'src/app/core/services/notification.service';
import { UserService } from 'src/app/core/services/user.service';
import { Roles } from 'src/app/shared/enums/role-types.enum';
import { AuthService } from '../services/auth.service';
import { emailValidator } from 'src/app/core/helpers/helpers.function';
import { MatDialog } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';

@Component({ 
  selector: 'eci-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  public formGroup: FormGroup;
  public formGroup2: FormGroup;
  public isLogin = true;
  public isFirstTime: boolean = true;
  public showAdditionalsFields = false;
  returnUrl: string;

  showPassword: boolean = false;

  constructor(
    private router: Router,
    public authService: AuthService,
    private notify: NotificationService,
    private localStorageSvc: LocalStorageService,
    private userSvc: UserService,
  ) {
    this.formGroup = new FormGroup({
      email: new FormControl('', [
        Validators.required,
        Validators.email,
        Validators.minLength(6),
        Validators.maxLength(256),
        emailValidator()
      ]),
      password: new FormControl('', [
        Validators.required,
        Validators.minLength(8),
        Validators.maxLength(32),
      ])
    });

    this.formGroup2 = new FormGroup({
      email: new FormControl('', [
        Validators.required,
        Validators.email,
        Validators.minLength(6),
        Validators.maxLength(256),
        emailValidator()
      ]),
      code: new FormControl('', [
        Validators.minLength(4),
        Validators.maxLength(256)
      ]),
      password: new FormControl('', [
        Validators.minLength(8),
        Validators.maxLength(256),
        Validators.pattern(/^(?=.*\d)(?=.*[a-z])[0-9a-zA-Z_@./#$^*%&+-]{8,}$/)
      ]),
      repeatPassword: new FormControl('', [
        Validators.minLength(8),
        Validators.maxLength(256),
        Validators.pattern(/^(?=.*\d)(?=.*[a-z])[0-9a-zA-Z_@./#$^*%&+-]{8,}$/)
      ]),
    });

  }

  public ngOnInit() {
    //this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
    if (!this.userSvc.userStorage?.company?.id) {
      this.localStorageSvc.clear();
      return;
    }
    if (this.userSvc.userStorage?.company?.isCompleted) {
      switch (this.userSvc.user.role) {
        case Roles.Admin:
          this.router.navigateByUrl('/customers-management');
          break;
        case Roles.IndVatAccount:
          this.router.navigateByUrl('/dashboard');
          break;
        default:
          this.router.navigateByUrl(`/customers-management/details/${this.userSvc.userStorage.company?.id}`);
          break;
      }
    }
  }

  public onSubmit(): void {
    const { email, password } = this.formGroup.value;

    this.authService.login({ username: email.toLowerCase(), password: password }).subscribe((res) => {
      this.localStorageSvc.set('packageName',res?.packages?.packageName)
      switch (res.message) {
        case 'MESSAGES.USER.INVALID_LOGIN':
          this.notify.showError('Incorrect Login or Password');
          break;
        case 'MESSAGES.USER.USER_DISABLED':
          this.notify.showError('This user disabled');
          break;
        case 'MESSAGES.USER.LOGIN_SUCCESS': {
          this.localStorageSvc.set('token', res.token);
          this.localStorageSvc.set('user', res);
          this.localStorageSvc.set('partnerName',res?.partner);
          localStorage.removeItem('main_auth_token');
          if (res.company && !res.company.isCompleted) {
            if (this.returnUrl) {
              this.router.navigateByUrl(this.returnUrl);
            } else {
              switch (res.role) {
                case Roles.Customer: {
                  this.router.navigateByUrl(`/registration/long-registration?id=${res.company.id}`);
                  this.notify.showInfo('Your registration has been not completed. Please fill all fields and click Finish');
                }; break;
                case Roles.IndVatAccount: {
                  this.router.navigateByUrl(`/registration/ind-vat-registration?id=${res.company.id}`);
                  this.notify.showInfo('Your registration has been not completed. Please fill all fields and click Finish');
                }; break;
                case Roles.OfflineShopOwner: {
                  this.router.navigateByUrl(`/registration/long-registration-offline?id=${res.company.id}`);
                  this.notify.showInfo('Your registration has been not completed. Please fill all fields and click Finish');
                }; break;
              }
              return;
            }
          } else {
            this.userSvc.user$.next(res);
            this.localStorageSvc.set('user', JSON.stringify(this.userSvc.user));
            if (this.userSvc.user.role === Roles.Admin) {
              this.router.navigateByUrl('/admin-profile');
            } else {                
              if (res.role === Roles.Customer ||
                this.userSvc.user.role === Roles.DelicateCustomer ||
                this.userSvc.user.role === Roles.DelicateAdmin ||
                this.userSvc.user.role === Roles.OfflineShopOwner
              ) {
                const redirect = this.localStorageSvc.get('redirectTo');
                if (!!redirect) {
                  const path = redirect.split('?');
                  if (path?.[0] && path?.[0] === 'monthly-payment') {
                    this.router.navigateByUrl(redirect);
                  } else {
                    switch (redirect) {
                      case 'products': this.router.navigateByUrl(`products`);
                        break;
                      case 'online-shop':
                        break;
                      case 'payment-methods':
                      case 'empact-shop':
                      default:
                        this.router.navigateByUrl(`customers-management/details/${res.company.id}`);
                        break;
                    }
                  }
                } else {
                  if (this.returnUrl) {
                    this.router.navigateByUrl(this.returnUrl);
                  } else {
                    this.router.navigateByUrl('dashboard');
                  }
                }
              }
              if (res.role === Roles.IndVatAccount) {
                this.router.navigateByUrl('dashboard');
              }
              if (res.role === Roles.PartnerAccount) {
                this.router.navigateByUrl(`/partners-management/details/${this.userSvc.userId}`);
              } 
              
              if (res.role === Roles.DelegatePartnerAccount) {
                this.router.navigateByUrl(`/partners-management/details/${this.userSvc.user?.parentId?this.userSvc.user?.parentId : this.userSvc.userId}`);
              } 

            }
            //this.router.navigateByUrl('dashboard');
          }

        } break;
        default: break;
      }
    });
  }

  public forgot() {
    if (this.formGroup2.invalid) return;
    const { email, password, code, repeatPassword } = this.formGroup2.value;
    if (password.toString() === repeatPassword.toString()) {
      this.authService.forgotPassword({ username: email, code: code, password: password }).subscribe(res => {
        this.showAdditionalsFields = true;

        if (!this.isFirstTime)
          this.isLogin = true;
        else this.isFirstTime = false;
      }, err => {
        if (!this.showAdditionalsFields) this.notify.showError("User not found in the system")
        else this.notify.showError("Invalid Forgot Code")
      });
    } else {
      this.notify.showError('Passwords does not match');
    }
  }

  checkPasswords(group: FormGroup = this.formGroup2) {
    const pass = group.controls.password.value;
    const confirmPass = group.controls.repeatPassword.value;

    return pass.toString() === confirmPass.toString() ? true : false;
  }

  openChoosePopup(): void {

    if (environment.production) {
        window.location.href = "https://empact.online/pricing/";  // Because now we showing bussiness-type on wordpress website
    } else {
        this.router.navigate(['../../bussiness-type']);
        // const dialogRef = this.dialog.open(BusinessChoosePopupDialog);

        // dialogRef.afterClosed().subscribe(value => {
        //   switch (value) {
        //     case ShopType.Online: this.router.navigate(['../../registration/long-registration']); break;
        //     case ShopType.Offline: this.router.navigate(['../../registration/long-registration-offline']); break;
        //     case ShopType.IndVATNumber: this.router.navigate(['../../registration/ind-vat-registration']); break;
        //   }
        // });
    }
  }

  toggleShowPassword(): void {
    this.showPassword = !this.showPassword;
  }
}
