import {Component} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import { LocalStorageService } from 'src/app/core/services/local-storage.service';
import {ShopType} from './../../enums/shop-type';

@Component({
  selector: 'eci-business-choose-popup',
  templateUrl: './business-choose-popup.component.html',
  styleUrls: ['./business-choose-popup.component.scss']
})
export class BusinessChoosePopupDialog {
  message: string = 'Please select type of your business';

  listOfBusiness: any[] = [{
    name: 'B2C Package',
    type: ShopType.Online,
    packageName: 'B2C',
  }, {
    name: 'B2B Package',
    type: ShopType.Online,
    packageName: 'B2B',
  }, {
      name: 'B2B Offline',
      type: ShopType.Offline,
      packageName: 'B2B Offline',
  }, {
    name: 'Reporting Tool',
    type: ShopType.IndVATNumber,
    packageName: 'Reporting Tool',
    class: 'btn-purple',
  }];

  shopType = ShopType;

  constructor(
    public dialogRef: MatDialogRef<BusinessChoosePopupDialog>,
    private localStorageSvc: LocalStorageService,
  ) {

  }

  chooseType(shopType,packageName): void {
    this.localStorageSvc.set('packageName',packageName);
    this.dialogRef.close(shopType);
  }
}
