export enum ActivitySubscriptionRates {
  'Less than 10,000 Euro' = 1,
  'Between 10,000 - 50,000 Euro',
  'Between 50,000 - 100,000 Euro',
  'Between 100,000 - 500,000 Euro',
  'Between 500,000 - 1,000,000 Euro',
  'Between 1,000,000 - 4,000,000 Euro',
  'Between 4,000,000 - 10,000,000 Euro',
  '10,000,000 Euro and above',
}
