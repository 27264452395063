import { Pipe, PipeTransform } from '@angular/core';
import { AuditLogLevel } from '../enums/audit-log-level.enum';

@Pipe({ name: 'auditLogType' })
export class AuditLogTypePipe implements PipeTransform {
    transform(value: any) {
        switch (value) {
            case AuditLogLevel[AuditLogLevel.ALL]: return 'All';
            case AuditLogLevel[AuditLogLevel.DEBUG]: return 'Debug';
            case AuditLogLevel[AuditLogLevel.INFO]: return 'Info';
            case AuditLogLevel[AuditLogLevel.ERROR]: return 'Errors';
        }
    }
}