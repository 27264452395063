import { Component, OnInit } from '@angular/core';
import {ReportingToolService} from '../../../reporting-tool.service';

@Component({
  selector: 'eci-reports-per-states',
  templateUrl: './reports-per-states.component.html',
  styleUrls: ['./reports-per-states.component.scss']
})
export class ReportsPerStatesComponent implements OnInit {

  constructor(
      public reportingToolService: ReportingToolService
  ) { }

  ngOnInit(): void {
      this.reportingToolService.selectedCompany = null;
      this.reportingToolService.selectedShop = null;
      this.reportingToolService.selectedCountry = null;
  }

}
