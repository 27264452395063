import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import { Subject } from 'rxjs';
import { ApiService } from './api.service';
import { LocalStorageService } from 'src/app/core/services/local-storage.service';

@Injectable({
    providedIn: 'root'
})
// @ts-ignore
export class OrderShipmentService extends ApiService {

    public fileLoadedObserver = new Subject<any>();
    public amountNotHandledConsolShipment$: BehaviorSubject<number> = new BehaviorSubject<number>(0);

    constructor(
        public http: HttpClient,
        public storageBrowser: LocalStorageService,
    ) {
        super(http, storageBrowser);
    }

    getAmountHandled(isHandled: boolean) {
        return this.request({
            method: 'GET',
            path: `orders/get-handled/counts`,
            query: {
                handleStatus: isHandled,
            }
        })
    }
}
