import {Component, OnInit} from '@angular/core';
import {RegistrationService} from '../../customer-registration/services/registration.service';

@Component({
    selector: 'eci-invoices',
    templateUrl: './invoices.component.html',
    styleUrls: ['./invoices.component.scss']
})
export class InvoicesComponent implements OnInit {

    showLoader: boolean = false;
    companyList = [];

    constructor(
        public regSvc: RegistrationService,
    ) {}

    ngOnInit(): void {
        this.regSvc.getCompanyListForSales('').subscribe(data => {
            if (!data) {
                return;
            }
            this.companyList = data;
        });
    }

    setLoader(e: boolean) {
        setTimeout(() => {
            this.showLoader = e;
        }, 0)
    }
}
