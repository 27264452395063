import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatTableExporterModule} from 'mat-table-exporter';
import {MatSelectInfiniteScrollModule} from 'ng-mat-select-infinite-scroll';
import {NgxMatSelectSearchModule} from 'ngx-mat-select-search';
import {CoreModule} from '../../core/core.module';
import {LoaderModule} from '../../shared/directives/loader/loader.module';
import {CustomerManagementService} from '../customer-management/services/customer-management.service';
import {MaterialModule} from '../material.module';
import {TaxReportService} from '../new-tax-report-management/services/tax-report.service';
import {ReportingToolRoutingModule} from './reporting-tool-routing.module';
import {ReportingToolService} from './reporting-tool.service';
import {AddVatModule} from './tax-reports/components/add-vat/add-vat.module';
import {RTTableTaxReportComponent} from './tax-reports/components/table-tax-report/table-tax-report.component';
import {TaxReportsComponent} from './tax-reports/tax-reports.component';
import { IossOssComponent } from './tax-reports/components/ioss-oss/ioss-oss.component';
import { ReportsPerStatesComponent } from './tax-reports/components/reports-per-states/reports-per-states.component';
import { TaxReportTabComponent } from './tax-reports/components/tax-report-tab/tax-report-tab.component';

@NgModule({
    declarations: [
        TaxReportsComponent,
        IossOssComponent,
        ReportsPerStatesComponent,
        RTTableTaxReportComponent,
        TaxReportTabComponent,
    ],
    imports: [
        FormsModule,
        ReactiveFormsModule,
        CommonModule,
        MaterialModule,
        ReportingToolRoutingModule,
        CoreModule,
        LoaderModule,
        MatSelectInfiniteScrollModule,
        MatTableExporterModule,
        NgxMatSelectSearchModule,
        AddVatModule
    ],
    providers: [
        TaxReportService,
        CustomerManagementService,
        ReportingToolService
    ]
})
export class ReportingToolModule {
}
