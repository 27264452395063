import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot } from '@angular/router';
import { Roles, UserRoles } from 'src/app/shared/enums/role-types.enum';
import { UserService } from './user.service';

@Injectable()
export class AuthGuard implements CanActivate, CanActivateChild {
    constructor(
        private router: Router,
        public userService: UserService
    ) { }

    public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        console.log(this.userService.user);
        if (this.userService.user) {
            return true;
        } else {
            this.router.navigate(['/auth/login'], { queryParams: { returnUrl: state.url } });
            return false;
        }
    }

    canActivateChild(route: ActivatedRouteSnapshot): Promise<boolean> | boolean {
        let myRole = this.userService.user.role;

        let roles = route.data['roles'] as Array<string>;
        if (!roles || roles.indexOf(myRole) != -1 || myRole === 'OFFLINE_SHOP_OWNER') return true;
        else if (myRole === Roles.PartnerAccount ||
            myRole === Roles.DelegatePartnerAccount) {
            return true;
            //this.router.navigateByUrl(`/partners-management/details/${this.userService.userId}`);
        }
        else {
            this.router.navigate(['/customers-order']); //или на страницу авторизации
            return false;
        }
    }
}
