export enum SaleOrderStatus {
    NEW,
    PAID,
    COLLECTED,
    IN_TRANSIT,
    DELIVERED,
    PENDING_COLLECTION,
    PENDING_CUSTOMER_COLLECTION,
    DELIVERY_ATTEMPT_FAILED,
    DELIVERY_ADDRESS_CHANGED,
    DELIVERY_REFUSED,
    CUSTOM_DELAY,
    SHIPMENT_DELAYED,
    DELAYED_DUE_PAYMENT,
    INCORRECT_ADDRESS,
    SHIPMENT_REJECTED,
    SHIPMENT_RETURNED,
    SHIPMENT_LOST,
    SHIPMENT_ARRIVED_DESTINATION_PORT,
    CUSTOM_CLEARED,
    CUSTOM_CLEARANCE_PROCESS,
    SHIPMENT_BACK_2_TRANSIT,
    NEW_ADDRESS_RECEIVED,
    DELIVERY_IN_PROCESS,
    SHIPMENT_CANCELED,
    DELAYED,
    PENDING_DELIVERY,
    CANCELED,
    UPDATE,
    REORDER,
    EARLY_CANCELATION,
    PENDING,
    ABANDODED_CHECKOUT,
    PAYMENT_DECLINED,
    CLOSED,
    PARTIAL_SHIPMENT,
    REFUNDED,
}
