import { Component, OnInit, Inject} from '@angular/core';
import {FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NotificationService } from 'src/app/core/services/notification.service';
import { Router } from '@angular/router';
import { ConstantService } from 'src/app/core/services/constant.service';
import { CustomerManagementService } from 'src/app/modules/customer-management/services/customer-management.service';

@Component({
  selector: 'eci-restorepayoneraccount',
  templateUrl: 'restorepayoneraccount.component.html',
  styleUrls: ['restorepayoneraccount.component.scss']
})
export class RestorePayonerAccountComponent implements OnInit {
  showErrors = false;
  EnableFieldsForm: FormGroup;
  info: any;
  customerId: any;
  settingName: string;
  constructor(
    public dialogRef: MatDialogRef<RestorePayonerAccountComponent>,
    private notify: NotificationService,
    private formBuilder: FormBuilder,
    private router: Router,
    private constSvc: ConstantService,
    private customerManSvc: CustomerManagementService,
    @Inject(MAT_DIALOG_DATA) private data: any,
  ) {
    this.info = data.payoneerAccounts;
    this.customerId = parseInt(data.customerId)
  }
  ngOnInit(): void {}
 
  onNoClick(): void {
    this.dialogRef.close();
  }

  retriveAccount(payeeId): void{
    const req = {
      payeeId: payeeId,
      companyId: this.customerId
    };
    this.customerManSvc.restoreDeletedPayoneerAccounts(req).subscribe(data => {
      this.dialogRef.close(payeeId);
    });
  }
}