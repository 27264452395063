import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProductAllocationCartonComponent } from '../shared/components/product-allocation-carton/product-allocation-carton.component';
import { ProductAllocationModalComponent } from '../shared/components/product-allocation-modal/product-allocation-modal.component';
import { ProductAllocationConfirmComponent } from '../shared/components/product-allocation-confirm/product-allocation-confirm.component';
import { AuthGuard } from './services/auth-guard.service';
import { NotificationService } from './services/notification.service';
import { LocalStorageService } from './services/local-storage.service';
import { ToastrService } from 'ngx-toastr';
import { ConstantService } from './services/constant.service';
import { UploadFileService } from './services/upload-file.service';
import { UploadAgreementService } from './services/upload-agreement.service';
import { PaginatorComponent } from './components/paginator/paginator.component';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MaterialModule } from '../modules/material.module';
import { AgreementsComponent } from './components/agreements/agreements.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RegistrationService } from '../modules/customer-registration/services/registration.service';
import { CanvasComponent } from './components/canvas/canvas.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { SharedModule } from '../shared/shared.module';
import { SHARED_PIPES } from '../shared/pipes';
import { CategoriesService } from './services/categories.service';
import { StripeFormComponent } from './components/stripe-form/stripe-form.component';
import { AgentService } from './services/agent.service';
import { MatSelectSearchComponent } from './components/mat-select-search/mat-select-search.component';
import { MarketplacesService } from './services/marketplaces.service';
import { CompanyMarketplaceService } from './services/company-marketplaces.service';
import { CompanyService } from './services/company.service';
import { PaymentTermsService } from './services/payment-terms.service';
import { SaleOrderTemplateService } from './services/so-template.service';
import { SaleOrderFromXlsxService } from './services/so-from-xlsx.service';
import { CronJobHistoryService } from './services/cron-job-history.service';

@NgModule({
    declarations: [
        PaginatorComponent,
        AgreementsComponent,
        CanvasComponent,
        ...SHARED_PIPES,
        StripeFormComponent,
        MatSelectSearchComponent,
        ProductAllocationCartonComponent,
        ProductAllocationModalComponent,
        ProductAllocationConfirmComponent
    ],
    imports: [
        CommonModule,
        MaterialModule,
        MatPaginatorModule,
        ReactiveFormsModule,
        MatProgressSpinnerModule,
        SharedModule,
        FormsModule
    ],
    providers: [
        AuthGuard,
        NotificationService,
        LocalStorageService,
        ToastrService,
        ConstantService,
        UploadFileService,
        UploadAgreementService,
        RegistrationService,
        CategoriesService,
        AgentService,
        MarketplacesService,
        CompanyMarketplaceService,
        CompanyService,
        PaymentTermsService,
        SaleOrderTemplateService,
        SaleOrderFromXlsxService,
        CronJobHistoryService
    ],
    exports: [
        PaginatorComponent,
        CanvasComponent,
        MatSelectSearchComponent,
        ...SHARED_PIPES
    ]
})
export class CoreModule {
}
