import {Injectable} from '@angular/core';
import {ApiService, IApiParams} from './api.service';
import {Observable} from 'rxjs';

@Injectable({providedIn: 'root'})
export class CompanyMarketplaceService extends ApiService {

  getListOfMarketplaces(page: number = 1, limit: number = 20, companyId: number | string): Observable<any> {
    return this.request({
      path: `company-marketplaces/${companyId}`,
      method: 'GET',
      query: {
        page: page,
        limit: limit,
        companyId
      }
    });
  }

  getSkuVaultChannelId(skuVaultChannelId: any | string): Observable<any> {
    return this.request({
      path: `company-marketplaces/skuVaultChannelId/${skuVaultChannelId}`,
      method: 'GET'
    });
  }

  getskuVaultShopId(getskuVaultShopId: any | string): Observable<any> {
    return this.request({
      path: `company-marketplaces/skuVaultShopId/${getskuVaultShopId}`,
      method: 'GET'
    });
  }

  getListOfShops(companyId: number | string, q?: string): Observable<any> {
    let data: IApiParams = {
      path: `company-marketplaces/shops/${companyId}`,
      method: 'GET',
    }
    if (q) {
      data['query'] = {q};
    }
    return this.request(data);
  }

  delete(id): Observable<any> {
    return this.request({
      path: `company-marketplaces/${id}`,
      method: 'DELETE',
    });
  }

  deletePaymentDetails(id): Observable<any> {
    return this.request({
      path: `company-marketplace-payment/${id}`,
      method: 'DELETE',
    });
  }
}
