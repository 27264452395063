import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { RegistrationService } from 'src/app/modules/customer-registration/services/registration.service';
import { SaleOrderService } from 'src/app/modules/sales-order-management/services/sale-order.service';
import { NotificationService } from 'src/app/core/services/notification.service';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-customer-popup',
  templateUrl: './customer-popup.component.html',
  styleUrls: ['./customer-popup.component.scss']
})
export class CustomerPopupDialog implements OnInit {

  form: FormGroup;
  searchCustomerName: FormControl = new FormControl('');
  companyList: any[];

  previousePageCustomer: number = 0;
  totalPagesCustomers: number = 0;
  showLoader: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<CustomerPopupDialog>,
    private regSvc: RegistrationService,
    private saleOrderSvc: SaleOrderService,
    private notify: NotificationService
  ) {
  }

  ngOnInit(): void {
    this.form = this.initializeForm();
    this.getList();

    this.form.controls.searchCustomerName.valueChanges.pipe(debounceTime(500)).subscribe(value => {
      this.previousePageCustomer = 0;
      this.getList();
    })
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onSubmit(): void {
    if (this.form.invalid) {
      return;
    }
    this.showLoader = true;
    this.saleOrderSvc.createMultipleOrder({ companyId: this.form.controls?.customerName?.value}).subscribe(value => {
      this.notify.showSuccessMessage(`${value} Orders was created successfully`);
      this.dialogRef.close();
    }, (err) => {
      this.notify.showError(err.error.message);
      this.showLoader = false;
    });
  }

  initializeForm(): FormGroup {
    return this.formBuilder.group({
      customerName: ['', [Validators.required]],
      searchCustomerName: ['']
    });
  }

  getList(
    page: number = this.previousePageCustomer + 1,
    limit: number = 20,
    q: string = this.form.controls.searchCustomerName.value,
  ): void {
    this.regSvc.getCompanyList(limit, page, q).subscribe(data => {
      if (!data) return;

      if (data.meta.currentPage > 1) {
        this.companyList = [...this.companyList, ...data.items];
      } else {
        this.companyList = data.items;
      }

      this.totalPagesCustomers = data.meta.totalPages;
      this.previousePageCustomer = data.meta.currentPage;
    })
  }
}
