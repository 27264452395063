import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Observable } from 'rxjs';

@Injectable()
export class CronJobHistoryService extends ApiService {

    private baseUrl = 'cron-job-history';

    getAll(filters?: any): Observable<any> {
        return this.request({
            path: `${this.baseUrl}`,
            method: 'GET',
            query: filters,
        })
    }

    delete(id: number): Observable<any> {
        return this.request({
            path: `${this.baseUrl}/${id}`,
            method: 'DELETE',
        });
    }
}
