import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { IAgentInfo } from 'src/app/core/models/agent.interface';
import { IAvailableCourierItem } from 'src/app/core/models/available-courier.interface';
import { IAvailableTypes } from 'src/app/core/models/available-types.interface';
import { ConstantService } from 'src/app/core/services/constant.service';
import { NotificationService } from 'src/app/core/services/notification.service';
import { UserService } from 'src/app/core/services/user.service';
import { IOrderHistory } from '../../models/order-history.interface';
import { ICompany } from 'src/app/modules/customer-registration/models/company.model';
import { ISaleOrder } from 'src/app/modules/sales-order-management/models/sale-order.interface';
// import { ConsolidateShipmentService } from '../../services/consolidate-shipments.service';
import { ConsolidateShipmentService } from '../../../../core/services/consolidate-shipments.service';
import { OrderType } from 'src/app/shared/enums/order-type.enum';
import { InvoiceType } from 'src/app/shared/enums/invoice-type.enum';
import { PaymentService } from 'src/app/modules/customers-order-management/services/payment.service';
import { StripeFormComponent } from 'src/app/core/components/stripe-form/stripe-form.component';
import { ShipmentType } from 'src/app/shared/enums/shipment-type.enum';
import { CargoType } from 'src/app/shared/enums/cargo-type.enum';
import { ShipmentStatus } from 'src/app/shared/enums/shipment-status.enum';

export const AMOUNT_OF_DOCUMENTS = 6;

@Component({
  selector: 'eci-consolidate-shipments-details',
  templateUrl: './consolidate-shipments-details.component.html',
  styleUrls: ['./consolidate-shipments-details.component.scss']
})
export class ConsolidateShipmentsDetailsComponent implements OnInit {

  summaryPage = false;
  header = 'Consolidate Shipment #';
  marginTop = '80px';
  createMode = false;
  shipmentOrderType = OrderType.Import;
  info: any;
  orderHistory: IOrderHistory[];
  availableTypes: IAvailableTypes;

  availableCourier: IAvailableCourierItem[];
  availableAgents: IAgentInfo[];
  currentOrderId: number;

  saleOrders: ISaleOrder[];
  saleOrderProducts: any[] = [];

  availableStates: string[];
  statusChanged = false;
  actualDate: string;
  companyId: number;
  companyInfo: ICompany;
  currentOrderStatus: number;
  currShipmentType: number;

  showErrors = false;

  selectedTab = 0;
  showLoader = false;
  destinationPortCountryCode: string;
  orderType: number;

  errors = [
    true,
    true,
    true,
    true,
  ];

  get totalPay(): string {
    return this.info?.invoices?.find(el => el.invoiceType === InvoiceType.SERVICE_INVOICE)?.grandTotal?.toFixed(2);
  }

  get isPaid(): boolean {
    if (!this.info) { return; }
    return this.info?.invoices?.find(el => el.invoiceType === InvoiceType.SERVICE_INVOICE)?.isPayed;
  }

  constructor(
    public userSvc: UserService,
    private constSvc: ConstantService,
    private route: ActivatedRoute,
    public dialogRef: MatDialogRef<ConsolidateShipmentsDetailsComponent>,
    private notify: NotificationService,
    private router: Router,
    private consolShipmentSvc: ConsolidateShipmentService,
    private paymentSvc: PaymentService,
    private dialog: MatDialog,
    private cdr: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.getAvailableTypes();

    this.showLoader = true;
    this.route.params.subscribe(values => {
      if (!!values.id) {
        this.consolShipmentSvc.getShipmentById(values.id).subscribe(data => {
          if (!!data) {
            this.header = `Consolidate shipment #${data.consolNumber}`;
            this.info = data;
            this.saleOrders = data?.saleOrders;
            this.setupSaleOrderProducts();
          }
        });
      } else {
        this.setupSaleOrderProducts();
      }
    });

    this.constSvc.availableCourier$.subscribe(couriers => {
      if (!couriers) { return; }
      this.availableCourier = couriers;
    });

    if (!this.createMode) {
      this.errors = [false, false, false, false];
    }
  }

  isAdmin() {
    return this.userSvc.isAdmin || this.userSvc.isDelegatedAdmin;
  }

  handleSwitch() {
    this.route.params.subscribe(values => {
      if (!!values.id) {
      const ids = [values.id];
        this.showLoader = true;
        this.consolShipmentSvc.switchHandled(ids).subscribe(data => {
            this.showLoader = false;
        });
      }
    });

}

    ngAfterViewChecked() {
        this.cdr.detectChanges();
    }

  setupSaleOrderProducts(): void {
    this.saleOrders?.forEach(el => {
      if (!el) {
        return;
      }
      el.products?.forEach(product => {
        const findProduct = this.saleOrderProducts?.findIndex(prod =>
          product?.product?.sku === prod?.product?.sku &&
          product?.costPriceUnit === prod?.costPriceUnit
        );

        if (findProduct !== -1) {
          this.saleOrderProducts[findProduct].units += product.units;
        } else {
          this.saleOrderProducts = [...this.saleOrderProducts, product];
        }
      });
    });

    this.showLoader = false;
  }

  getAvailableTypes(): void {
    this.constSvc.availableTypes.subscribe(data => {
      this.availableTypes = data;
    });
    // this.constSvc.getAvailableTypes();
  }

  save(): void {
    this.showErrors = true;
    this.info.shipmentStatus = ShipmentStatus.NEW;

    if (!this.canSave) {
      if (this.info?.shipment?.cargoType !== CargoType.FCL && Number(this.info?.packageDetails?.chargeableWeight) <= 0) {
        this.notify.showError('Chargeable weight should be more than 0');
        this.selectedTab = 2;
        return;
      }

      if (this.info?.pickupInfos?.length <= 0) {
        this.notify.showError('Should have at least one pickup information');
        this.selectedTab = 3;
        return;
      }

      this.notify.showError('Not all fields are filled');
      this.selectedTab = this.errors.findIndex(el => el);
      return;
    }

    if (this.info?.packageDetails?.pallets?.length <= 0) {
      this.notify.showError('Should have at least one pallet');
      this.selectedTab = 2;
      return;
    }

    this.showLoader = true;

    if (this.createMode) {
      this.info.orderIds = this.saleOrders.map(el => el.id);
      this.consolShipmentSvc.addShipment(this.info).subscribe(data => {
        if(!data) {
          this.notify.showError('Consol shipment not created for this user !!!');
          this.showLoader = false;
        } else {
          this.showLoader = false;
          this.dialogRef.close();
          this.router.navigate([`/consolidate-shipments/consol-shipment-details/${data.id}`]);
        }
      }, err => {
        this.notify.showError(err?.error?.errors?.data);
        this.showLoader = false;
      });
      this.showSummaryPage();

    } else {
      // if (!this.statusChanged) return;
      // this.consolShipmentSvc.updateOrder(this.info.id, {"event" : this.info.status}).subscribe(data => {
      //   this.showLoader = false;
      // })
    }
  }

  showSummaryPage() {
    this.dialogRef.close({ type: 'summaryPage', data: this.info, status: 'open' });
  }

  editShipment() {
    this.dialogRef.close({ type: 'createPage', data: this.info, status: 'open' });
  }
  saveDraft(){
    this.info.shipmentStatus = ShipmentStatus.DRAFT;
    this.confirm(false);
  }

  confirm(checkProducts = true){

    const orderIds = [];
    this.saleOrders.forEach(d => {
      orderIds.push(d.id);
    });
    if(checkProducts)
    {
      this.info.productAllocation.forEach(prdAlcd => {
        if (Number(prdAlcd.not_allocated) !== 0) {
            this.info.shipment.shipmentStatus = ShipmentStatus.PENDING;
        }
      });
    }
    this.info.orderIds = orderIds;
    delete this.info['paletform'];
    delete this.info['cartonform'];
    this.consolShipmentSvc.addShipment(this.info).subscribe(data => {
      if(!data) {
        this.notify.showError('Consol shipment not created for this user !!!');
        this.showLoader = false;
      } else {
        this.showLoader = false;
        this.dialogRef.close();
        this.router.navigate([`/consolidate-shipments/consol-shipment-details/${data.id}`]);
      }
    }, err => {
      console.log(err);
      this.notify.showError(err?.error?.errors?.data);
      this.showLoader = false;
    });
  }

  get canSave(): boolean {
    console.log(this.errors);
    return !this.errors.find(el => el);
  }

  get sum(): number {
    if (!this.info) { return 0; }
    return (this.info?.invoices?.find(el => el.invoiceType === InvoiceType.SHIPPING_INVOICE)?.grandTotal || 0)?.toFixed(2);
  }

  exit(): void {
    this.dialogRef.close();
  }

  get isAllFilesUploaded(): boolean {
    return this.info?.documents?.length >= AMOUNT_OF_DOCUMENTS; // for now amount of documents equal to 6
  }

  update(): void {
    this.showErrors = true;

    if (!this.canSave) {
      if (this.info?.shipment?.cargoType !== CargoType.FCL && Number(this.info?.packageDetails?.chargeableWeight) <= 0) {
        this.notify.showError('Chargeable weight should be more than 0');
        this.selectedTab = 2;
        return;
      }

      if (this.info?.pickupInfos?.length <= 0) {
        this.notify.showError('Should have at least one pickup information');
        this.selectedTab = 3;
        return;
      }

      this.notify.showError('Not all fields are filled');
      this.selectedTab = this.errors.findIndex(el => el);
      return;
    }

    if (this.info?.packageDetails?.pallets?.length <= 0) {
      this.notify.showError('Should have at least one pallet');
      this.selectedTab = 2;
      return;
    }

    this.showLoader = true;

    this.info.orderIds = this.saleOrders.map(el => el.id);
    this.consolShipmentSvc.updateShipment(this.info?.id, this.info).subscribe(data => {
      this.reload();
    }, err => {
      this.notify.showError(err?.error?.errors?.data);
      this.showLoader = false;
    });
  }

  reload(): void {
    this.showLoader = true;
    this.consolShipmentSvc.getShipmentById(this.info.id).subscribe(value => {
      this.info = value;
      this.showLoader = false;
    });
  }

  pay() {
    this.paymentSvc.getShipmentConsolClientSecret(this.info.id).subscribe(data => {
    const dialogRef = this.dialog.open(StripeFormComponent, {
      // opening dialog here which will give us back stripeToken
      width: '400px',
      data: this.info
    });
    dialogRef.componentInstance.clientSecret = data.clientSecret;
    dialogRef.componentInstance.type = 'shipment-consol';
    dialogRef.afterClosed()
      // waiting for stripe token that will be given back
      .subscribe((result: any) => {
        if (result) {
          if (result.error) {
            this.notify.showError(result.error.message);
          } else {
            this.notify.showSuccessMessage('Payment completed successfully');
          }
          this.paymentSvc.createShipmentConsolPayment(this.info.id, result).subscribe(() => {
            this.consolShipmentSvc.getShipmentById(this.info.id).subscribe(data => {
              this.info = data;
              this.currentOrderStatus = Number(this.info.status);
            });
          });
        }
      });
    }, (err => {
      this.notify.showError(err.error.message);
    }));
  }
}
