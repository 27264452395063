import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
    selector: 'eci-partner-block',
    templateUrl: './partner-block.component.html',
    styleUrls: ['./partner-block.component.scss']
})
export class PartnerBlockComponent implements OnInit {

    @Input() isInvoice: boolean;
    @Input() isUKDelivery: boolean;
    @Input() euPartnerOf = '';
    @Output() euPartnerOfChange = new EventEmitter<string>();
    @Input() ukPartnerOf = '';
    @Output() ukPartnerOfChange = new EventEmitter<string>();

    constructor() {
    }

    ngOnInit(): void {
    }

}
