import { Pipe, PipeTransform } from "@angular/core";
import { IConstantItem } from 'src/app/core/models/available-types.interface';
import { DocType } from "../enums/doc-type.enum";
import * as _ from 'lodash';

@Pipe({ name: 'docType', pure: false })
export class DocTypePipe implements PipeTransform {
    transform(value: any, array: IConstantItem[]) {
        if (!array) return '';
        const elem = array.find(el => el.id === value)
        if(!elem) return '';
        let name = elem.name.split('_');
        return name.length > 1 ? _.startCase(_.toLower(name.join(' '))) : name;
    }
    // transform(value: any) {
    //     switch (value) {
    //         case DocType.AWB: return 'AWB';
    //         case DocType.BL: return 'BL';
    //         case DocType.CMR: return 'CMR';
    //         case DocType.CustomDeclaration: return 'Customer Declaration';
    //         case DocType.DeliveryNote: return 'Delivery Note';
    //         case DocType.EX1: return 'EX1';
    //         case DocType.PackingList: return 'Packing List';
    //         case DocType.SignedPod: return 'Signed Pod';
    //         case DocType.T1: return 'T1';
    //         default: return ''
    //     }
    // }
}