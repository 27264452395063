import { DatePipe } from '@angular/common';
import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { IAvailableTypes, IConstantItem } from '../../../../../core/models/available-types.interface';
import { PageParams } from '../../../../../core/models/page-params.model';
import { CompanyMarketplaceService } from '../../../../../core/services/company-marketplaces.service';
import { CompanyService } from '../../../../../core/services/company.service';
import { ConstantService } from '../../../../../core/services/constant.service';
import { NotificationService } from '../../../../../core/services/notification.service';
import { UserService } from '../../../../../core/services/user.service';
import { HideShowFieldsComponent } from '../../../../../shared/popups/hideshow-fields/hideshowfields.component';
import { RegistrationService } from '../../../../customer-registration/services/registration.service';
import { IOrderDetails } from '../../../../customers-order-management/models/order-details.model';
import { TaxReportService } from '../../../../new-tax-report-management/services/tax-report.service';
import { IOrderListItem } from '../../../../sales-order-management/models/order-list-item';
import { ISaleOrder } from '../../../../sales-order-management/models/sale-order.interface';
import {GlobalSettingsService} from '../../../../setting/services/global-settings.service';
import { ReportingToolService } from '../../../reporting-tool.service';
import { Field_List } from '../../tax-reports.component';
import { TaxReportType } from '../../../../../shared/enums/tax-report-type.enum';

@Component({
    selector: 'eci-tax-report-tab',
    templateUrl: './tax-report-tab.component.html',
    styleUrls: ['./tax-report-tab.component.scss']
})
export class TaxReportTabComponent implements OnInit {

    accountType = '';
    reportType = '';
    companyId = null;
    companyCode = '';
    eoriCode = '';
    companyCodeForPDFXLS = '';

    pageParams: PageParams = new PageParams();
    availableTypes: IAvailableTypes;
    currentSort: Sort;

    form: FormGroup;
    ordersList: IOrderListItem[];
    showReportText: string = '';

    dataSource;
    userCountries: any[] = [];
    orders: IOrderDetails[];
    saleOrders: ISaleOrder[];

    showLoader = false;
    previousUrl: string = null;
    currentUrl: string = null;

    countriesEU: any[] = [];
    countriesAdmin: any[] = [];
    countriesUser: any[] = [];
    displayedCountries: any[] = [];

    previousePageCustomer: number = 0;
    companyList: any[];
    selectedIds: any = [];
    @ViewChild('table') table: ElementRef;
    sumOfNumericFields: any = [];
    routeSubscription: Subscription;
    companyCodes = {
        vat: null,
        ioss: null,
        oss: null
    }

    displayedColumns: string[] = [
        'customerId',
        'customerName',
        'orderId',
        'orderType',
        'orderDate',
        'marketPlaceName',
        'marketPlaceOrderId',
        'vatId',
        'customerType',
        'currency',
        'stripeAmount',
        // 'eciVat',
        'stripeRefundAmount',
        'invoiceAmount',
        'invoiceVatAmount',
        'marketPlaceInvoiceAmount',
        'marketPlaceInvoiceVatAmount',
        'vatRate',
        'vatCharges',
        'conversionRate',
        'eciVatEuro',
        'eciTotalEuro',
        'shippedFrom',
        'shippedTo',
        'shippingRoute',
        'shippingStatus',
        'deliveryDate'
    ];
    displayedColumnsForPurchases: string[] = [
        'customerId',
        'customerName',
        'orderId',
        'orderType',
        'shipmentDate',
        'shipmentId',
        'vatId',
        'customerType',
        'currency',
        'stripeAmount',
        'stripeRefundAmount',
        'invoiceVatAmount',
        'marketPlaceInvoiceVatAmount',
        'vatRatePurchases',
        'vatCharges',
        'conversionRate',
        'freightCost',
        'shipmentValue',
        'cif',
        'shippedFrom',
        'shippedTo',
        'shippingRoute',
        'shippingStatus',
        'deliveryDate'
    ];

    constructor(
        private activatedRoute: ActivatedRoute,
        private taxReportSvc: TaxReportService,
        public userSvc: UserService,
        private datePipe: DatePipe,
        private constSvc: ConstantService,
        private formBuilder: FormBuilder,
        private regSvc: RegistrationService,
        public dialog: MatDialog,
        private notify: NotificationService,
        private companyMarketplaceSvc: CompanyMarketplaceService,
        private companyService: CompanyService,
        public reportingToolService: ReportingToolService,
        private globalSettingsSvc: GlobalSettingsService,
    ) {
    }

    @HostListener('window:beforeunload', ['$event'])
    unloadNotification() {
        // commented because it's creating an error when we are logout
        // bug https://github.com/Zangula/ECommIn_project_management/issues/3569
        // this.resetReportSession();
        // const req = {
        //     name: 'TAX_REPORT_VISIBLITY_FIELD_RULE',
        //     label: 'TAX_REPORT_VISIBLITY_FIELD_RULE Listview Fields setting',
        //     value: JSON.stringify(Field_List)
        // };
        // this.constSvc.saveSettings(req).subscribe(data => {
        //     this.routeSubscription && this.routeSubscription.unsubscribe();
        //     if (!data) {
        //         return;
        //     }
        // });
    }

    resetReportSession() {
        sessionStorage.removeItem("reportdateto");
        sessionStorage.removeItem("reportdatefrom");
        sessionStorage.removeItem("reportType_oss");
        sessionStorage.removeItem("reportType_state");
        sessionStorage.removeItem("reportCustomerIds");
        sessionStorage.removeItem("reportSelectedCompany");
    }

    ngOnInit(): void {
        this.accountType = this.activatedRoute.snapshot.data.accountType;
        this.reportType = this.activatedRoute.snapshot.data.reportType;
        this.companyId = this.activatedRoute.snapshot.params.companyId;

        this.form = this.initializeForm();

        this.constSvc.availableTypes.subscribe(types => {
            this.availableTypes = types;
        });


        // const customerSelected = JSON.parse(sessionStorage.getItem("reportSelectedCompany")) || null;
        // if (customerSelected) this.reportingToolService.selectedCompany = customerSelected;

        this.form.get('searchCustomerName').valueChanges.pipe(debounceTime(500)).subscribe(() => {
            this.previousePageCustomer = 0;
            this.getLists();
        });
        this.form.get('reportType').valueChanges.subscribe(() => {
            this.setIOSSNumbers();
            this.setCountriesList();
        });

        this.companyService.$company.subscribe(res => {
            if (res) {
                this.companyCodes = {
                    vat: res.companyCode,
                    ioss: res.ioss,
                    oss: res.oss
                }
                this.setIOSSNumbers();
            }
        })

        if (this.accountType === 'ind') {
            if (this.userSvc.isAdmin) {
                this.getListCompanies();
                this.getList();
                this.globalSettingsSvc.getVatDetailsByCompany(this.userSvc.company.id).subscribe(res => {
                    this.countriesAdmin = res.items.map(r => {
                        return {
                            ...r.country,
                            vatNumber: r.vatNumber,
                            eoriNumber: r.eoriNumber
                        }
                    });
                    this.setUserCountries();
                    // this.displayedCountries = this.countriesAdmin;
                });
            } else {
                this.getListShops();
                this.getList();
                this.setUserCountries();
                this.getUserCompany(this.userSvc.company.id);
            }
        } else {
            if (this.userSvc.isAdmin) {
                this.globalSettingsSvc.getVatDetailsByCompany(this.userSvc.company.id).subscribe(res => {
                    this.countriesAdmin = res.items.map(r => {
                        return {
                            ...r.country,
                            vatNumber: r.vatNumber,
                            eoriNumber: r.eoriNumber
                        }
                    });
                    this.setUserCountries();
                    // this.displayedCountries = this.countriesAdmin;
                });
            }
            this.getListCompanies();
            this.getList()
            this.setUserCountries();
        }

        this.getCountries();
        this.setIOSSNumbers();
    }

    initializeForm(): FormGroup {
        let dateFrom = new Date(this.datePipe.transform(sessionStorage.getItem("reportdatefrom") || new Date(), 'yyyy-MM-dd'));
        let dateTo = new Date(this.datePipe.transform(sessionStorage.getItem("reportdateto") || new Date(), 'yyyy-MM-dd'));

        switch (this.reportType) {
            case 'ioss_oss':
                return this.formBuilder.group({
                    dateFrom: [dateFrom],
                    dateTo: [dateTo],
                    reportType: ['1'], //[sessionStorage.getItem("reportType_oss") || '1'],
                    customerName: [null], // [JSON.parse(sessionStorage.getItem("reportCustomerIds")) || null],
                    searchCustomerName: [''],
                });
            case 'per_states':
                return this.formBuilder.group({
                    dateFrom: [dateFrom],
                    dateTo: [dateTo],
                    reportType: ['3'], //[sessionStorage.getItem("reportType_state") || '3'],
                    customerName: [null], //[JSON.parse(sessionStorage.getItem("reportCustomerIds")) || null],
                    searchCustomerName: [''],
                    countryCode: [this.reportingToolService.selectedCountry ? this.reportingToolService.selectedCountry.code : ''],
                    searchCountry: [''],
                });
        }
    }

    pageChanged(e: PageParams): void {
        this.pageParams.pageIndex = e.pageIndex;
        this.pageParams.pageSize = e.pageSize;
        this.getList(this.pageParams?.pageIndex + 1, this.pageParams?.pageSize);
    }

    get isAdmin(): boolean {
        return this.userSvc.isAdmin;
    }

    sortData(sort: Sort): void {
        const data = this.ordersList.slice();
        if (!sort.active || sort.direction === '') {
            this.ordersList = data;
            this.dataSource = new MatTableDataSource<IOrderListItem>(this.ordersList);
            return;
        }

        this.currentSort = sort;
        this.getList(this.pageParams?.pageIndex + 1, this.pageParams?.pageSize);
    }

    formSubmit() {
        sessionStorage.setItem("reportdateto", this.form.get('dateTo').value);
        sessionStorage.setItem("reportdatefrom", this.form.get('dateFrom').value);
        if (this.reportType === 'ioss_oss') {
            sessionStorage.setItem("reportType_oss", this.form.get('reportType').value);
        } else {
            sessionStorage.setItem("reportType_state", this.form.get('reportType').value);
        }

        sessionStorage.setItem("reportCustomerIds", JSON.stringify(this.form.get('customerName').value));
        sessionStorage.setItem("reportSelectedCompany", JSON.stringify(this.reportingToolService.selectedCompany));
        this.getList();
    }

    getList(
        page: number = 1,
        limit: number = 100,
    ): void {
        this.showLoader = true;
        if (
            this.prepareSearchParams.dateFrom &&
            this.prepareSearchParams.dateTo &&
            this.prepareSearchParams.reportType
        ) {

            this.taxReportSvc.getTaxReportRepTool(
                page,
                limit,
                this.prepareSearchParams,
                this.currentSort?.active,
                this.currentSort?.direction.toUpperCase(),
                this.userSvc.isAdmin ? this.reportingToolService.selectedCompany?.id : this.userSvc.user.company.id
            ).subscribe(data => {
                if (!data) {
                    return;
                }
                this.showLoader = false;
                this.dataSource = data.items;
                this.ordersList = data.items;
                this.pageParams.pageSize = data.meta.itemsPerPage;
                this.pageParams.pageIndex = data.meta.currentPage - 1;
                this.pageParams.length = data.meta.totalItems;
                this.showReportText = data.titleText;
                this.sumOfNumericFields = data.sumOfAmountFields;
                // this.userCountries = data.customers ? data.customers : [];
            });
        } else if (
            (
                !this.prepareSearchParams.dateFrom ||
                !this.prepareSearchParams.dateTo
            ) &&
            this.prepareSearchParams.reportType
        ) {
            this.notify.showError('Please select the \'From-To\' date');
            this.showLoader = false;
        } else if (
            !this.prepareSearchParams.reportType &&
            (
                this.prepareSearchParams.dateFrom &&
                this.prepareSearchParams.dateTo
            )
        ) {
            this.notify.showError('Please select the Report type');
            this.showLoader = false;
        } else {
            this.notify.showError('Please select the the \'From-To\' date and \'Report type\'');
            this.showLoader = false;
        }
    }

    get prepareSearchParams(): any {
        return {
            dateTo: this.form.get('dateTo').value && this.form.get('dateTo').value !== null ? this.datePipe.transform(this.form.get('dateTo').value, 'yyyy-MM-dd') : '',
            dateFrom: this.form.get('dateFrom').value && this.form.get('dateFrom').value !== null ? this.datePipe.transform(this.form.get('dateFrom').value, 'yyyy-MM-dd') : '',
            reportType: this.form.get('reportType').value && this.form.get('reportType').value !== null ? this.form.get('reportType').value : '',
            customerIds: this.reportingToolService.selectedShop?.id,
            saleOrderId: this.selectedIds,
            country: this.reportType === 'per_states' ? this.reportingToolService.selectedCountry?.code : '',
            isIndTax: this.accountType === 'ind'
        };
    }

    downloadDoc(id): void {
        this.showLoader = true;
        this.taxReportSvc.downloadInvoice(id).subscribe(() => {
            this.showLoader = false;
        });
    }

    get taxReportTypes(): IConstantItem[] {
        if (!this.availableTypes) {
            return;
        }
        return this.availableTypes.TaxReportType;
    }

    clearFilters(): void {
        this.form.get('dateFrom').setValue(this.datePipe.transform(new Date(), 'yyyy-MM-dd'));
        this.form.get('dateTo').setValue(this.datePipe.transform(new Date(), 'yyyy-MM-dd'));
        if (this.reportType === 'ioss_oss') {
            this.form.get('reportType').setValue('1');
        } else {
            this.form.get('reportType').setValue('3');
            this.form.get('countryCode').setValue('');
        }

        this.form.get('customerName').setValue('');
        this.reportingToolService.selectedCompany = null;
        this.reportingToolService.selectedShop = null;
        this.reportingToolService.selectedCountry = null;
        this.companyCode = '';
        this.eoriCode = '';
        //this.setIOSSNumbers();
        this.setUserCountries();
        this.formSubmit();
    }

    exportToPdf(): void {
        if (this.dataSource.length) {
            const body = {
                ...this.prepareSearchParams,
                reportNumber: this.companyCodeForPDFXLS
            };
            if (this.accountType === 'ind') {
                body.companyId = this.userSvc.isAdmin ? this.reportingToolService.selectedCompany?.id : this.userSvc.user.company.id;
                this.taxReportSvc.generatePdfVat(body);
            } else {
                body.companyId = this.userSvc.isAdmin ? this.reportingToolService.selectedCompany?.id : this.userSvc.user.company.id;
                this.taxReportSvc.generatePdfVat(body);
            }
        } else {
            this.notify.showError('Please generate a tax report!');
        }
    }

    exportToXl(): void {
        if (this.dataSource.length) {
            this.showLoader = true;
            const body = {
                ...this.prepareSearchParams,
                reportNumber: this.companyCodeForPDFXLS
            };
            if (this.accountType === 'ind') {
                body.companyId = this.userSvc.isAdmin ? this.reportingToolService.selectedCompany?.id : this.userSvc.user.company.id;
                this.taxReportSvc.generateXlIndVat(body).subscribe(() => {
                    this.showLoader = false;
                });
            } else {
                body.companyId = this.userSvc.isAdmin ? this.reportingToolService.selectedCompany?.id : this.userSvc.user.company.id;
                this.taxReportSvc.generateXlIndVat(body).subscribe(() => {
                    this.showLoader = false;
                });
            }
        } else {
            this.notify.showError('Please generate a tax report!');
        }
    }

    getLists() {
        if (this.accountType === 'ind') {
            if (this.userSvc.isAdmin) {
                this.getListCompanies();
            } else {
                this.getListShops();
            }
        } else {
            this.getListCompanies();
        }
    }

    getListCompanies(
        q: string = this.form?.get('searchCustomerName').value,
        isIndVat: boolean = this.accountType === 'ind'
    ): void {
        this.regSvc.getCompanyListForRepTool(q, isIndVat).subscribe(data => {
            if (!data) {
                return;
            }
            this.companyList = data;
        });
    }

    downloadSalesInvoice(): void {
        if (this.dataSource.length) {
            const myObj = this.dataSource;
            this.selectedIds = myObj.map(({ referenceId }) => referenceId);
            this.showLoader = true;
            const body = {
                ...this.prepareSearchParams
            };
            this.taxReportSvc.downloadSalesInvoicesIndVat(body).subscribe(() => {
                this.showLoader = false;
            });
        } else {
            this.notify.showError('Please generate a tax report!');
        }
    }

    openHideShowFieldsPopup(): void {
        const dialogRef = this.dialog.open(HideShowFieldsComponent, {
            width: '700px',
            data: { fields: Field_List, settingName: 'TAX_REPORT_VISIBLITY_FIELD_RULE' }
        });
        dialogRef.afterClosed().subscribe(result => {
            if (!result) {
                return;
            }

            if (this.accountType === 'ind') {
                this.getList();
            } else {
                this.getList();
            }

        });
    }

    getCountryNameByCode(value: any) {
        return this.displayedCountries.find(c => c.code === value)?.name;
    }

    getFlagClass(code: string) {
        return `iti__${code?.toLowerCase()}`;
    }

    onClickCountry() {
        if (this.isAdmin) {
            if (this.accountType === 'empact') {
                switch (this.form.get('reportType').value) {
                    case '1':
                        break;
                    case '2':
                        break;
                    case '3':
                        break;
                    case '5':
                        break;
                    case '6':
                        break;
                }
            }
        } else {

        }
        /*if (this.userCountries.length === 0) {
            const dialogRef = this.dialog.open(ConfirmPopupDialog);
            dialogRef.componentInstance.message = 'To select country, please select the customer first.';
            dialogRef.componentInstance.header = 'Information message';
            dialogRef.componentInstance.leftBtnText = 'Close';
            dialogRef.componentInstance.showRightBtn = false;
        }*/
    }

    onSelectCountry(e) {
        this.reportingToolService.selectedCountry = this.displayedCountries.find(c => c.code === e.value);
        this.setIOSSNumbers();
        this.getList();
    }

    onSelectCustomer(e) {
        if (this.userSvc.isAdmin) {
            this.form.get('countryCode')?.setValue('');
            this.reportingToolService.selectedCountry = null;
            this.reportingToolService.selectedCompany = this.companyList.find(c => c.id == e.value);
            this.setIOSSNumbers();
            this.setUserCountries();
        } else {
            this.reportingToolService.selectedShop = this.companyList.find(c => c.id == e.value);
            this.setIOSSNumbers();
            this.setUserCountries();
        }
    }

    setIOSSNumbers() {
        this.companyCode = '';
        this.eoriCode = '';
        if (this.isAdmin) {
            if (this.accountType === 'empact') {
                switch (Number(this.form.get('reportType').value)) {
                    case TaxReportType.IOSS:
                        this.companyCodeForPDFXLS = this.companyCodes.ioss; // 'IM5280003377';
                        this.companyCode = 'IOSS number: ' + (this.companyCodeForPDFXLS ? this.companyCodeForPDFXLS : '');
                        break;
                    case TaxReportType.OSS:
                        this.companyCodeForPDFXLS = this.companyCodes.oss; // this.companyService.$company.value?.oss;
                        this.companyCode = 'OSS number: ' + (this.companyCodeForPDFXLS ? this.companyCodeForPDFXLS : '');
                        break;
                    case TaxReportType.Purchases:
                    case TaxReportType.Regular:
                        this.companyCodeForPDFXLS = this.reportingToolService.selectedCountry?.vatNumber;
                        this.companyCode = 'VAT number: ' + (this.companyCodeForPDFXLS ? this.companyCodeForPDFXLS : '');
                        this.eoriCode = this.reportingToolService.selectedCountry?.eoriNumber ? `EORI number: ${this.reportingToolService.selectedCountry?.eoriNumber}` : '';
                        break;
                    case TaxReportType.Sales:
                        this.companyCodeForPDFXLS = this.companyCodes.vat; // this.companyService.$company.value?.companyCode;
                        this.companyCode = 'VAT number: ' + (this.companyCodeForPDFXLS ? this.companyCodeForPDFXLS : '');
                        break;
                }
            } else {
                const setNumber = (type: string, key: string) => {
                    if (
                        this.reportingToolService.selectedCompany &&
                        this.reportingToolService.selectedCompany?.users.length &&
                        this.reportingToolService.selectedCompany?.users[0][key]) {
                        this.companyCodeForPDFXLS = this.reportingToolService.selectedCompany?.users[0][key];
                        this.companyCode = `${type} number: ${this.companyCodeForPDFXLS ? this.companyCodeForPDFXLS : ''}`;
                    }
                    else if (this.reportingToolService.selectedCompany !== null) {
                        this.companyCode = `${type} number: `;
                    }
                };
                switch (Number(this.form.get('reportType').value)) {
                    case TaxReportType.IOSS:
                        setNumber('IOSS', 'iossNumber');
                        this.eoriCode = '';
                        break;
                    case TaxReportType.OSS:
                        setNumber('OSS', 'ossNumber');
                        this.eoriCode = '';
                        break;
                    case TaxReportType.UK:
                        if (this.reportingToolService.selectedCompany && this.reportingToolService.selectedCompany?.companyCode) {
                            this.companyCodeForPDFXLS = this.reportingToolService.selectedCompany?.companyCode;
                            this.companyCode = `VAT number: ${this.companyCodeForPDFXLS ? this.companyCodeForPDFXLS : ''}`;
                            this.eoriCode = `EORI number: ${this.reportingToolService.selectedCompany?.eoriNumber ? this.reportingToolService.selectedCompany?.eoriNumber : ''}`;
                        }
                        break;
                    case TaxReportType.Purchases:
                    case TaxReportType.Regular:
                        if (this.reportingToolService.selectedCompany && this.reportingToolService.selectedCompany?.companyCode) {
                            if (this.reportingToolService.selectedCountry) {
                                this.companyCodeForPDFXLS = this.reportingToolService.selectedCountry?.c_code;
                                this.companyCode = `VAT number: ${this.companyCodeForPDFXLS ? this.companyCodeForPDFXLS : ''}`;
                                this.eoriCode = `EORI number: ${this.reportingToolService.selectedCountry?.eori ? this.reportingToolService.selectedCountry?.eori : ''}`
                            } /*else {
                                this.companyCodeForPDFXLS = this.reportingToolService.selectedCompany?.companyCode;
                                this.companyCode = `VAT number: ${this.companyCodeForPDFXLS ? this.companyCodeForPDFXLS : ''}`;
                                this.eoriCode = `EORI number: ${this.reportingToolService.selectedCompany?.eoriNumber ? this.reportingToolService.selectedCompany?.eoriNumber : ''}`
                            }*/
                        }
                        break;
                    case TaxReportType.Sales:
                        if (this.reportingToolService.selectedCompany && this.reportingToolService.selectedCompany?.companyCode) {
                            this.companyCodeForPDFXLS = this.reportingToolService.selectedCompany?.companyCode;
                            this.companyCode = `VAT number: ${this.companyCodeForPDFXLS ? this.companyCodeForPDFXLS : ''}`;
                            this.eoriCode = `EORI number: ${this.reportingToolService.selectedCompany?.eoriNumber ? this.reportingToolService.selectedCompany?.eoriNumber : ''}`
                        }
                        break;
                }
            }
        } else {
            const currentCompany = this.userSvc.user;
            //const shopCountryId = this.reportingToolService.selectedShop?.sourceCountryId;
            //const shopCode = this.reportingToolService.userCompany?.companyCodeAdd.find(c => c.country.id == shopCountryId);
            const shopCode = this.reportingToolService.userCompany?.companyCodeAdd.find(c => c.country.id == this.reportingToolService.selectedCountry?.id);
            switch (Number(this.form.get('reportType').value)) {
                case TaxReportType.IOSS:
                    this.companyCodeForPDFXLS = (currentCompany?.iossNumber ? currentCompany.iossNumber : '');
                    this.companyCode = 'IOSS number: ' + (currentCompany?.iossNumber ? currentCompany.iossNumber : '');
                    break;
                case TaxReportType.OSS:
                    this.companyCodeForPDFXLS = (currentCompany?.ossNumber ? currentCompany.ossNumber : '');
                    this.companyCode = 'OSS number: ' + (currentCompany?.ossNumber ? currentCompany.ossNumber : '');
                    break;
                case TaxReportType.UK:
                    this.companyCodeForPDFXLS = '';
                    this.companyCode = '';
                    break;
                case TaxReportType.Regular:
                    if (shopCode) {
                        this.companyCodeForPDFXLS = (shopCode?.code ? shopCode.code : '');
                        this.companyCode = 'VAT number: ' + (shopCode?.code ? shopCode.code : '');
                        this.eoriCode = `EORI number: ${shopCode?.eoriCode ? shopCode?.eoriCode : ''}`
                    } else {
                        this.companyCodeForPDFXLS = (currentCompany?.company?.companyCode ? currentCompany.company.companyCode : '');
                        this.companyCode = 'VAT number: ' + (currentCompany?.company?.companyCode ? currentCompany.company.companyCode : '');
                    }
                    break;
                case TaxReportType.Purchases:
                    if (shopCode) {
                        this.companyCodeForPDFXLS = (shopCode?.code ? shopCode.code : '');
                        this.companyCode = 'VAT number: ' + (shopCode?.code ? shopCode.code : '');
                        this.eoriCode = `EORI number: ${shopCode?.eoriCode ? shopCode?.eoriCode : ''}`
                    } else {
                        this.companyCodeForPDFXLS = (currentCompany?.company?.companyCode ? currentCompany.company.companyCode : '');
                        this.companyCode = 'VAT number: ' + (currentCompany?.company?.companyCode ? currentCompany.company.companyCode : '');
                    }
                    break;
                case TaxReportType.Sales:
                    // this.companyCodeForPDFXLS = '';
                    // this.companyCode = '';
                    if (shopCode) {
                        this.companyCodeForPDFXLS = (shopCode?.code ? shopCode.code : '');
                        this.companyCode = 'VAT number: ' + (shopCode?.code ? shopCode.code : '');
                        this.eoriCode = `EORI number: ${shopCode?.eoriCode ? shopCode?.eoriCode : ''}`
                    } else {
                        this.companyCodeForPDFXLS = (currentCompany?.company?.companyCode ? currentCompany.company.companyCode : '');
                        this.companyCode = 'VAT number: ' + (currentCompany?.company?.companyCode ? currentCompany.company.companyCode : '');
                    }
                    break;
            }
        }
    }

    setUserCountries() {
        this.userCountries = [];
        if (this.reportingToolService.selectedCompany) {
            if (this.accountType === 'ind') {
                if (this.countriesEU.find(c => c.code === this.reportingToolService.selectedCompany.address.state.country.code)) {
                    this.userCountries.push({
                        c_code: this.reportingToolService.selectedCompany.companyCode,
                        eori: this.reportingToolService.selectedCompany.eoriNumber,
                        ioss: this.reportingToolService.selectedCompany.users.length ? this.reportingToolService.selectedCompany.users[0]?.iossNumber : '',
                        oss: this.reportingToolService.selectedCompany.users.length ? this.reportingToolService.selectedCompany.users[0]?.ossNumber : '',
                        code: this.reportingToolService.selectedCompany.address.state.country.code,
                        name: this.reportingToolService.selectedCompany.address.state.country.name,
                    })
                }
                this.reportingToolService.selectedCompany.codes.map(c => {
                    const existingCountry = this.userCountries.find(eC => eC.name === c.country.name);
                    if (!existingCountry) {
                        this.userCountries.push({
                            c_code: c.code,
                            eori: c.eoriCode,
                            code: c.country.code,
                            name: c.country.name,
                        })
                    }
                });
                this.displayedCountries = this.userCountries;
            } else {
                this.displayedCountries = this.countriesAdmin;
            }

        } else {
            if (this.isAdmin) {
                if (this.accountType === 'ind') {
                    this.displayedCountries = this.countriesEU;
                } else {
                    this.displayedCountries = this.countriesAdmin;
                }
            } else {
                this.displayedCountries = this.countriesUser;
            }
        }
    }

    getUserCompany(id) {
        this.companyService.getCompanyById(id).subscribe(c => {
            this.reportingToolService.userCompany = c;

            let usrCountries = [];
            this.reportingToolService?.userCompany?.companyCodeAdd?.forEach(userCountry => {
                this.countriesEU.forEach(country => {
                    if (userCountry.countryId === country.id) {
                        usrCountries.push(country);
                    }
                });
            });
            this.countriesUser = usrCountries;
            this.displayedCountries = usrCountries;
        });
    }

    getListShops(
        q: string = this.form?.controls?.searchCustomerName.value
    ): void {
        this.companyMarketplaceSvc.getListOfShops(this.userSvc.company.id, q).subscribe(data => {
            this.companyList = data;
        });
    }

    getCountries() {
        this.constSvc.getAvailableCountries(1, 1000, true, true).subscribe(data => {
            if (!data) {
                return;
            }
            this.countriesEU = data;
        });
    }

    setCountriesList() {
        if (this.isAdmin) {
            switch (this.form.get('reportType').value) {
                case '3':
                case '5':
                case '6':
                    // this.displayedCountries = this.countriesEU;
                    break;
            }
        } else {
            switch (this.form.get('reportType').value) {
                case '3':
                case '5':
                case '6':
                    // this.displayedCountries = this.countriesEU;
                    break;
            }
        }
    }
}
