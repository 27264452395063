import { Directive, Renderer2, ElementRef, OnChanges, Input } from '@angular/core';

@Directive({
    selector: '[loader]',
})
export class LoaderDirective implements OnChanges {
    @Input('loader') showLoader: boolean = false;
    loader: any;

    constructor(
        private renderer: Renderer2,
        private el: ElementRef) {
        this.renderer.setStyle(el.nativeElement, 'position', 'relative');
        this.loader = this.renderer.createElement('div');
        this.renderer.addClass(this.loader, 'loader');
        this.renderer.appendChild(this.el.nativeElement, this.loader);
    }

    ngOnChanges(): void {
        if (this.showLoader) this.renderer.setStyle(this.loader, 'display', 'flex');
        else this.renderer.setStyle(this.loader, 'display', 'none');
    }

}
