import { Component, Input, OnInit } from '@angular/core';
import {IImage} from '../../core/models/product.interface';

@Component({
    selector: 'eci-image-slider',
    templateUrl: './image-slider.component.html',
    styleUrls: ['./image-slider.component.scss'],
})
export class ImageSliderComponent implements OnInit {

    @Input() public images: IImage[] = [];

    public selectedImage: IImage;
    public index = 0;

    constructor() {
    }

    ngOnInit(): void {
        this.index = 0;
        this.selectedImage = this.getImageByIndex(this.index);
    }

    public onNext(): void {
        this.index += 1;
        this.selectedImage = this.getImageByIndex(this.index);
    }

    public onPrev(): void {
        this.index--;
        this.selectedImage = this.getImageByIndex(this.index);
    }

    public selectImage(index: number): void {
       this.index = index;
       this.selectedImage = this.getImageByIndex(index);
    }

    private getImageByIndex(index): IImage {
        return this.images && this.images.length >= index ? this.images[this.index] : {id: undefined, file: undefined};
    }
}
