import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { LocalStorageService } from './local-storage.service';
import { environment } from '../../../../../environments/environment';
const SERVER_URL = environment.api_url;
const ECOMMERCE_SERVER_URL = environment.api_ecommerce_url;

export interface IApiParams {
    path: string;
    method: 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH';
    query?: any;
    body?: any;
    noAuth?: boolean;
}

@Injectable()
export class ApiService {
    constructor(
        public http: HttpClient,
        public storageBrowser: LocalStorageService
    ) {
    }

    public requestMain(params: IApiParams): Observable<any> {
        const { path, method, body, query } = params;
        const headers = this.getHeader()
        return this.http.request(method, `${SERVER_URL}${path}`, { body, params: query, headers });
    }

    public requestEcommerce(params: IApiParams): Observable<any> {
        const { path, method, body, query } = params;
        const headers = this.getHeader()

        return this.http.request(method, `${ECOMMERCE_SERVER_URL}${path}`, { body, params: query, headers });
    }

    getHeader(): HttpHeaders {
        const token = this.storageBrowser.get('empactToken');
        return new HttpHeaders({
            'Authorization': `Bearer ${token}`,
            Accept: 'application/json',
            'Content-Type': 'application/json',
        });
    }
}
