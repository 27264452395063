import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from '../../../core/services/api.service';

@Injectable()
export class PalletService extends ApiService {

    addPallet(orderId: number, body): Observable<any> {
        return this.request({
            method: 'POST',
            path: `orders/${orderId}/palettes`,
            body: body
        });
    }

    updatePallet(orderId: number, body, id): Observable<any> {
        return this.request({
            method: 'PUT',
            path: `orders/${orderId}/palettes/${id}`,
            body: body
        });
    }

    deletePallete(orderId: number, id: number): Observable<any> {
        return this.request({
            method: 'DELETE',
            path: `orders/${orderId}/palettes/${id}`,
        });
    }
}