import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'paymentStatus' })
export class PaymentStatusPipe implements PipeTransform {
    transform(value: any) {
        switch (value) {
            case 1: return 'Open';
            case 2: return 'Closed';
        }
    }
}