import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {IossOssComponent} from './tax-reports/components/ioss-oss/ioss-oss.component';
import {ReportsPerStatesComponent} from './tax-reports/components/reports-per-states/reports-per-states.component';
import {TaxReportsComponent} from './tax-reports/tax-reports.component';

const routes: Routes = [
    {
        path: '',
        redirectTo: 'empact/ioss-oss',
        pathMatch: 'full'
    },
        {
        path: 'empact',
        component: TaxReportsComponent,
        data: {
            accountType: 'empact'
        },
        children: [
            {
                path: 'ioss-oss',
                component: IossOssComponent,
                data: {
                    accountType: 'empact',
                    reportType: 'ioss_oss'
                }
            },
            {
                path: 'rep-per-states',
                component: ReportsPerStatesComponent,
                data: {
                    accountType: 'empact',
                    reportType: 'per_states'
                }
            }
        ]
    },
    {
        path: 'ind',
        component: TaxReportsComponent,
        data: {
            accountType: 'ind'
        },
        children: [
            {
                path: 'ioss-oss',
                component: IossOssComponent,
                data: {
                    accountType: 'ind',
                    reportType: 'ioss_oss'
                }
            },
            {
                path: 'rep-per-states',
                component: ReportsPerStatesComponent,
                data: {
                    accountType: 'ind',
                    reportType: 'per_states'
                }
            }
        ]
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class ReportingToolRoutingModule {
}
