import { Pipe, PipeTransform } from '@angular/core';
import { IConstantItem } from 'src/app/core/models/available-types.interface';
import * as _ from 'lodash';
import { ShipmentType } from '../enums/shipment-type.enum';

@Pipe({ name: 'carrierTypeTable' })
export class CarrierTypeTablePipe implements PipeTransform {
    transform(value: any, array?: IConstantItem[]) {
        switch (value) {
            case ShipmentType.Air: return 'Air';
            case ShipmentType.Ocean: return 'Ocean';
            case ShipmentType.CourierExpress: return 'Courier Express';
            case ShipmentType.Inland: return 'Inland';
        }
    }
}
