import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { IBaseCategory } from '../models/category.interface';
import { IProductCard, IProductsFilter } from '../models/product.interface';
import { ApiService } from './api.service';

@Injectable({
    providedIn: 'root',
})
export class EmpactShopService extends ApiService {
    shopId$: BehaviorSubject<string> = new BehaviorSubject<string>(undefined);

    public get shopId(): string {
        return this.shopId$.getValue();
    }

    public set shopId(shopId: string) {
        this.shopId$.next(shopId);
    }

    /**
     * Params:
     * page – number of paginator page
     * limit - maximum length of data
     * orderDirection - sorting direction
     * status - product status (active / not active)
     * shopCategoryName - shop category
     * shopId - shop id
     * Returns:
     * Promise a promise that either resolves list of product on observable completion or rejects with the handled error
     */
    public getProducts(filter: IProductsFilter): Observable<IProductCard[]> {
        filter.shopId = this.shopId;
        return this.requestEcommerce({
            method: 'GET',
            path: `shop/marketplace-products`,
            query: filter,
        });
    }

    /**
     * Params:
     * shopId – global shop id
     * Returns:
     * Promise return either resolves list of all category or error
     */
    public getCategories(): Observable<IBaseCategory[]> {
        return this.requestMain({
            method: 'GET',
            path: `categories-marketplace/get-subcategories/${this.shopId}`,
        });
    }

    public IsShopExist(shopId): Observable<boolean> {
        return this.requestMain({
            method: 'GET',
            path: `webhook/is-exist/${shopId}`,
        })
            .pipe(tap((isExist) => {
                if (isExist) {
                    this.shopId$.next(shopId);
                }
            }));
    }

}
