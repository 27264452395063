import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {ApiService} from '../../core/services/api.service';
import {LocalStorageService} from '../../core/services/local-storage.service';
import {NotificationService} from '../../core/services/notification.service';
import {ISaleOrder} from '../sales-order-management/models/sale-order.interface';

export enum EInvManagerTabType {
    'so',
    'shipment',
    'consolidate_shipment'
}

@Injectable({
    providedIn: 'root'
})
export class InvoiceManagementService extends ApiService {

    constructor(
        public http: HttpClient,
        public storageBrowser: LocalStorageService,
        private notify: NotificationService
    ) {
        super(http, storageBrowser);
    }

    public getInvoices(
        page: number = 1,
        limit: number = 100,
        searchParams?: any,
    ): Observable<any> {
        let query: any = {
            limit,
            page,
        };
        if (searchParams) {
            query = {...query, ...searchParams}
        }
        return this.request({
            method: 'GET',
            path: `invoices/list`,
            query
        })
    }

    public getTemplate(templateId: string, historyId?: string): void {
        this.requestPdf({
            method: 'GET',
            path: `invoices/template/${templateId}`,
            query: historyId ? { historyId } : null
        }).subscribe(res => {
            let fileURL = URL.createObjectURL(res);
            window.open(`${fileURL}`, '_blank');
        }, error => {
            this.notify.showError('Invoice is not available due to some error');
        });
    }

    public getTemplateData(templateId: string, historyId?: string): Observable<any> {
        return this.request({
            method: 'GET',
            path: `invoices/template/data/${templateId}`,
            query: historyId ? { historyId } : null
        });
    }

    public setTemplateData(templateId: string, data: any, historyId?: string): Observable<any> {
        return this.request({
            method: 'POST',
            path: `invoices/template/data/${templateId}`,
            body: data,
            query: historyId ? { historyId } : null
        });
    }

    public reIssueInvoice(id, type): Observable<any> {
        return this.request({
            method: 'GET',
            path: `invoices/re-issue/${id}`,
            query: {
                type
            }
        })
    }

    public invoiceUpload(file: File, id: any, type: any): Observable<any> {
        return new Observable<any>(obs => {
            const max_size = 20971520;
            if (file.size > max_size) {
                obs.error('Maximum size allowed is ' + max_size / 1000 + 'Mb');
                return;
            }

            const token = this.storageBrowser.get('token');
            const headers = new HttpHeaders({
                'Authorization': `Bearer ${token}`,
            });

            const formData: FormData = new FormData();
            formData.append('invoice', file, file.name);

            let params = new HttpParams()
            params = params.append('type', type);
            params = params.append('id', id);

            this.http.post(
                `${environment.api_url}invoices/upload`,
                formData,
                {headers, params}
            ).subscribe(res => obs.next(res), error => obs.error(error));
        });
    }

    public getTemplateHistory(templateId: string) {
        return this.request({
            method: 'GET',
            path: `invoices/template/history/${templateId}`
        });
    }

    public setTemplateHistoryActive(templateId: string, historyId: string) {
        return this.request({
            method: 'PUT',
            path: `invoices/template/history/${templateId}`,
            body: { historyId }
        });
    }

    public getOrderInvoiceData(orderId: number, invoiceId: number): Observable<ISaleOrder> {
        return this.request({
            path: `invoices/order-invoice`,
            method: 'POST',
            body: { orderId, invoiceId }
        });
    }

    public getConShipmentInvoiceData(invoiceId: number): Observable<ISaleOrder> {
        return this.request({
            path: `invoices/con-shipment-invoice`,
            method: 'POST',
            body: { invoiceId }
        });
    }

    public saveShipment(body: any) {
        return this.request({
            path: `invoices/update-shipment`,
            method: 'POST',
            body
        });
    }

    public saveConShipment(body: any) {
        return this.request({
            path: `invoices/update-con-shipment`,
            method: 'POST',
            body
        });
    }

    public reIssueAllInvoice(itemIds: string[], tabType: number, invoiceType?: string) {
        return this.request({
            method: 'POST',
            path: `invoices/re-issue-all`,
            body: {
                ids: itemIds,
                type: tabType,
                invoiceType
            }
        })
    }
}
