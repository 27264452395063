import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { IProductCart, IProductCheckout } from '../models/product.interface';
import { EmpactShopService } from './empact-shop.service';
import { LocalStorageService } from './local-storage.service';
import { ApiService } from './api.service';
import { UserService } from './user.service';
import { AddProduct2Cart } from '../models/add-product-cart.model';
import { UpdateProductCart } from '../models/update-product-cart.model';

@Injectable({
    providedIn: 'root',
})
export class CartService extends ApiService {

    constructor(
        public http: HttpClient,
        private userSvc: UserService,
        private empactShopService: EmpactShopService,
        private storageService: LocalStorageService,
    ) {
        super(http, storageService);
    }

    private get cartQuery(): object {
        const userId = this.userSvc.userId;
        const cartToken = this.storageService.get(`cartToken-${this.empactShopService.shopId}`);
        return !userId ? {cartToken} : {};
    }

    public addProduct2Cart(body: AddProduct2Cart): Observable<any> {
        const userId = this.userSvc.userId;
        const cartToken = this.storageService.get(`cartToken-${this.empactShopService.shopId}`);
        return this.requestEcommerce({
            method: 'POST',
            path: `cart/add-to-cart`,
            body: {
                userId: !!userId ? userId : null,
                token: !!cartToken ? cartToken : null,
                ...body
            }
        });
    }

    public updateProductInCart(body: UpdateProductCart[]): Observable<any> {
        return this.requestEcommerce({
            method: 'POST',
            path: `cart/update-cart-items`,
            body: { products: body },
        });
    }

    // IF USER LOGGED IN - WE ARE USING THE USER ID TO GET DETAILS FOR USER'S CART
    // IF USER IS NOT LOGGED IN - WE ARE USING CART TOKEN TO IDENTIFY UNAUTHORIZED USER
    public getProducts4Cart(): Observable<IProductCart[]> {
        return this.requestEcommerce({
            method: 'GET',
            path: `cart`,
            query: this.cartQuery,
        });
    }

    public getCheckoutSummary(): Observable<IProductCheckout> {
        return this.requestEcommerce({
            method: 'GET',
            path: `cart/checkout`,
            query: this.cartQuery
        });
    }

    public deleteProducts4Cart(id: number): Observable<IProductCart> {
        return this.requestEcommerce({
            method: 'DELETE',
            path: `cart`,
            body: { cartId: id },
        });
    }

    public clearCart(): Observable<IProductCart> {
        return this.requestEcommerce({
            method: 'DELETE',
            path: `cart/clear-cart`,
            body: { cartToken: this.storageService.get(`cartToken-${this.empactShopService.shopId}`) },
        });
    }

    public checkIndividualCartToken(shopId: string): void {
        const cartToken = this.storageService.get(`cartToken-${shopId}`);
        const token = this.storageService.get('empactToken');
        if (!token && !cartToken) {
            this.storageService.set(`cartToken-${shopId}`, this.cartToken);
        }
    }

    private get cartToken(): string {
        let text = '';
        const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890=-&^%$#!~';
        const lengthOfCode = Math.random() * (40 - 20) + 20;

        for (let i = 0; i < lengthOfCode; i++) {
            text += possible.charAt(Math.floor(Math.random() * possible.length));
        }

        return text;
    }

}
