import {
    Component,
    Directive,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    Pipe,
    PipeTransform,
    Renderer2
} from '@angular/core';
import {AbstractControl, FormArray, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators} from '@angular/forms';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {CompanyService} from '../../../../../core/services/company.service';

import {
    ErrorMatcher
} from '../../../../registration/components/indvat-registration/components/user-main-details/user-main-details.component';

export function vatValidator(mainNumber: string, mainCountry: string, addNumbers: any[]): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        let duplicate = null;
        let duplicateCountry = null;
        const regNumber = control.get('code')?.value;
        const regCountry = control.get('countryCode')?.value;

        if (mainNumber === regNumber) {
            duplicate = true;
        }

        if (mainCountry === regCountry) {
            duplicateCountry = true;
        }

        addNumbers?.map( (n, i) => {
            if (n.code === regNumber) {
                duplicate = true;
            }
            if (n.countryCode === regCountry) {
                duplicateCountry = true;
            }
        })

        if (duplicate != null) {
            let errors = control?.get('code').errors;
            if (!errors) {
                const c = control?.get('code');
                c.setErrors({
                    duplicate: 'Duplicate numbers'
                })
                c.markAsTouched();
            }
        }
        if (duplicateCountry != null) {
            let errors = control?.get('countryCode').errors;
            if (!errors) {
                const c = control?.get('countryCode');
                c.setErrors({
                    duplicate: 'Duplicate country'
                })
                c.markAsTouched();
            }
        }
        return null;
    }
}

@Component({
    selector: 'eci-add-vat',
    templateUrl: './add-vat.component.html',
    styleUrls: ['./add-vat.component.scss']
})
export class AddVatComponent implements OnInit {

    @Input() countries: any[];
    @Input() country: any;
    @Input() company: any;
    @Output() onCompanyUpdate: EventEmitter<any> = new EventEmitter<any>();

    constructor(
        private dialog: MatDialog,
    ) {
    }

    ngOnInit(): void {

    }

    addSecondaryVAT() {
        const dialogRef = this.dialog.open(AddVATDialog);
        dialogRef.componentInstance.countries = this.countries;
        dialogRef.componentInstance.country = this.country;
        dialogRef.componentInstance.company = this.company;
        dialogRef.afterClosed().subscribe(value => {
            if (!value) {
                return;
            }
            this.onCompanyUpdate.emit(value);
        });
    }
}

@Component({
    template: `
        <div class="popup-container" [loader]="showLoader">
            <div class="header">
                <div class="popup-title label-name-text">
                    <span>VAT Numbers</span>
                </div>
                <div class="popup-close-button">
                    <mat-icon (click)="cancel()">close</mat-icon>
                </div>
            </div>

            <div class="row">
                <div class="input-container inline _180">Country</div>
                <div class="input-container inline _180">VAT Number</div>
                <div class="input-container inline _180">EORI Number</div>
            </div>
            <ng-container [formGroup]="form">
                <div class="content">
                    <div class="input-container inline mat-text-input _180 reg-numbers-container">
                        <mat-form-field class="mat-form-field__180" appearance="outline">
                            <mat-select formControlName="countryCode" [errorStateMatcher]="matcher">
                                <mat-select-trigger>
                                    <div class="flag-option">
                                        {{getCountryNameByCode(form.get('countryCode').value)}} <div class="iti__flag {{getFlagClass(form.get('countryCode').value)}}"></div>
                                    </div>
                                </mat-select-trigger>
                                <mat-option>
                                    <ngx-mat-select-search formControlName="searchCountry"
                                                           placeholderLabel="Search..."
                                                           noEntriesFoundLabel="No results"></ngx-mat-select-search>
                                </mat-option>
                                <mat-option *ngFor="let country of countriesFiltered | filter:form.get('searchCountry')?.value"
                                            [value]="country.code">
                                    <div class="flag-option">
                                        {{country.name}} <div class="iti__flag {{getFlagClass(country.code)}}"></div>
                                    </div>
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="form.get('countryCode').hasError('required')">
                                Please enter your country
                            </mat-error>
                            <mat-error *ngIf="form.get('countryCode').hasError('duplicate')">
                                Duplicate country
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="input-container inline mat-text-input _180 reg-numbers-container">
                        <mat-form-field class="mat-form-field__180" appearance="outline">
                            <input type="text" matInput formControlName="code" [errorStateMatcher]="matcher">
                            <mat-error *ngIf="form.get('code').hasError('required')">
                                Mandatory
                            </mat-error>
                            <mat-error *ngIf="form.get('code').hasError('pattern')">
                                Registration number should contain 8-15 chars
                            </mat-error>
                            <mat-error *ngIf="form.get('code').hasError('duplicate')">
                                Duplicate number
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="input-container inline mat-text-input _180 reg-numbers-container">
                        <mat-form-field class="mat-form-field__180" appearance="outline">
                            <input type="text" matInput formControlName="eoriCode" [errorStateMatcher]="matcher">
                            <mat-error *ngIf="form.get('eoriCode').hasError('pattern')">
                                EORI number should contain 8-15 chars
                            </mat-error>
                            <mat-error *ngIf="form.get('eoriCode').hasError('duplicate')">
                                Duplicate EORI number
                            </mat-error>
                        </mat-form-field>
                    </div>

                </div>
            </ng-container>
            <div class="footer">
                <button class="button-item {{ form.invalid ? 'disabled' : 'active' }}"
                        [disabled]="form.invalid"
                        (click)="save()">
                    <span class="text">Save</span>
                </button>
            </div>
        </div>
    `,
    styles: [
        `.flag-option{
            display: flex;
            justify-content: space-between;
            align-items: center;
        }`
    ]
})
export class AddVATDialog implements OnInit {

    public countries: any[];
    public country: any;
    public company: any;
    countriesFiltered: any[];
    form: FormGroup;
    matcher = new ErrorMatcher();
    showLoader = false;

    constructor(
        public fb: FormBuilder,
        private dialogRef: MatDialogRef<AddVATDialog>,
        private companyService: CompanyService
    ) {}

    ngOnInit(): void {
        this.countriesFiltered = this.countries;
        this.form = this.fb.group({
            countryCode: ['', Validators.required],
            code: ['', [Validators.required, Validators.pattern('[0-9a-zA-Z]{8,15}')]],
            eoriCode: ['', [Validators.pattern('[0-9a-zA-Z]{8,15}')]],
            searchCountry: [''],
        }, {validators: vatValidator(this.company.companyCode, this.company.address.countryCode, this.company.companyCodeAdd)});
        this.form.get('searchCountry').valueChanges.subscribe(value => {
            let searchTextCountries = value.toLowerCase();
            this.countriesFiltered = this.countries.filter(it => {
                return it.name.toLowerCase().substring(0, searchTextCountries.length) == searchTextCountries;
            });
        });
        this.findAndSetCountry(this.country);
    }

    getFlagClass(code: string) {
        return `iti__${code.toLowerCase()}`;
    }

    getCountryNameByCode(value: any) {
        return this.countries?.find(c => c.code === value)?.name;
    }

    cancel() {
        this.dialogRef.close();
    }

    save() {
        this.showLoader = true;
        const body = {
            countryCode: this.form.get('countryCode').value,
            code: this.form.get('code').value,
            eoriCode: this.form.get('eoriCode').value,
        }
        this.companyService.addVATNumber(this.company.id, body).subscribe(company => {
            this.showLoader = false;
            const newCode = company.companyCodeAdd.find(c => c.code === this.form.get('code').value);
            this.dialogRef.close({
                company,
                countryCode: this.form.get('countryCode').value,
                newCode
            });
        }, err => {
            this.showLoader = false;
        });
    }

    private findAndSetCountry(id) {
        if (id) {
            const country = this.countries.find(c => c.id === id);
            if (country) {
                this.form.get('countryCode').setValue(country.code);
            }
        }
    }
}

@Pipe({
    name: 'filter',
    pure: false
})
export class FilterPipe implements PipeTransform {

    transform(items: any[], searchText: string, isShopName: boolean = false): any {
        if (!items) return [];
        if (!searchText) return items;

        searchText = searchText?.toLowerCase();

        return items.filter(it => {
            if (isShopName) {
                return it.marketplace?.name?.toLowerCase()?.substring(0, searchText.length) === searchText ||
                    it.shopName?.toLowerCase()?.substring(0, searchText.length) === searchText;
            }
            if (it.name) {
                return it.name?.toLowerCase()?.substring(0, searchText.length) === searchText;
            } else if (it.viewValue) {
                return it.viewValue?.toLowerCase()?.substring(0, searchText.length) === searchText;
            } else if (it.originalName) {
                return it.originalName?.toLowerCase()?.substring(0, searchText.length) === searchText;
            } else if (it.city) {
                return it.city?.toLowerCase()?.substring(0, searchText.length) === searchText;
            } else {
                if (typeof it === 'number') {
                    return it?.toString()?.substring(0, searchText.length) === searchText;
                } else {
                    return it?.toLowerCase()?.substring(0, searchText.length) === searchText;
                }
            }
        });
    }
}

@Directive({
    selector: '[loader]',
})
export class LoaderDirective implements OnChanges {
    @Input('loader') showLoader: boolean = false;
    loader: any;

    constructor(
        private renderer: Renderer2,
        private el: ElementRef) {
        this.renderer.setStyle(el.nativeElement, 'position', 'relative');
        this.loader = this.renderer.createElement('div');
        this.renderer.addClass(this.loader, 'loader');
        this.renderer.appendChild(this.el.nativeElement, this.loader);
    }

    ngOnChanges(): void {
        if (this.showLoader) this.renderer.setStyle(this.loader, 'display', 'flex');
        else this.renderer.setStyle(this.loader, 'display', 'none');
    }

}

