import {DatePipe} from '@angular/common';
import {Component, ElementRef, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {FormControl} from '@angular/forms';
import * as _ from 'lodash';
import {Observable} from 'rxjs';
import {debounceTime} from 'rxjs/operators';
import {IAvailableTypes} from '../../../core/models/available-types.interface';
import {PageParams} from '../../../core/models/page-params.model';
import {ConstantService} from '../../../core/services/constant.service';
import { NotificationService } from '../../../core/services/notification.service';
import {SaleOrderInvoiceType} from '../../../shared/enums/sale-order-invoice-type.enum';
import {InvoiceTypePipe} from '../../../shared/pipes/invoice-type.pipe';
import {RegistrationService} from '../../customer-registration/services/registration.service';
import {EInvManagerTabType, InvoiceManagementService} from '../invoice-management.service';
import {InvManTableSaleOrderComponent} from './inv-man-table-sale-order/inv-man-table-sale-order.component';

@Component({
    template:''
})
export class InvManagerTab {

    @Input() companyList = [];
    @Output() isLoad = new EventEmitter<boolean>();

    @ViewChild(InvManTableSaleOrderComponent, {static: false}) public table: InvManTableSaleOrderComponent;
    @ViewChild('invoiceFileInput') invoiceFileInput: ElementRef;

    orderStatus: FormControl = new FormControl(null);
    searchOrderStatus: FormControl = new FormControl('');
    invoiceType: FormControl = new FormControl('');
    searchOrderStr: FormControl = new FormControl('');
    customerName: FormControl = new FormControl('');
    searchCustomerName: FormControl = new FormControl('');
    orderStatusesList = [];
    invoiceTypesList = [];
    fromData: FormControl = new FormControl('');
    toData: FormControl = new FormControl('');
    pageParams: PageParams = new PageParams();
    dataSource;
    sumValues;
    availableTypes: IAvailableTypes;
    displayedColumns: string[] = [
        'checkboxes',
        'orderType',
        'invoiceType',
        'date',
        'status',
        'customer',
        'numberInvoices',
        'actions',
    ];
    uploadedInvoice;

    constructor(
        public invoiceManagementService: InvoiceManagementService,
        public datePipe: DatePipe,
        public constSvc: ConstantService,
        public tabType: EInvManagerTabType,
        public regSvc: RegistrationService,
        public notify: NotificationService,
    ) {

    }

    onInit(): void {
        this.constSvc.availableTypes.subscribe(data => {
            const modName = (i) => {
                return {
                    id: i.id,
                    name: i.id == SaleOrderInvoiceType.IMPORT_INVOICE_AIR ?
                        'Service Invoice (IMP)' :
                        _.startCase(_.toLower(i.name.split('_').join(' ')))
                }
            }

            this.availableTypes = data;
            this.invoiceTypesList = this.availableTypes?.InvoiceEditorType.map(i => modName(i))
                .sort((a,b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
            switch (this.tabType) {
                case EInvManagerTabType.so:
                    this.orderStatusesList = this.availableTypes?.SaleOrderStatus.map(i => modName(i))
                        .sort((a,b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
                    break;
                case EInvManagerTabType.shipment:
                    this.orderStatusesList = this.availableTypes?.OrderStatus.map(i => modName(i))
                        .sort((a,b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
                    break;
                case EInvManagerTabType.consolidate_shipment:
                    this.orderStatusesList = this.availableTypes?.ShipmentConsolStatus.map(i => modName(i))
                        .sort((a,b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
                    break;
            }
        });

        this.searchOrderStatus.valueChanges.subscribe(value => {
            this.clearPage();
            this.orderStatusesList = this.availableTypes?.SaleOrderStatus.filter(os => os.name.toLowerCase().includes(value.toLowerCase()));
        });

        this.searchCustomerName.valueChanges.pipe(debounceTime(500)).subscribe(value => {
            this.clearPage();
            this.getListCompanies();
        });

        this.customerName.valueChanges.subscribe(() => this.clearPage());
        this.orderStatus.valueChanges.subscribe(() => this.clearPage());
        this.invoiceType.valueChanges.subscribe(() => this.clearPage());

    }

    clearPage() {
        this.pageParams.pageIndex = 0;
    }

    clearSearchStatus(searchInput:  FormControl) {
        searchInput.reset();
    }

    search(params?) {
        this.isLoad.emit(true);
        this.invoiceManagementService.getInvoices(
            this.pageParams.pageIndex + 1,
            this.pageParams.pageSize,
            params ? params : this.prepareSearchParams()
        ).subscribe(res => {
            this.isLoad.emit(false);
            this.dataSource = res.items;
            this.pageParams.pageSize = res.meta.itemsPerPage;
            this.pageParams.pageIndex = res.meta.currentPage - 1;
            this.pageParams.length = res.meta.totalItems;
            this.sumValues = res.meta.sum;
        });
    }

    prepareSearchParams() {
        let params: any = {};
        if (this.toData.value) {
            params.dateTo = this.datePipe.transform(this.toData.value, 'yyyy-MM-dd');
        }
        if (this.fromData.value) {
            params.dateFrom = this.datePipe.transform(this.fromData.value, 'yyyy-MM-dd');
        }
        if (this.orderStatus.value) {
            params.orderStatus = this.orderStatus.value;
        }
        if (this.invoiceType.value) {
            params.invoiceType = this.invoiceType.value;
        }
        if (this.searchOrderStr.value) {
            params.orderNumber = this.searchOrderStr.value;
        }
        if (this.customerName.value) {
            params.customerName = this.customerName.value
        }
        params.type = this.tabType;
        return params;
    }

    invoiceUpload(fileInput: any): Observable<any> {
        return new Observable<any>(obs => {
            if (fileInput.target.files && fileInput.target.files[0]) {

                const max_size = 20971520;
                if (fileInput.target.files[0].size > max_size) {
                    obs.error('Maximum size allowed is ' + max_size / 1000 + 'Mb');
                    return;
                }
                const file: File = fileInput.target.files[0];
                this.invoiceManagementService.invoiceUpload(file, this.uploadedInvoice.item.id, this.tabType).subscribe(res => {
                    obs.next(res);
                    this.notify.showSuccessMessage('Upload successfully');
                });
            }
        })
    }

    onPageChange(e: PageParams, params?: any) {
        this.pageParams.pageIndex = e.pageIndex;
        this.pageParams.pageSize = e.pageSize;
        this.search(params);
    }

    getListCompanies(q: string = this.searchCustomerName.value): void {
        q = this.customerName.value ? this.searchCustomerName.value : q;
        this.regSvc.getCompanyListForSales(q).subscribe(data => {
            if (!data) {
                return;
            }
            this.companyList = data;
        });
    }

    clearSelection() {
        this.customerName.reset();
        this.getListCompanies(this.searchCustomerName.value);
    }
}
