import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { ConsolidateShipmentRoutingModule } from './consolidate-shipments-routing.module'
import { MaterialModule } from '../material.module';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatTabsModule } from '@angular/material/tabs';
import { ConsolidateShipmentsManagementComponent } from './components/consolidate-shipments-management.component';
import { MatButtonModule } from '@angular/material/button';
import { CoreModule } from 'src/app/core/core.module';
import { CustomerManagementService } from '../customer-management/services/customer-management.service';
import { CarriersService } from '../carriers-list/services/carriers.service';
import { PalletService } from './services/pallet.service';
import { SharedModule } from 'src/app/shared/shared.module';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { CdkScrollableModule, ScrollingModule } from '@angular/cdk/scrolling';
import { PortalModule } from '@angular/cdk/portal';
import { PortsService } from './services/ports.service';
import { MatSelectInfiniteScrollModule } from 'ng-mat-select-infinite-scroll';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { ProductsService } from '../products/services/products.service';
import { GlobalSettingsService } from '../setting/services/global-settings.service';
import { ConsolidateShipmentsDetailsComponent } from './components/consolidate-shipments-details/consolidate-shipments-details.component';
import { MainConsolidateShipmentsDetailsComponent } from './components/consolidate-shipments-details/main-consolidate-shipments-details/main-consolidate-shipments-details.component';
import { ConsolidateShipmentsDocumentsListComponent } from './components/consolidate-shipments-details/consolidate-shipments-documents-list/consolidate-shipments-documents-list.component';
import { ConsolidateShipmentsShipmentComponent } from './components/consolidate-shipments-details/consolidate-shipments-shipment-details/consolidate-shipments-shipment-details.component'
import { ConsolidateShipmentsPackageDetailsComponent } from './components/consolidate-shipments-details/consolidate-shipments-package-details/consolidate-shipments-package-details.component';
import { PalletsTableComponent } from './components/consolidate-shipments-details/consolidate-shipments-package-details/pallets-table/pallets-table.component';
import { ContainerTableComponent } from './components/consolidate-shipments-details/consolidate-shipments-package-details/container-table/container-table.component';
import { ProductsListComponent } from './components/consolidate-shipments-details/products-list/products-list.component';
import { PickupInfoListComponent } from './components/consolidate-shipments-details/pickup-info-list/pickup-info-list.component';
import { TableConsolidateShipmentsComponent } from './components/table-consolidate-shipments-management/table-consolidate-shipments.component';
import { ConsolidateShipmentPackagesComponent } from './components/consolidate-shipments-details/consolidate-shipment-packages/consolidate-shipment-packages.component';
import { ConsolShipmentDetailsResolver } from './services/resolvers/consol-shipment-details-resolver.service';
import { SaleOrderService } from '../sales-order-management/services/sale-order.service';
import { ConsolidateShipmentsInvoiceListComponent } from './components/consolidate-shipments-details/consolidate-shipments-invoice-list/consolidate-shipments-invoice-list.component';
import { PaymentService } from '../customers-order-management/services/payment.service';
import { OrderInvoicesService } from './services/consolidate-shipments-invoices.service';
import { PalletDetailsTableComponent } from './components/consolidate-shipments-details/consolidate-shipments-package-details/pallet-details-table/pallet-details-table.component';
import { CityService } from 'src/app/core/services/city.service';
import { SOTypeConsolSelectPopupPopupComponent } from './components/so-type-consol-select-popup/so-type-consol-select-popup.component';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    ConsolidateShipmentRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    MatFormFieldModule,
    MatSelectModule,
    MaterialModule,
    MatTabsModule,
    MatInputModule,
    MatButtonModule,
    CoreModule,
    SharedModule,
    MatDialogModule,
    CdkScrollableModule,
    ScrollingModule,
    PortalModule,
    MatSelectInfiniteScrollModule
  ],
  declarations: [
    ConsolidateShipmentsManagementComponent,
    ConsolidateShipmentsDetailsComponent,
    MainConsolidateShipmentsDetailsComponent,
    PickupInfoListComponent,
    ProductsListComponent,
    ConsolidateShipmentsDocumentsListComponent,
    ConsolidateShipmentsShipmentComponent,
    ConsolidateShipmentsPackageDetailsComponent,
    PalletsTableComponent,
    ContainerTableComponent,
    TableConsolidateShipmentsComponent,
    ConsolidateShipmentPackagesComponent,
    ConsolidateShipmentsInvoiceListComponent,
    PalletDetailsTableComponent,
    SOTypeConsolSelectPopupPopupComponent
  ],
  providers: [
    CarriersService,
    PalletService,
    NgxMatSelectSearchModule,
    ProductsService,
    GlobalSettingsService,
    PortsService,
    CityService,
    ConsolShipmentDetailsResolver,
    SaleOrderService,
    PaymentService,
    OrderInvoicesService,
    {
      provide: MatDialogRef,
      useValue: {}
    },
  ]
})
export class ConsolidateShipmentModule { }
