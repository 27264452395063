import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'eci-slider-section',
    templateUrl: './slider-section.component.html',
    styleUrls: ['./slider-section.component.scss']
})
export class SliderSectionComponent implements OnInit {
    @Input() title: string;
    @Input() linkName: string;

    constructor() {
    }

    ngOnInit(): void {
    }

}
