import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable()
export class SaleOrderTemplateService extends ApiService {

    public resellerCouponsFilters$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    public currentResellerCoupon: number;
    private baseUrl = 'sale-order-templates';

    getAllTemplates(marketplaceId?: number): Observable<any> {
        return this.request({
            path: `${this.baseUrl}`,
            method: 'GET',
            query: {
                marketplaceId: !!marketplaceId ? marketplaceId : ''
            }
        })
    }

    getOne(id?: number): Observable<any> {
        return this.request({
            path: `${this.baseUrl}/${id}`,
            method: 'GET',
        })
    }

    saveTemplate(body: any): Observable<any> {
        return this.request({
            path: `${this.baseUrl}`,
            method: 'POST',
            body: body
        })
    }

    updateTemplate(id, body): Observable<any> {
        return this.request({
            path: `${this.baseUrl}/${id}`,
            method: 'PUT',
            body: body
        });
    }

    delete(id: number): Observable<any> {
        return this.request({
            path: `${this.baseUrl}/${id}`,
            method: 'DELETE',
        });
    }
}
