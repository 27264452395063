import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'eci-subscriber',
  templateUrl: './subscriber.component.html',
  styleUrls: ['./subscriber.component.scss']
})
export class SubscriberComponent implements OnInit {

  constructor() {
  }

  ngOnInit(): void {
  }

}
