import { Component, EventEmitter, Input, OnInit, Output, ChangeDetectorRef} from '@angular/core';
import { LocalStorageService } from 'src/app/core/services/local-storage.service';
import { IStep } from './models/step.interface';

@Component({
  selector: 'eci-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss']
})
export class ProgressBarComponent implements OnInit {

  @Input() steps: IStep[];
  @Input() type = 'online';
  @Input() activeStep: number = 0;
  @Input() registrationStep: number = 0;
  @Input() allowClick: boolean = true;
  @Input() disableSave: boolean = true;
  @Input() disableNext: boolean = true;

  @Output() activeStepChange = new EventEmitter<number>();
  @Output() save = new EventEmitter<any>();
  @Output() next = new EventEmitter<any>();
  @Output() back = new EventEmitter<any>();
  progressBar: boolean = true;
  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private localStorageSvc: LocalStorageService,
  ) { }

  ngOnInit(): void {

  }

  isBackBtnDisabled(stepNumber: number): boolean {
    // !!this.localStorageSvc.get('main_auth_token') ? true : false;
    if (stepNumber === 0 && !!this.localStorageSvc.get('main_auth_token')) {
        return true;
    }else{
        return false;
    }
     // stepNumber === 0;
  }

  isNextBtnDisabled(stepNumber: number): boolean {
    return stepNumber === (this.steps.length - 1) || this.disableNext;
  }

  goNext(activeStep): void {
    if (activeStep !== (this.steps.length - 1)) {
      this.setStep(activeStep + 1);
      // this.next.emit(activeStep + 1);
    }

    if (activeStep === (this.steps.length - 1)) {
      this.save.emit();
    }
  }

  goBack(activeStep): void {
    // if (activeStep !== 0) {
    // this.setStep(activeStep - 1);
    // this.back.emit(activeStep - 1);
    // }
    if (activeStep === 0 && !!this.localStorageSvc.get('main_auth_token')) {
        return;
    }else{
        this.setStep(activeStep - 1);
        this.back.emit(activeStep - 1);
    }
  }

  setStep(stepNumber: number): void {
    if (!this.allowClick || (stepNumber > this.activeStep && this.isNextBtnDisabled(this.activeStep))) {
      return undefined;
    }

    if (stepNumber > this.activeStep) {
      this.next.emit(stepNumber);
    }

    this.activeStep = stepNumber;
    this.changeDetectorRef.detectChanges();
    this.activeStepChange.emit(this.activeStep);
  }

  showNext(stepNumber: number): boolean {
    return stepNumber !== (this.steps.length - 1);
  }

  // TO DO - WHAT IS THE DIFFERENCE BETWEEN NEXT AND SAVE
  showSave(stepNumber: number): boolean {
    return false;
    if (stepNumber === 6) {
      return false
    } else {
      return stepNumber === (this.steps.length - 1);
    }
  }
}
