import {Injectable} from '@angular/core';
import {Observable, Subject, BehaviorSubject} from 'rxjs';
import { ApiService } from './api.service';
import { saveAs } from "file-saver";
import { environment } from './../../../environments/environment';

@Injectable()
export class UploadAgreementService extends ApiService {
    public confidentialityObserver = new Subject<any>();
    public filesLoadedObserver: BehaviorSubject<{ fileId: string, data}[]> = new BehaviorSubject<{ fileId: string, data}[]>(null);


    public fileFormats = [
        'image/jpeg',
        'image/png'
    ];

    public textFileFormats = [
        'application/pdf',
    ];

    public excelFileFormats = [
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel',
    ];

    public fileLoaded(): Observable<any> {
        return this.confidentialityObserver.asObservable();
    }

    public allFilesLoaded(): Observable<any> {
        return this.filesLoadedObserver.asObservable();
    }

    public uploadFile(files: any, url, id?, fieldName = 'content') {
        if (files.length > 0) {
            const file: File = files[0];
            const formData: FormData = new FormData();
            formData.append(fieldName, file, file.name);
            const token = this.storageBrowser.get('token');
            const xhr = new XMLHttpRequest();
            xhr.open('POST', `${environment.api_url}${url}${id ? '/' + id : ''}`, true);
            xhr.setRequestHeader('Authorization', `Bearer ${token}`);
            xhr.onload = () => {
                if (xhr.readyState === 4) {
                    if (xhr.status === 200) {
                        // Use an observer to notify competion
                        console.log(xhr);
                        this.confidentialityObserver.next(xhr.responseText);

                    } else {
                        this.confidentialityObserver.error(xhr.statusText);
                        console.error(xhr.statusText);
                    }
                }
            };
            xhr.onerror = () => {
                console.error(xhr.statusText);
            };
            xhr.send(formData);
        }
    }

    public uploadFileFormData(formData: FormData, url) {
            const token = this.storageBrowser.get('token');
            const xhr = new XMLHttpRequest();
            xhr.open('POST', `${environment.api_url}${url}`, true);
            xhr.setRequestHeader('Authorization', `Bearer ${token}`);
            xhr.onload = () => {
                if (xhr.readyState === 4) {
                    if (xhr.status === 200) {
                        // Use an observer to notify competion
                        console.log(xhr);
                        this.confidentialityObserver.next(xhr.responseText);

                    } else {
                        this.confidentialityObserver.error(xhr.statusText);
                        console.error(xhr.statusText);
                    }
                }
            };
            xhr.onerror = () => {
                console.error(xhr.statusText);
            };
            xhr.send(formData);
    }

    public uploadFilePdf(files: any, url, fieldName = 'content') {
            const file: File = files;
            const formData: FormData = new FormData();
            formData.append(fieldName, file, file.name);
            const token = this.storageBrowser.get('token');
            const xhr = new XMLHttpRequest();
            xhr.open('POST', `${environment.api_url}${url}`, true);
            xhr.setRequestHeader('Authorization', `Bearer ${token}`);
            xhr.onload = () => {
                if (xhr.readyState === 4) {
                    if (xhr.status === 200) {
                        // Use an observer to notify competion
                        this.confidentialityObserver.next(xhr.responseText);

                    } else {
                        this.confidentialityObserver.error(xhr.statusText);
                        console.error(xhr.statusText);
                    }
                }
            };
            xhr.onerror = () => {
                console.error(xhr.statusText);
            };
            xhr.send(formData);

    }

    public uploadSeveralFile(files: any, url, fileId?, fieldName = 'content') {
        if (files.length > 0) {
            const file: File = files[0];
            const formData: FormData = new FormData();
            formData.append(fieldName, file, file.name);
            const token = this.storageBrowser.get('token');
            const xhr = new XMLHttpRequest();
            const state = this.filesLoadedObserver.getValue() ? this.filesLoadedObserver.getValue() : [];
            xhr.open('POST', `${environment.api_url}${url}`, true);
            xhr.setRequestHeader('Authorization', `Bearer ${token}`);
            xhr.onload = () => {
                const file = state ? state.find(el => el.fileId == fileId) : null;

                if (xhr.readyState === 4) {
                    if (xhr.status === 200) {
                        // Use an observer to notify competion

                        if (!file)
                            state.push({ fileId: fileId, data: xhr.responseText });
                        else file.data = xhr.responseText;
                        this.filesLoadedObserver.next(state);
                    } else {
                        if (!file)
                            state.push({ fileId: fileId, data: xhr.statusText });
                        else file.data = xhr.statusText;
                        this.filesLoadedObserver.next(state);
                        console.error(xhr.statusText);
                    }
                }
            };
            xhr.onerror = () => {
                console.error(xhr.statusText);
            };
            xhr.send(formData);
        }
    }

    public saveToFileSystem(response) {
        const blob = new Blob([response],
            { type: 'application/vnd.ms-excel' });
        const file = new File([blob], 'report.xlsx',
            { type: 'application/vnd.ms-excel' });

        saveAs(blob, 'product_example.xlsx');
    }

    resetLoadedFiles(): void {
        this.confidentialityObserver.next(null);
    }
}
