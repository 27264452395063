import { Component, OnInit } from '@angular/core';
import {template} from 'lodash';
import {InvoiceTemplateBase} from '../invoce-template-base';

@Component({
  selector: 'eci-vat-invoice-form',
  templateUrl: './vat-invoice-form.component.html',
  styleUrls: ['./vat-invoice-form.component.scss']
})
export class VatInvoiceFormComponent extends InvoiceTemplateBase implements OnInit {

  ngOnInit(): void {
      this.model = {
          ...this.template,
          ...this.invoice,
          customerCompany: {
              ...this.template.customerCompany,
              ...this.invoice.customerCompany
          }
      };
  }

}
