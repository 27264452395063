import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SliderSectionComponent } from './slider-section/slider-section.component';
import { SubscriberComponent } from './subscriber/subscriber.component';
import { BannerComponent } from './banner/banner.component';
import { SearchComponent } from './search/search.component';
import { CategoriesComponent } from './categories/categories.component';
import { ProductCardComponent } from './product-card/product-card.component';
import { RatingBarComponent } from './rating-bar/rating-bar.component';
import { RoundCheckboxComponent } from './round-checkbox/round-checkbox.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { ImageSliderComponent } from './image-slider/image-slider.component';
import { CounterComponent } from './counter/counter.component';
import { StepperComponent } from './stepper/stepper.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FilterComponent } from './filter/filter.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  declarations: [
    SubscriberComponent,
    BannerComponent,
    SearchComponent,
    CategoriesComponent,
    ProductCardComponent,
    RatingBarComponent,
    RoundCheckboxComponent,
    PageNotFoundComponent,
    ImageSliderComponent,
    CounterComponent,
    StepperComponent,
    FilterComponent,
    SliderSectionComponent,
  ],
  exports: [
    SubscriberComponent,
    BannerComponent,
    SearchComponent,
    CategoriesComponent,
    ProductCardComponent,
    RatingBarComponent,
    RoundCheckboxComponent,
    ImageSliderComponent,
    CounterComponent,
    StepperComponent,
    FilterComponent,
    SliderSectionComponent,
  ],
})
export class ComponentsModule { }
