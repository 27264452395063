const appVersion = require('../../package.json').version
const environmentName = require('../../package.json')._envName
export const environment = {
  production: false,
  api_url: 'https://staging.platform.empact.online/api/',
  img_url: 'https://empact-dev-uploads.s3.amazonaws.com/',
  api_ecommerce_url: 'https://api.dev.ecommerce.alpha.platform.empact.online/api/',
  pk_stripe: 'pk_test_51HMDlIKQqSuACCb1TiAKdtLG7eqPk83Fgc020P67gRWiSZSublUgbzhXjbEx5BbOSRC46ouWQ3Kqb9fAesFlxPKx009mOR1xuq',
  one_time_registration_fees: 150,
  monthly_subscription_fees: 299,
  one_time_registration_fees_indvat: 150,
  monthly_subscription_fees_indvat: 199,
  b2b_handling_fees: 10,
  b2c_handling_fees: 2.5,
  appVersion: '0.8.33',
  is_live_mode: false,
  integration_layer_url: 'https://dev.integrationlayer.platform.empact.zangula.net/',
  isFedex: false,
  // appVersion: '0.8.33',
};
