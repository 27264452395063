export enum CustomerType {
    'Sole_proprietorship' = 0,
    Partnership,
    LLP,
    LLC,
    'Series_LLC',
    'C_corporation',
    'S_corporation',
    'Nonprofit_corporation',
    'Benefit_corporation',
    L3C,
    'Reporting Tool',
}
