import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ConsolidateShipmentsManagementComponent } from './components/consolidate-shipments-management.component'
import { ConsolidateShipmentsDetailsComponent } from './components/consolidate-shipments-details/consolidate-shipments-details.component';
import { ConsolShipmentDetailsResolver } from './services/resolvers/consol-shipment-details-resolver.service';

const routes: Routes = [
    { 
        path: '', 
        component: ConsolidateShipmentsManagementComponent
    },
    { 
        path: 'consol-shipment-details/:id', 
        component: ConsolidateShipmentsDetailsComponent,
        // resolve: {
        //     data: ConsolShipmentDetailsResolver,
        // }
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class ConsolidateShipmentRoutingModule {
}
