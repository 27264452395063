import { Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Subscription } from 'rxjs';

@Component({
    selector: 'eci-counter',
    templateUrl: './counter.component.html',
    styleUrls: ['./counter.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => CounterComponent),
            multi: true
        }
    ]
})
export class CounterComponent implements OnInit {

    formControl = new FormControl(0);

    sub: Subscription;

    @Input() theme: 'gray' | 'simple' = 'simple';
    @Input() format: string = '1.0-0';
    @Input() isDisabled: boolean = false;
    @Input() max: number = 100;
    @Input() min: number = 1;
    @Input() increment: number = 1;
    @Output() valueChange = new EventEmitter<number>();

    @Input() set value(val: number) {
        this.formControl.setValue(val);
        this.valueChange.emit(this.value);
    }

    get value(): number {
        return this.formControl.value;
    }

    constructor() {
    }

    ngOnInit(): void {
    }

    inc(val: number = this.increment): void {
        if (!this.isDisabled && this.value < this.max) {
            this.value += val;
        }
    }

    dec(val: number = this.increment): void {
        if (!this.isDisabled && this.value > this.min) {
            this.value -= val;
        }
    }

    writeValue(value: any): void {
        if (!value) {
            return;
        }
        this.value = value;
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    onChange: any = () => { };
    onTouched: any = () => { };
}
