import { Pipe, PipeTransform } from '@angular/core';
import { VbanStatus } from '../enums/vbans-status.enum';

@Pipe({ name: 'payinStatusPipe' })
export class PayinStatusPipe implements PipeTransform {
    transform(value: any) {
        switch (value) {
            case VbanStatus[VbanStatus.Free]: return 'Free';
            case VbanStatus[VbanStatus['In-Active']]: return 'In-Active';
            case VbanStatus[VbanStatus.Allocated]: return 'Allocated';
        }
    }
}