import { Pipe, PipeTransform } from "@angular/core";
import { FileStatus } from './../enums/file-status.enum';

@Pipe({ name: 'docStatus' })
export class DocStatusPipe implements PipeTransform {
    transform(value: any) {
        switch (value) {
            case FileStatus.PENDING: return 'Pending';
            case FileStatus.UPLOADED: return 'Uploaded';
            default: return ''
        }
    }
}