import { Component, EventEmitter, Input, OnInit, Output, SimpleChange } from '@angular/core';
import { IAvailableTypes } from 'src/app/core/models/available-types.interface';
import { PageParams } from 'src/app/core/models/page-params.model';
import { IOrderDetails } from '../../../models/order-details.model';
import { UploadFileService } from 'src/app/core/services/upload-file.service';
import { FileStatus } from 'src/app/shared/enums/file-status.enum';
import { MatTableDataSource } from '@angular/material/table';
import { ShipmentConsolDocumentType } from 'src/app/shared/enums/shipment-consol-document-type.enum';
import { SaleOrderStatus } from 'src/app/shared/enums/sale-order-status.enum';
import { UserService } from 'src/app/core/services/user.service';
import { ConsolidateShipmentService } from '../../../../../core/services/consolidate-shipments.service';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { NotificationService } from 'src/app/core/services/notification.service';

@Component({
  selector: 'eci-consolidate-shipments-documents-list',
  templateUrl: './consolidate-shipments-documents-list.component.html',
  styleUrls: ['./../consolidate-shipments-details.component.scss']
})
export class ConsolidateShipmentsDocumentsListComponent implements OnInit {

  pageParams: PageParams = new PageParams();
  isFedex: boolean = false;

  displayedColumns: string[] = [
    'dateUploaded',
    'documentType', // TODO MAYBE NEED TO CHANGE TO ENUM
    'documnetStatus',
    'uploads',
    'linkToViewUpload',
    'delete'
  ];
  @Input() summaryPage = false;
  @Input() info: any;
  @Input() isCreateMode: boolean = true;
  @Input() availableTypes: IAvailableTypes;
  @Input() availableFields: string[];

  @Output() reload = new EventEmitter<boolean>();
  @Output() infoChange = new EventEmitter<IOrderDetails>();

  documentList: any[]
  dataSource;

  listOfSelectedItems: any[] = [];

  pageChanged(e: PageParams): void {
    this.pageParams.pageIndex = e.pageIndex;
    this.pageParams.pageSize = e.pageSize;
    this.paginate(this.documentList, e.pageIndex + 1, e.pageSize);
    // this.getList(e.pageIndex + 1, e.pageSize);
  }


  paginate(array, page_number, page_size) {
    const newArr = array.slice((page_number - 1) * page_size, page_number * page_size);
    this.dataSource = newArr;
    this.pageParams.pageSize = page_size;
    this.pageParams.pageIndex = page_number - 1;
    this.pageParams.length = this.documentList.length;
    return newArr
  }

  constructor(
    public uploadFileSvc: UploadFileService,
    private userSvc: UserService,
    private consolidateShipmentSvc: ConsolidateShipmentService,
    private notifySvc: NotificationService
  ) { }

  ngOnInit(): void {
    this.prepareDocumentList();
    if (this.info && this.info.id && this.info.documents) {
      if (this.info?.company?.isFedex) {
        this.isFedex = true;
        this.displayedColumns.splice(0, 0, 'checkboxes');
      }

      this.info.documents = this.info?.documents?.map(el => {
        return {
          id: el.id,
          type: el.type,
          status: !!el.id ? FileStatus.UPLOADED : FileStatus.PENDING,
          createdAt: el.createdAt,
        }
      });

      const additionalDocList = this.documentList?.filter(el => {
        return !this.info.documents.map(val => val.type).includes(el.type);
      });

      this.documentList = [...this.info.documents, ...additionalDocList];
    }

    this.dataSource = new MatTableDataSource();
    this.dataSource.data = [...this.documentList];
    this.paginate(this.documentList, 1, 100)
  }

  get isAdmin(): boolean {
    return this.userSvc.isAdmin;
  }

  ngOnChanges(change: SimpleChange): void {
  }

  documentSave(id): void {
    this.consolidateShipmentSvc.downloadFile(this.info.id, id);
  }

  uploadFile(id: number, e: any, file: any): void {
    const files = e.dataTransfer ? e.dataTransfer?.files : e.target?.files;

    this.consolidateShipmentSvc.uploadFile(files, this.info.id, id);
  }

  deleteDoc(id): void {
    this.consolidateShipmentSvc.deleteDocument(id).subscribe(value => {
      if (value?.affected > 0) {
        const idx = this.documentList.findIndex(el => el.id === id);

        if (idx !== -1) {
          this.documentList[idx].id = null;
          this.documentList[idx].status = FileStatus.PENDING;
          this.documentList[idx].createdAt = null;
        }
      }
    });
  }

  public fileUpload(e: any, fileType) {
    if (!e.target.files) {
      return;
    }
    const files = e.dataTransfer ? e.dataTransfer.files : e.target.files;
    this.uploadFileSvc.uploadSeveralFile(files, 'uploads/consol-shipments', fileType)
    this.uploadFileSvc.allFilesLoaded().subscribe(data => {
      if (!data) return;
      const file = data.find(el => el.fileId === fileType)
      if (!file) return;

      const filDetails = JSON.parse(file.data);
      const document = this.documentList.find(el => el.type === fileType);

      if (document && filDetails) {
        document.id = filDetails.id;
        document.status = FileStatus.UPLOADED;
        document.createdAt = filDetails.createdAt;
      }
      this.info = this.model;
      this.infoChange.emit(this.info);
      // this.updateDocs(id, this.documentList.find(el => el.id == id))
      this.dataSource = [...this.documentList];

      if (this.info && this.info.id) {
        this.updateDocs();
      }
    });
  }

  get model(): any {
    return {
      ...this.info,
      documents: this.documentList
    }
  }

  updateDocs(): void {
    if (!this.info || !this.info?.id) {
      return;
    }

    this.consolidateShipmentSvc.updateShipment(this.info.id, this.info).subscribe(value => {
      this.reload.emit(true);
    });
  }

  uploadShow(element): boolean {
    if (element.type !== ShipmentConsolDocumentType[ShipmentConsolDocumentType.ConsolManifest]) {
      return element.status === FileStatus.PENDING; 
    } else {
      return this.isAdmin && element.status === FileStatus.PENDING;
    }
  }

  deleteShow(element): boolean {
    if (element.type !== ShipmentConsolDocumentType[ShipmentConsolDocumentType.ConsolManifest]) {
      return true; 
    } else {
      return this.isAdmin;
    }
  }

  downloadShow(status: number): boolean {
    return status === FileStatus.UPLOADED;
  }

  get hideCol(): boolean {
    return this.userSvc.isDelicated;
  }

  getDeliveredItems(consolShipment): number {
    const statuses = consolShipment.saleOrders.map(el => el.status);

    const delivered = statuses.filter(el => el === SaleOrderStatus.DELIVERED);
    return delivered?.length;
  }

  isRequired(element): boolean {
    return true;
    // if (element.type === OrderDocumentType.SignedPod) return true;
    // if (this.shipmentType === ShipmentType.Air) {
    //   // if (element.type === OrderDocumentType.SignedPod) return true;
    //   if (element.type === OrderDocumentType.AWB && !this.dataSource.find(el => el.type === OrderDocumentType.AWB && el.status === OrderDocumentStatus.Uploaded)) return true;
    //   if (element.type === OrderDocumentType.MAWB && (!this.dataSource.find(el => el.type === OrderDocumentType.AWB && el.status === OrderDocumentStatus.Uploaded) && (!this.dataSource.find(el => el.type === OrderDocumentType.HAWB && el.status === OrderDocumentStatus.Uploaded)))) return true;
    //   if (element.type === OrderDocumentType.HAWB && (this.dataSource.find(el => el.type === OrderDocumentType.MAWB && el.status === OrderDocumentStatus.Pending))) return true;
    // }

    // // if (this.shipmentType === ShipmentType.Ocean) {
    // //   if (element.type === OrderDocumentType.SignedPod) return true;
    // // }

    // return element.isRequired;
  }

  get isAllSelected(): boolean {
    return this.listOfSelectedItems?.length > 0 &&
      this.listOfSelectedItems?.length === this.dataSource?.filteredData?.length;
  }

  selectAllChanged(event: MatCheckboxChange): void {
    if (event.checked) {
      this.listOfSelectedItems = this.dataSource?.filteredData?.filter(val => !!val.id).map(el => el.id);
    } else {
      this.listOfSelectedItems = [];
    }
  }

  selectionChanged(event: MatCheckboxChange, id): void {
    if (!id) return;
    if (event.checked) {
      if (!this.isChecked(id)) {
        this.listOfSelectedItems.push(id);
      }
    } else {
      if (this.isChecked(id)) {
        this.listOfSelectedItems.splice(this.listOfSelectedItems.findIndex(el => el === id), 1);
      }
    }
  }

  isChecked(id: number): boolean {
    return !!this.listOfSelectedItems.find(el => el === id);
  }

  sendMails(): void {
    this.consolidateShipmentSvc.sendDocuments({
      ids: this.listOfSelectedItems,
    }).subscribe(res => {
      this.notifySvc.showSuccessMessage('Mail sent to Fedex');
      this.listOfSelectedItems = [];
    });
  }

  prepareDocumentList(): void {
    this.documentList = [{
      id: undefined,
      type: ShipmentConsolDocumentType[ShipmentConsolDocumentType.MasterAWB],
      isRequired: true,
      status: FileStatus.PENDING,
      createdAt: '',
    }, {
      id: undefined,
      type: ShipmentConsolDocumentType[ShipmentConsolDocumentType.CustomDeclaration],
      isRequired: true,
      status: FileStatus.PENDING,
      createdAt: '',
    }, {
      id: undefined,
      type: ShipmentConsolDocumentType[ShipmentConsolDocumentType.CMR],
      isRequired: true,
      status: FileStatus.PENDING,
      createdAt: '',
    }, {
      id: undefined,
      type: ShipmentConsolDocumentType[ShipmentConsolDocumentType.PackingList],
      isRequired: true,
      status: FileStatus.PENDING,
      createdAt: '',
    }, {
      id: undefined,
      type: ShipmentConsolDocumentType[ShipmentConsolDocumentType.SignedPod],
      isRequired: true,
      status: FileStatus.PENDING,
      createdAt: '',
    }, {
      id: undefined,
      type: ShipmentConsolDocumentType[ShipmentConsolDocumentType.ConsolManifest],
      isRequired: true,
      status: FileStatus.PENDING,
      createdAt: '',
    }];
  }
}
