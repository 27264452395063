import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatSelectModule} from '@angular/material/select';
import {NgxMatSelectSearchModule} from 'ngx-mat-select-search';
import {AddVatComponent, AddVATDialog, FilterPipe, LoaderDirective} from './add-vat.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        MatSelectModule,
        NgxMatSelectSearchModule,
        MatIconModule,

    ],
    declarations: [
        AddVatComponent,
        AddVATDialog,
        FilterPipe,
        LoaderDirective
    ],
    exports: [
        AddVatComponent,
        LoaderDirective
    ],
})
export class AddVatModule {}
