import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { ConsolidateShipmentService } from '../../../../core/services/consolidate-shipments.service';

@Injectable({ providedIn: 'root' })
export class ConsolShipmentDetailsResolver implements Resolve<any> {
    constructor(
        private consolShipmentSvc: ConsolidateShipmentService 
    ) { }

    resolve(route: ActivatedRouteSnapshot): Observable<any> {
        const { id } = route.params;

        return this.consolShipmentSvc.getShipmentById(id);
    }
}
