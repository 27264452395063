import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { emailValidator } from 'src/app/core/helpers/helpers.function';
import { IAvailableTypes } from 'src/app/core/models/available-types.interface';
import { ConstantService } from 'src/app/core/services/constant.service';
import { NotificationService } from 'src/app/core/services/notification.service';
import { UserService } from 'src/app/modules/empact-shop/core/services/user.service';
import { AuthType } from './../../core/enums/auth-type.enum';

@Component({
  selector: 'ecommerce-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss']
})
export class RegistrationComponent implements OnInit {

  @Output() typeChanged: EventEmitter<AuthType> = new EventEmitter<AuthType>(null);  

  form: FormGroup;
  phoneCodes: IAvailableTypes;

  constructor(
    private fb: FormBuilder,
    private constantService: ConstantService,
    private userSvc: UserService,
    private notify: NotificationService
  ) { }

  ngOnInit(): void {
    // TO DO - NEED SEPARETED SERVICE FOR COUNTRIES FOR ECOMMERCE
    this.constantService.getAvailablePhoneCodes().subscribe(values => {
      this.phoneCodes = values;
    });
    //////////////////////////

    this.form = this.initializeForm();
  }

  goToLogin(): void {
    this.typeChanged.emit(AuthType.Login);
  }

  initializeForm(): FormGroup {
    return this.fb.group({
      fullName: ['', [Validators.required]],
      phoneCode: ['', [Validators.required]],
      mobileNumber: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email, emailValidator()]],
      password: ['', [Validators.required, Validators.minLength(8), Validators.pattern(/^(?=.*\d)(?=.*[a-z])[0-9a-zA-Z_@./#$^*%&+-]{8,}$/)]],
      confirmPassword: ['', [Validators.pattern(/^(?=.*\d)(?=.*[a-z])[0-9a-zA-Z_@./#$^*%&+-]{8,}$/)]]
    }, { validator: this.checkPasswords });
  }

  checkPasswords(group: FormGroup) {
    const pass = group.controls.password.value;
    const confirmPass = group.controls.confirmPassword.value;

    return pass === confirmPass ? null : { notSame: true };
  }

  register(): void {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }

    this.userSvc.register(this.form.value).subscribe(result => {
      this.notify.showSuccessMessage('User created successfully');
      this.goToLogin();
    })
  }

  public fieldValidation(name): boolean {
    return this.form?.controls?.[name]?.touched && this.form?.controls?.[name]?.hasError('required');
  }
}
