import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ApiService} from '../../../core/services/api.service';

@Injectable()
export class AuthService extends ApiService {
    public login(loginData): Observable<any> {
        return this.request({
            path: 'auth/login',
            method: 'POST',
            body: loginData
        });
    }
    public forgotPassword(passwordData): Observable<any> {
        return this.request({
            path: `users/password?email=${passwordData.username}&code=${passwordData.code}&password=${passwordData.password}`,
            method: 'POST',
            body: passwordData
        });
    }
    public getCustomerDetails(id: any): Observable<any> {
        return this.request({
            method: 'GET',
            path: `auth/login-customer-deatils/${id}`,
        });
    }
    public getUserDetails(): Observable<any> {
        return this.request({
            method: 'GET',
            path: `auth/getuser`,
        });
    }
}
