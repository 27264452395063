import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from '../../../core/services/api.service';

@Injectable()
export class CarriersService extends ApiService {

    getCarriersList(page: number = 1, limit: number = 100, q?: number | string, orderColumn: string = '', orderDirection: string = '', type?: number): Observable<any> {
        return this.request({
            path: `carriers`,
            method: 'GET',
            query: {
                page: page,
                limit: limit,
                q: q ? q : '',
                orderField: orderColumn,
                orderDirection: orderDirection,
                type: type ? type : ''
            }
        });
    }

    getCarriersById(id: number): Observable<any> {
        return this.request({
            path: `carriers/${id}`,
            method: 'GET'
        });
    }

    addCarriers(body): Observable<any> {
        return this.request({
            path: `carriers`,
            method: 'POST',
            body: body
        });;
    }

    updateCarriers(id: number, body): Observable<any> {
        return this.request({
            path: `carriers/${id}`,
            method: 'PUT',
            body: body
        });
    }

    deleteCarriers(id: number): Observable<any> {
        return this.request({
            path: `carriers/${id}`,
            method: 'DELETE'
        });
    }

    getCarriersListForSelection(q?: number | string, page?: number, limit?: number, type?: number): Observable<any> {
        return this.request({
            path: `carriers/list/selection`,
            method: 'GET',
            query: { 
                q: q ? q : '',
                page,
                limit,
                type
            }
        });
    }
}