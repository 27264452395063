import { Component, Inject, Input, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { IAvailableTypes, IConstantItem } from 'src/app/core/models/available-types.interface';
import { ICountry, IState } from 'src/app/core/models/country.interface';
import { ConstantService } from 'src/app/core/services/constant.service';
import { UserService } from 'src/app/core/services/user.service';
import { IProduct, IProductExt } from 'src/app/modules/customers-order-management/models/order-details.model';
import { ICategory } from 'src/app/core/models/category.interface';
import { DatePipe } from '@angular/common';
import { CategoriesService } from 'src/app/core/services/categories.service';
import { ProductsService } from 'src/app/modules/products/services/products.service';
import { IProductDetails } from 'src/app/modules/products/models/product-details.interface';
import { LocalStorageService } from 'src/app/core/services/local-storage.service';
import { Roles } from 'src/app/shared/enums/role-types.enum';
import { formConstant } from 'src/config';
import { SaleProductService } from 'src/app/modules/sales-order-management/services/sale-product.service';
import { ProductSearchType } from 'src/app/shared/enums/product-search-type.enum';
import { ProductStatus } from 'src/app/shared/enums/product-status.enum';
import { FilterPipe } from 'src/app/shared/pipes/filter.pipe';
import { ProductDetailsComponent } from '../product-details/product-details.component';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-product-popup',
  templateUrl: './product-popup.component.html',
  styleUrls: ['./product-popup.component.scss'],
  providers: [DatePipe, FilterPipe]
})
export class ProductPopupDialog implements OnInit {

  form: FormGroup;
  textFieldMaxLength = formConstant.textFieldMaxLength;

  // locales = LOCATION_TYPES;
  // wareHouseTypes = WAREHOUS_TYPES;
  // counties = COUNTRIES;

  availableTypes: IAvailableTypes;
  availableCategories: ICategory[];
  countries: ICountry[];
  states: IState[] = [];
  showLoader: boolean = false;

  @Input() info: IProduct;
  @Input() isCreateMode: boolean;
  @Input() isEditable: boolean = false;
  @Input() isSaleOrder: boolean = false;
  @Input() isB2BSaleOrder: boolean = false;
  @Input() isMarketplaceSO: boolean = false;
  @Input() title: string = 'Shipment - Add Product List';
  @Input() isShipment: boolean = false;

  minDate: Date;

  previousePageSearch: number = 0;
  searchCategory: string = '';
  totalPagesCat: number = 1;

  listOfProducts: any[] = []; // TO DO
  previousePageProducts: number = 0;
  totalPagesProducts: number = 1;
  modelToShow: IProductExt;
  companyId: number;
  currentProduct: IProductDetails;
  searchProduct: FormControl = new FormControl('');
  unitPrice;
  constructor(
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<ProductPopupDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private constSvc: ConstantService,
    public userSvc: UserService,
    private catSvc: CategoriesService,
    private productSvc: ProductsService,
    private localStorageSvc: LocalStorageService,
    private saleProductService: SaleProductService,
    private dialog: MatDialog
  ) {

  }

  get warehouseTypes(): IConstantItem[] {
    if (!this.availableTypes) return;
    return this.availableTypes.WarehouseType;
  }

  get locales(): IConstantItem[] {
    if (!this.availableTypes) return;
    return this.availableTypes.WarehouseLocation;
  }

  get orderTypes(): IConstantItem[] {
    if (!this.availableTypes) return;
    return this.availableTypes.CompanyType; // TODO NEED TO CHANGE WHEN WILL HAVE ENUM
  }

  get customerTypes(): IConstantItem[] {
    if (!this.availableTypes) return;
    return this.availableTypes.CompanyType;
  }

  get orderStatuses(): IConstantItem[] {
    if (!this.availableTypes) return;
    return this.availableTypes.CompanyType; // TODO NEED TO CHANGE TO ENUM WHEN IT WILL BE AVAILABLE
  }

  get paymentStatuses(): IConstantItem[] {
    if (!this.availableTypes) return;
    return this.availableTypes.CompanyType; // TODO NEED TO CHANGE TO ENUM WHEN IT WILL BE AVAILABLE
  }

  ngOnInit(): void {
    this.isCreateMode = this.data['isCreateMode'];
    this.isEditable = this.data['isEditable'];
    this.isSaleOrder = this.data['isSaleOrder'];
    this.isB2BSaleOrder = this.data['isB2BSaleOrder'];
    this.isMarketplaceSO = this.data['isMarketplaceSO'];
    this.companyId = this.data['companyId'];
    this.isShipment = this.data['isShipment'] ?? false;
    this.form = this.initializeForm(this.info);
    this.minDate = new Date();
    this.subcribeOnAvailableTypes();

    this.searchProduct.valueChanges.subscribe(value => {
      this.previousePageProducts = 0;
      this.getListOfProducts();
    })

    this.form.controls.productId.valueChanges.subscribe(value => {
      this.currentProduct = this.listOfProducts.find(el => el.id === value);
      if (!!this.currentProduct) {
        this.form.get('costPriceUnit').setValue(this.currentProduct?.costPriceUnit);
        this.form.get('salePrice').setValue((this.info?.price || this.info?.product?.salePrice || 0).toFixed(2))
      }
    })
    // Need to update the tax-amount and other details if sale-order is in editable mode
    if (this.isSaleOrder && this.isEditable) {
      this.form.get('simplyDutyTaxRate').valueChanges.pipe(debounceTime(500)).subscribe(value => {
        this.form.value.simplyDutyTaxRate = value;
        this.updateListOfGoods(this.info?.id, this.form.value);
      });

      this.form.get('salePrice').valueChanges.pipe(debounceTime(500)).subscribe(value => {
        this.form.value.salePrice = value;
        this.updateListOfGoods(this.info?.id, this.form.value);
      });

      if (!this.isCreateMode) {
        this.form.get('numOfUnits').valueChanges.pipe(debounceTime(500)).subscribe(value => {
          this.form.value.numOfUnits = value;
          this.updateListOfGoods(this.info?.id, this.form.value);
        });
      }
    } else {
      this.form.get('costPriceUnit').valueChanges.subscribe(value => {
        this.form.get('salePrice').setValue(value);
        this.form.get('totalPrice').setValue(this.totalPrice);
      });
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onSubmit(): void {
    if (this.form.invalid) {
      return;
    }
    this.info = this.model;
    this.modelToShow = {
      ...this.model,
      product: this.listOfProducts.find(el => el.id === this.model.productId)
    }
    this.dialogRef.close({ info: this.info, modelToShow: this.modelToShow });
  }

  initializeForm(info: any): FormGroup {
    this.currentProduct = info;
    return this.formBuilder.group({
      numOfUnits: [{ disabled: false, value: info?.units || null }, (!this.isCreateMode && !this.isB2BSaleOrder) ? [] : [Validators.required]],
      salePrice: [{ disabled: false, value: this.unitPrice }, !this.isB2BSaleOrder ? [] : [Validators.required]],
      costPriceUnit: [{ disabled: false, value: this.currentProduct?.costPriceUnit || info?.product?.costUnitPrice }, !this.isB2BSaleOrder ? [] : [Validators.required]],
      totalPrice: [{ disabled: false, value: this.totalPrice }, []],
      costUnitPrice: [this.info?.costUnitPrice || '', []],
      saleUnitPrice: [this.info?.saleUnitPrice ? this.info?.saleUnitPrice : this.unitPrice || '', []],
      discount: [this.info?.discount || 0, []],
      productId: [{ disabled: !this.isCreateMode, value: info?.productId || info?.product?.productId || null }, [Validators.required]],
      hsCode: [{ disabled: false, value: info?.hsCode || null }, (!this.isEditable || this.isCreateMode) ? [] : [Validators.pattern(/[0-9]{4}.[0-9]{2}.[0-9]{4}/), Validators.required]],
      simplyDutyTaxRate: [{ disabled: this.isCreateMode, value: info?.simplyDutyTaxRate || null }],
      simplyDutyTaxAmount: [{ disabled: this.isCreateMode, value: info?.simplyDutyTaxAmount || null }],
    });
  }

  updateListOfGoods(id, value) {
    if (this.isAdmin && this.form.valid) {
      this.showLoader = true;
      this.saleProductService.updateTaxAmountFromTaxRate(id, value).subscribe(data => {
        if (!data) return;
        this.showLoader = false;
        this.form.get('totalPrice').setValue(data.totalAmount);
        //   this.form.get('salePrice').setValue(data.price);
        this.form.get('simplyDutyTaxAmount').setValue(data.simplyDutyTaxAmount);
      }, err => {
        this.showLoader = false;
      });
    }
  }

  subcribeOnAvailableTypes(): void {
    this.constSvc.availableTypes.subscribe(data => {
      if (!data) return;
      this.availableTypes = data;
    });

    this.catSvc.getAvailableCategories().subscribe(data => {
      this.availableCategories = data.items;
    })

    this.getListOfProducts();
  }

  get isAdmin(): boolean {
    return this.localStorageSvc.get('user').role == Roles.Admin || this.localStorageSvc.get('user').role == Roles.DelicateAdmin;
  }

  get model(): IProduct {
    const product = this.listOfProducts.find(el => el.id === this.form.get('productId').value);
    return {
      ...this.info,
      hsCode: this.isSaleOrder ? this.form.get('hsCode').value : '1111.11.1111',
      simplyDutyTaxRate: this.form.get('simplyDutyTaxRate').value || 0,
      simplyDutyTaxAmount: this.form.get('simplyDutyTaxAmount').value || 0,
      discount: this.form.get('discount').value || 0,
      weight: product?.weight,
      volume: product?.volume,
      units: parseInt(this.form.get('numOfUnits').value),
      productId: this.form.get('productId').value,
      itemNumber: product?.sku,
      description: product?.name,
      price: this.isEditable ? (this.isSaleOrder ? Number(this.form.get('salePrice').value) : Number(this.form.get('saleUnitPrice').value)) : (Number(this.form.get('salePrice').value) || product?.salePrice),
      saleUnitPrice: this.isSaleOrder ? this.form.get('salePrice').value : this.form.get('saleUnitPrice').value,
      costUnitPrice: this.form.get('costPriceUnit').value,
      costPriceUnit: this.isSaleOrder ? parseInt(this.form.get('costPriceUnit').value) : product?.costPriceUnit,
      categoryId: product?.categoryId,
      status: product?.status
    }
  }

  changeTo0(field: string): void {
    this.form.get(field).value < 0 ? this.form.get(field).setValue(0) : '';
  }

  getListOfProducts(
    page: number = this.previousePageProducts + 1,
    q: string = this.searchProduct.value || '',
    limit: number = 500,
  ): void {
//   this.productSvc.getProducts(page, limit, q, 'name', 'ASC', this.companyId, null, ProductSearchType.WithCategory, this.isCreateMode ? ProductStatus.Active : null).subscribe(data => {
    this.productSvc.getProducts(page, limit, q, 'name', 'ASC', this.companyId, null, this.isShipment ? ProductSearchType.All : ProductSearchType.WithCategory, this.isShipment ? null : (this.isCreateMode ? ProductStatus.Active : null)).subscribe(data => {
      if (!data) return;

      if (data.meta.currentPage > 1)
        this.listOfProducts = [...this.listOfProducts, ...data.items];
      else this.listOfProducts = data.items;

      this.previousePageProducts = data.meta.currentPage;
      this.totalPagesProducts = data.meta.totalPages;
      this.setUnitPrice(data.items);
    });
  }

    get totalPrice(): string {
        return (parseFloat(this.unitPrice) * this.numOfUnits).toFixed(2);
    }

  get numOfUnits(): number {
    return parseInt(this.isCreateMode ? (this.form?.controls?.numOfUnits?.value || 0) : this.info?.units || 0);
  }

  // get unitPrice(): string {
  //   return this.isCreateMode ?
  //     (this.form?.controls?.costPriceUnit?.value || this.listOfProducts.find(el => el.id === this.form?.controls?.productId?.value)?.salePrice || 0).toFixed(2) :
  //     (this.info.price || this.info?.product?.salePrice || 0).toFixed(2);
  // }

  get unitPriceTotal(): string {
    return this.isCreateMode ?
      (this.listOfProducts.find(el => el.id === this.form?.controls?.productId?.value)?.costPriceUnit || 0).toFixed(2) :
      (this.info.costPriceUnit || this.info?.product?.costPriceUnit || 0).toFixed(2);
  }

  changeProduct(value): void {
    let product = this.listOfProducts.find(el => el.id === value?.value);
    let salePrice = product?.salePrice || 0;

    if (!!this.form) {
      this.form.controls.saleUnitPrice.setValue(salePrice?.toFixed(2));
      this.form.controls.costUnitPrice.setValue(product?.costPriceUnit?.toFixed(2));
      this.form.controls.discount.setValue(product?.discount?.toFixed(2));

      this.form.controls.costUnitPrice.setErrors(null);
    }
  }

  setUnitPrice(event): void {
    this.unitPrice = this.isCreateMode ? (this.listOfProducts.find(el => el.id === this.form?.controls?.productId?.value)?.salePrice || 0).toFixed(2) :
      (this.info.price || this.info?.product?.salePrice || 0).toFixed(2);
    this.form.controls.salePrice.setValue(this.unitPrice);
  }

    totalPriceBindData() {
        if (this.isCreateMode || this.isEditable) {
            const total = (parseFloat(this.form.controls.numOfUnits.value) * parseFloat(this.form.controls.salePrice.value)).toFixed(2)
            this.form.controls.totalPrice.setValue(total);
        }
    }

  openAddProductPopup(): void {
    const dialogRef = this.dialog.open(ProductDetailsComponent, {
      maxHeight: '600px',
    });
    dialogRef.componentInstance.allowAdd4Customer = true;
    dialogRef.componentInstance.createMode = true;
    dialogRef.componentInstance.marginTop = '0';
    dialogRef.componentInstance.companyCode = Number(this.companyId);

    dialogRef.afterClosed().subscribe(result => {
      this.getListOfProducts(1);
    });
  }
}
