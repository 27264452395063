import {DatePipe} from '@angular/common';
import {Component, OnInit} from '@angular/core';
import {FormControl} from '@angular/forms';
import {MatDialog} from '@angular/material/dialog';
import {forkJoin} from 'rxjs';
import {PageParams} from '../../../../core/models/page-params.model';
import {ConstantService} from '../../../../core/services/constant.service';
import {NotificationService} from '../../../../core/services/notification.service';
import {SaleOrderInvoiceType} from '../../../../shared/enums/sale-order-invoice-type.enum';
import {RegistrationService} from '../../../customer-registration/services/registration.service';
import {SaleOrderService} from '../../../sales-order-management/services/sale-order.service';
import {EInvManagerTabType, InvoiceManagementService} from '../../invoice-management.service';
import {InvManagerTab} from '../inv-manager-tab';
import {InvoiceFormComponent} from '../invoice-forms/invoice-form.component';

@Component({
    selector: 'eci-sale-order-tab',
    templateUrl: './sale-order-tab.component.html',
    styleUrls: ['./sale-order-tab.component.scss']
})
export class SaleOrderTabComponent extends InvManagerTab implements OnInit {

    orderType: FormControl = new FormControl('');
    orderTypesList: { id: string; name: string; }[] = [
        { id: 'b2c', name: 'B2C SO' },
        { id: 'b2b', name: 'B2B SO' },
        { id: 'reporting_tool', name: 'Reporting Tool SO' },
        { id: 'empact_shop', name: 'Empact Shop SO' },
    ];

    constructor(
        public invoiceManagementService: InvoiceManagementService,
        public datePipe: DatePipe,
        public constSvc: ConstantService,
        public saleOrderSvc: SaleOrderService,
        private dialog: MatDialog,
        public notify: NotificationService,
        public regSvc: RegistrationService,
    ) {
        super(
            invoiceManagementService,
            datePipe,
            constSvc,
            EInvManagerTabType.so,
            regSvc,
            notify,
        );
    }

    ngOnInit(): void {
        this.search(this.prepareSearchParamsSO());
        this.onInit();
        this.orderType.valueChanges.subscribe(() => this.clearPage());
    }

    prepareSearchParamsSO() {
        let params: any = {...this.prepareSearchParams()};
        if (this.orderType.value) {
            params.orderType = this.orderType.value;
        }
        return params;
    }

    onAction(e: { action: string; item: any, itemIds?: string[] }) {
        switch (e.action) {
            case 'view':
                this.saleOrderSvc.downloadInvoice(e.item.id);
                break;
            case 'edit':
                this.editSOInvoice(e.item);
                break;
            case 're-issue':
                this.isLoad.emit(true);
                this.invoiceManagementService.reIssueInvoice(e.itemIds, this.tabType).subscribe(res => {
                    this.isLoad.emit(false);
                    this.search(this.prepareSearchParamsSO());
                });
            case 're-issue-all':
                this.isLoad.emit(true);
                this.invoiceManagementService.reIssueAllInvoice(e.itemIds, this.tabType).subscribe(res => {
                    this.isLoad.emit(false);
                    this.search(this.prepareSearchParamsSO());
                    this.table.listOfSelectedItems = [];
                    this.notify.showSuccessMessage('Invoice Regenerated successfully');
                });
                break;
            case 'upload':
                this.uploadedInvoice = e;
                this.invoiceFileInput.nativeElement.click();
                break;
        }
    }

    editSOInvoice(item) {
        this.isLoad.emit(true);
        forkJoin([
            this.saleOrderSvc.getSaleOrderInvoiceData(item.saleOrder.id, item.id),
            this.invoiceManagementService.getTemplateData(SaleOrderInvoiceType[item.type])
        ]).subscribe(res => {
            this.isLoad.emit(false);
            let dialogRef = this.dialog.open(InvoiceFormComponent, {
                maxHeight: '80vh',
                width: '890px',
                panelClass: 'inv-man-dialog'
            });

            dialogRef.componentInstance.type = item.type;
            dialogRef.componentInstance.tabType = 0;
            dialogRef.componentInstance.invoice = res[0];
            dialogRef.componentInstance.template = res[1].data?.template;
            dialogRef.componentInstance.isInvoice = true;

            dialogRef.afterClosed().subscribe(value => {
                if (!value) return;
                this.saleOrderSvc.updateSaleOrderInvoiceData(value).subscribe(() => {
                    this.search(this.prepareSearchParamsSO());
                    this.notify.showSuccessMessage('Updated successfully');
                });
            });
        }, error => {
            this.notify.showError('Invoice is not available due to some error');
            this.isLoad.emit(false);
        });
    }

    onChange(e: Event) {
        this.isLoad.emit(true);
        this.invoiceUpload(e).subscribe(res => {
            this.isLoad.emit(false);
            this.search(this.prepareSearchParamsSO());
        }, error => {
            this.isLoad.emit(false);
        })
    }

    onPageChange(e: PageParams) {
        super.onPageChange(e, this.prepareSearchParamsSO());
    }
}
