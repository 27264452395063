import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSort } from '@angular/material/sort';
import { IConstantItem } from 'src/app/core/models/available-types.interface';
import { PageParams } from 'src/app/core/models/page-params.model';
import { IPalette } from 'src/app/modules/customers-order-management/models/palette.interface';

@Component({
  selector: 'eci-pallets-table',
  templateUrl: './pallets-table.component.html',
  styleUrls: ['./pallets-table.component.scss']
})
export class PalletsTableComponent implements OnInit {
  constructor(
    private fb: FormBuilder
  ) { }

  @Input() dataSource;
  @Input() data: IPalette[];
  @Input() displayedColumns;
  @Input() availablePackType: IConstantItem[];
  @Input() isLink: boolean = false;
  @Input() weightUnits: IConstantItem[];
  @Input() currentWeightType: string;
  @Input() currentMeasuremenrType: string;
  @Input() isCreateMode: boolean;
  @Input() isOcean: boolean = false;
  @Input() denominators: {
    airDenominator,
    oceanDenominator
  } = { 
    airDenominator: 5000,
    oceanDenominator: 1000000
  }
  @Output() pageChange: EventEmitter<PageParams> = new EventEmitter<PageParams>(null);
  @Output() editPickupInfos: EventEmitter<number> = new EventEmitter<number>(null);
  @Output() editProducts: EventEmitter<number> = new EventEmitter<number>(null);
  @Output() add: EventEmitter<number> = new EventEmitter<number>(null);
  @Output() delete: EventEmitter<number> = new EventEmitter<number>(null);
  @Output() dataChange: EventEmitter<IPalette[]> = new EventEmitter<IPalette[]>();

  rows: FormArray = this.fb.array([]);
  form: FormGroup = this.fb.group({ 'pallets': this.rows });

  @ViewChild(MatSort) sort: MatSort;

  ngOnInit(): void {
    this.data.forEach((element: IPalette) => {
      this.addRowToForm(element);
    });
    this.form.valueChanges.subscribe(data => {
      this.dataChange.emit(data['pallets']);
    })
  }

  deleteRow(id): void {
    this.rows.removeAt(id);
    this.delete.emit(id);
    this.updateView();
  }

  addRow(): void {
    this.add.emit();
    this.addRowToForm();
  }

  copyRow(index): void {
    this.addRowToForm(this.rows.value[index]);
  }

  addRowToForm(data?: IPalette): void {
    const row = this.fb.group({
      unitsOfMeasure: [{disabled: !this.isCreateMode, value: 1}, [Validators.required]],
      packingType: [{disabled: !this.isCreateMode, value: data?.packingType || 0}, [Validators.required]],
      width: [{disabled: !this.isCreateMode, value: data?.width || 0}, [Validators.required, Validators.min(0)]],
      height: [{disabled: !this.isCreateMode, value: data?.height || 0}, [Validators.required, Validators.min(0)]],
      length: [{disabled: !this.isCreateMode, value: data?.length || 0}, [Validators.required, Validators.min(0)]],
      // 'weight': [data?.weight || 0, [Validators.required, Validators.min(0)]],
      volume: [{disabled: !this.isCreateMode, value: data?.volume || 0}, [Validators.required, Validators.min(0)]],
      // 'chargeableWeight': [data?.chargeableWeight || 0, [Validators.required, Validators.min(0)]],
      grossWeight: [{disabled: !this.isCreateMode, value: data?.grossWeight || 0}, [Validators.required, Validators.min(0)]],
      units: [{disabled: !this.isCreateMode, value: data?.units || 0}, [Validators.required, Validators.min(0)]],
      searchpackingType: new FormControl(""),
      searchunitsOfMeasure: new FormControl(""),
    });
    
    this.rows.push(row);
    this.updateView();
  }

  changeVolume(el, index): void {
    if (!el) return;
    const amountOfUnits = ((this.form.get('pallets') as FormArray).controls[index] as FormGroup).get('units');
    this.rows.value[index].volume = amountOfUnits ? ((this.rows.value[index].height * this.rows.value[index].width * this.rows.value[index].length) / (this.isOcean ? this.denominators.oceanDenominator : this.denominators.airDenominator)) * amountOfUnits.value : 0;
    ((this.form.get('pallets') as FormArray).controls[index] as FormGroup).get('volume').setValue(parseFloat(this.rows.value[index].volume.toFixed(3)));
    el.volume = parseFloat((amountOfUnits ? ((el.height * el.width * el.length) / (this.isOcean ? this.denominators.oceanDenominator : this.denominators.airDenominator)) * amountOfUnits.value : 0).toFixed(3));
  }

  updateView() {
    this.dataSource = [...this.rows.controls];
  }

  changeTo0(index, field: string): void {
    const el = ((this.form.get('pallets') as FormArray).controls[index] as FormGroup).get(field).value;
    el < 0 ? ((this.form.get('pallets') as FormArray).controls[index] as FormGroup).get(field).setValue(0) : '';
  }
}
