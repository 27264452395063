import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserService } from 'src/app/core/services/user.service';

@Component({
  selector: 'app-reorder-popup',
  templateUrl: './reorder-popup.component.html',
  styleUrls: ['./reorder-popup.component.scss']
})
export class ReorderPopupDialog implements OnInit {

  form: FormGroup;
  isB2BSaleOrder: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<ReorderPopupDialog>,
    public userSvc: UserService  ) {

  }

  ngOnInit(): void {
    this.form = this.initializeForm();
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onSubmit(): void {
    if (this.form.invalid) return;
    this.dialogRef.close(this.form.value);
  }

  initializeForm(): FormGroup {
    return this.formBuilder.group({
      trackingNumber: [''],
      saleOrderId: [''],
      referenceOrderId: [''],
    });
  }
}
