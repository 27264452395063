import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import { map } from 'rxjs/operators';
import { saveAs } from 'file-saver';
import {ICountry} from '../models/country.interface';
import { ApiService } from './api.service';
import { LocalStorageService } from './local-storage.service';
import { ICompany } from 'src/app/modules/customer-registration/models/company.model';
import {UserService} from './user.service';

@Injectable()
export class CompanyService extends ApiService {

    productGeneralDescriptionList: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    $company: BehaviorSubject<any> = new BehaviorSubject<any>(null);

    constructor(
        public http: HttpClient,
        public localStorage: LocalStorageService,
        public userService: UserService
    ) {
        super(http, localStorage);
        this.getProductGeneralDescriptionList().subscribe(data => {
            if (!data) {
                return;
            }
            this.productGeneralDescriptionList.next(data);
        });
        if (this.userService.isAdmin) {
            this.getAdminCompany().subscribe(company => {
                this.$company.next(company);
            });
        }
    }

    //#region COMPANY


    getCompany(
        page: number = 1,
        limit: number = 100,
        q: string = '',
        showIncompleted: boolean = false,
        type: number[] = [],
        orderField: string = '',
        orderDirection: string = '',
        dateFrom?: string,
        dateTo?: string
    ): Observable<any> {
        if (!type) type = [];
        return this.request({
            path: `company`,
            method: 'GET',
            query: {
                page: page,
                limit: limit,
                showIncompleted,
                q: q,
                type: type,
                orderField: orderField,
                orderDirection: orderDirection,
                dateFrom,
                dateTo
            }
        });
    }

    getConfidentialityAgreement(companyId:number):Observable<any> {
        return this.request({
            path:'company/get-confidentiality-agreement',
            method:'GET',
            query:{
                assetableId:companyId,
                type:'confidentialityAgreement'
            }
        })
    }

    staticMonthlyFeesCharges(companyBody: any): Observable<any> {
        return this.request({
            path: `subscription-orders/static-monthly-fee`,
            method: 'POST',
            body: companyBody
        });
    }
    MonthlyFeesCharges(companyBody: ICompany): Observable<any> {
        return this.request({
            path: `subscription-orders/take-monthly-subscription-fee`,
            method: 'POST',
            body: companyBody
        });
    }

    monthlyFeeImmediatePayment(companyBody: ICompany): Observable<any> {
        return this.request({
            path: `subscription-orders/monthly-fee-immediate-pay`,
            method: 'POST',
            body: companyBody
        });
    }

    addCompany(companyBody: any): Observable<any> {
        return this.request({
            path: `company`,
            method: 'POST',
            body: companyBody
        });
    }
    deleteCompanies(body: any): Observable<any> {
        return this.request({
            path: `company/delete/`,
            method: 'DELETE',
            body
        });
    }

    getCompanyById(id: number): Observable<any> {
        return this.request({
            path: `company/${id}`,
            method: 'GET',
        });
    }

    getAdminCompany(): Observable<any> {
        return this.request({
            path: `company/admin-company`,
            method: 'GET',
        });
    }

    getCompanyList(limit: number = 1000, page: number = 1, q: string = ''): Observable<any> {
        return this.request({
            path: `company/list`,
            method: 'POST',
            query: {
                limit,
                page,
                q
            }
        });
    }

    getCompanyListForSales(q: string = ''): Observable<any> {
        return this.request({
            path: `company/list-for-sales`,
            method: 'POST',
            query: { q }
        });
    }

    getAgrrementsByCompanyId(id: number): Observable<any> {
        return this.request({
            path: `company/${id}/agreement`,
            method: 'GET',
        });
    }

    updateCompany(id: number, body): Observable<any> {
        return this.request({
            path: `company/${id}`,
            method: 'PUT',
            body: body
        });
    }

    addVATNumber(id: number, body): Observable<any> {
        return this.request({
            path: `company/add-vat/${id}`,
            method: 'POST',
            body: body
        });
    }

    setCompanyRegistrationStep(id: number, registrationStep: number): Observable<any> {
        return this.request({
            path: `company/set-registration-step`,
            method: 'POST',
            body: {
                companyId: id,
                registrationStep
            }
        });
    }

    deleteCompany(id): Observable<any> {
        return this.request({
            path: `company/${id}`,
            method: 'DELETE',
        });
    }

    deleteLicense(id): Observable<any> {
        return this.request({
            path: `company/license/${id}`,
            method: 'DELETE',
        });
    }

    downloadLicense(id): Observable<any> {
        return this.request({
            path: `company/download`,
            method: 'POST',
            body: {
                id
            }
        });
    }

    completeRegistration(id: number): Observable<any> {
        return this.request({
            path: `company/complete`,
            method: 'POST',
            body: {
                companyId: id,
                isCompleted: true
            }
        });
    }

    setReportGenerateDate(id: number, reportDay): Observable<any> {
        return this.request({
            path: `company/set-report`,
            method: 'POST',
            body: {
                companyId: id,
                reportDay: reportDay
            }
        });
    }

    sendOnboardingDetails(id: number): Observable<any> {
        return this.request({
            path: `company/send-onboarding-details-mail`,
            method: 'POST',
            body: {
                companyId: id
            }
        });
    }

    sendOnboardingComplete(id: number): Observable<any> {
        return this.request({
            path: `company/send-onboarding-complete-mail`,
            method: 'POST',
            body: {
                companyId: id
            }
        });
    }

    sendComissionaryAgreement(body: any): Observable<any> {
        return this.requestPdf({
            method: 'POST',
            path: `company/comissionary-agreement`,
            body
        }).pipe(map(res => {
            const fileURL = URL.createObjectURL(res);
            saveAs(fileURL, 'agreements.pdf');
            // window.open(fileURL, '_blank');
        }));
    }

    sendConfidentialityAgreement(body: any): Observable<any> {
        return this.requestPdf({
            method: 'POST',
            path: `company/confidentiality-agreement`,
            body
        }).pipe(map(res => {
            const fileURL = URL.createObjectURL(res);
            saveAs(fileURL, 'confidentiality-agreement.pdf');
            // window.open(fileURL, '_blank');
        }));
    }

    getRegistrationDetails(token: string): Observable<any> {
        return this.request({
            method: 'GET',
            path: `company/get-registration-details/${token}`
        });
    }

     getProductGeneralDescriptionList(): Observable<any> {
        return this.request({
            method: 'GET',
            path: `company/product-general/description`
        });
    }

    //#endregion

    public downloadCompanies(
        q: string = '',
        showIncompleted: boolean = false,
        type: number[] = [],
        orderField: string = '',
        orderDirection: string = '',
        dateFrom?: string,
        dateTo?: string,
        shopType?: string,
    ): Observable<any> {
        if (!type) type = [];

        const query = {
            showIncompleted,
            q: q,
            type: type,
            orderField: orderField,
            orderDirection: orderDirection,
            dateFrom,
            dateTo,
            shopType,
        };

        return this.requestFile({
            path: `company/download-companies`,
            method: 'POST',
            query: query
        }, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet').pipe(map(res => {
            const a = document.createElement('a')
            const objectUrl = URL.createObjectURL(res)
            a.href = objectUrl
            a.download = `Companies${new Date().toDateString()}.xlsx`;
            a.click();
            URL.revokeObjectURL(objectUrl);
        }));
    }

    downloadTaxInstructions(): Observable<any> {
        return this.requestPdf({
            method: 'POST',
            path: `company/download-tax-number-instructions`,
        }).pipe(map(data => {
            const objectUrl = URL.createObjectURL(data);
            saveAs(objectUrl, 'tax_number_instructions.pdf');
        }));
    }
}
