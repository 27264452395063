import { Injectable } from '@angular/core';
import { ApiService } from '../../../core/services/api.service';
import { Observable } from 'rxjs';

@Injectable()
export class SaleProductService extends ApiService {

    getOrderProducts(orderId: number, page: number = 1, limit: number = 100, q: string = '', orderField: string = '', orderDirection: string = ''): Observable<any> {
        return this.request({
            method: 'GET',
            path: `sale-orders/${orderId}/products`,
            query: {
                q: q,
                limit: limit,
                page: page,
                orderField: orderField,
                orderDirection: orderDirection
            }
        });
    }

    getOrderProductById(orderId: number, id: number): Observable<any> {
        return this.request({
            method: 'GET',
            path: `sale-orders/${orderId}/products/${id}`,
        });
    }

    updateOrder(id: number, body): Observable<any> {
        return this.request({
            method: 'PUT',
            path: `sale-orders/update-list-of-goods/${id}`,
            body: body
        });
    }

    updateTaxAmountFromTaxRate(id: number, body): Observable<any> {
        return this.request({
            method: 'POST',
            path: `sale-orders/update-duty-tax-amount/${id}`,
            body : body
        });
    }
}