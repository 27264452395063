export enum TypeBusinesses {
    'Sole proprietorship',
    'Partnership',
    'LLP',
    'LLC',
    'Series LLC',
    'C corporation',
    'S corporation',
    'Nonprofit corporation',
    'Benefit corporation',
    'L3C',
    'Reporting Tool',
    'Test User',
}
