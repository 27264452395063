import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { LocalStorageService } from './local-storage.service';

@Injectable()
export class MarketplacesService extends ApiService {

    public availableMarketplaces$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    public marketPlaceOtherNameSource = new BehaviorSubject<string>(null);
    constructor(
        http: HttpClient,
        localStorageBrowser: LocalStorageService
    ) {
        super(http, localStorageBrowser);
        this.getAvailableMarketplaces('').toPromise();
    }

    getAvailableMarketplaces(q: string = ''): Observable<any> {
        return this.request({
            path: `marketplaces`,
            method: 'GET',
            query: {
                q
            }
        }).pipe(tap(el => this.availableMarketplaces$.next(el)));
    }

    otherMarketPlace(marketPlaceOtherName: string) {
        this.marketPlaceOtherNameSource.next(marketPlaceOtherName);
      }

}
