import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import { Subject } from 'rxjs';
import { ApiService } from './api.service';
import { environment } from '../../../environments/environment';
import { LocalStorageService } from 'src/app/core/services/local-storage.service';
import { NotificationService } from 'src/app/core/services/notification.service';

@Injectable({
    providedIn: 'root'
})
export class ConsolidateShipmentService extends ApiService {

    public fileLoadedObserver = new Subject<any>();
    public amountNotHandledConsolShipment$: BehaviorSubject<number> = new BehaviorSubject<number>(0);

    constructor(
        public http: HttpClient,
        public storageBrowser: LocalStorageService,
        private notify: NotificationService
    ) {
        super(http, storageBrowser);
    }
    getShipments(page: number = 1, limit: number = 100, q: string = '', companyId?: number, searchParams?: any, orderField: string = '', orderDirection: string = ''): Observable<any> {
        return this.request({
            method: 'GET',
            path: `shipment-consol`,
            query: {
                q: q,
                limit: limit,
                page: page,
                companyId: companyId ? companyId : '',
                orderTypes: searchParams?.orderTypes,
                companyTypes: searchParams?.companyTypes,
                orderStatuses: searchParams?.orderStatuses,
                handleStatus: searchParams?.handleStatus,
                dateTo: searchParams?.dateTo,
                dateFrom: searchParams?.dateFrom,
                deliveryDateTo: searchParams?.deliveryDateTo,
                deliveryDateFrom: searchParams?.deliveryDateFrom,
                orderField: orderField,
                orderDirection: orderDirection,
            }
        });
    }

    getShipmentById(id: number): Observable<any> {
        return this.request({
            method: 'GET',
            path: `shipment-consol/${id}`,
        });
    }

    deleteShipment(id: number): Observable<any> {
        return this.request({
            method: 'DELETE',
            path: `shipment-consol/${id}`,
        });
    }

    addShipment(body): Observable<any> {
        return this.request({
            method: 'POST',
            path: `shipment-consol`,
            body: body
        });
    }

    updateShipment(id: number, body): Observable<any> {
        return this.request({
            method: 'PUT',
            path: `shipment-consol/${id}`,
            body: body
        });
    }

    // downloadOrder(id: number, body): Observable<any> {
    //     return this.request({
    //         method: 'PUT',
    //         path: `shipment-consol/${id}`,
    //         body: body
    //     });
    // }
    public downloadFile(orderId, id): any {
        this.request({
            path: `shipment-consol/documents/${id}`,
            method: 'GET',
        }).subscribe(res => {
            window.open(`${res.file}`, '_blank');
        });
    }

    public downloadInvoice(id): any {
        this.request({
            path: `shipment-consol/invoices/${id}`,
            method: 'GET',
        }).subscribe(res => {
            window.open(`${res.file}`, '_blank');
        }, error => {
            this.notify.showError('Invoice is not available due to some error');
        });
    }

    public uploadInvoice(id): any {
        this.request({
            path: `shipment-consol/invoices/${id}`,
            method: 'GET',
        }).subscribe(res => {
            window.open(`${res.file}`, '_blank');
        });
    }

    public updateOrderDoc(orderId, id, body): Observable<any> {
        return this.request({
            path: `shipment-consol/${orderId}/documents/${id}`,
            method: 'PUT',
            body: body
        })
    }

    public deleteOrderDoc(orderId, id): Observable<any> {
        return this.request({
            path: `shipment-consol/${orderId}/documents/${id}`,
            method: 'DELETE',
        })
    }

    uploadFile(files: any, orderId, docId, fieldName = 'content') {
        if (files.length > 0) {
            const file: File = files[0];
            const formData: FormData = new FormData();
            formData.append(fieldName, file, file.name);
            const token = this.storageBrowser.get('token');
            const xhr = new XMLHttpRequest();
            xhr.open('PUT', `${environment.api_url}shipment-consol/${orderId}/documents/${docId}`, true);
            xhr.setRequestHeader('Authorization', `Bearer ${token}`);
            xhr.onload = () => {
                if (xhr.readyState === 4) {
                    if (xhr.status === 200) {
                        // Use an observer to notify competion
                        this.fileLoadedObserver.next(xhr.responseText);

                    } else {
                        this.fileLoadedObserver.error(xhr.statusText);
                        console.error(xhr.statusText);
                    }
                }
            };
            xhr.onerror = () => {
                console.error(xhr.statusText);
            };
            xhr.send(formData);
        }
    }

    getOrderDocs(orderId): Observable<any> {
        return this.request({
            method: 'GET',
            path: `shipment-consol/${orderId}/documents`,
        });
    }

    deleteDocument(documentId): Observable<any> {
        return this.request({
            method: 'DELETE',
            path: `shipment-consol/documents/${documentId}`,
        });
    }

    deleteInvoice(invoiceId): Observable<any> {
        return this.request({
            method: 'DELETE',
            path: `shipment-consol/invoices/${invoiceId}`,
        });
    }

    sendDocuments(body): Observable<any> {
        return this.request({
            path: `shipment-consol/documents/send`,
            method: 'POST',
            body: body
        });
    }

    sendInvoices(body): Observable<any> {
        return this.request({
            path: `shipment-consol/invoices/send`,
            method: 'POST',
            body: body
        });
    }

    switchHandled(ids: number[]): Observable<any> {
        return this.request({
            method: 'POST',
            path: `shipment-consol/switch-handled`,
            body: ids
        });
    }

    delete(ids: number[]): Observable<any> {
        return this.request({
            method: 'POST',
            path: `shipment-consol/delete`,
            body: ids
        });
    }

    getAmountConsolHandled(isHandled: boolean) {
        return this.request({
            method: 'GET',
            path: `shipment-consol/get-handled/counts`,
            query: {
                handleStatus: isHandled,
            }
        })
    }

}
