import {
    Component, Input, ElementRef, AfterViewInit, ViewChild, OnInit, Output, EventEmitter
} from '@angular/core';
import { Observable } from 'rxjs';

import { fromEvent } from 'rxjs';
import { switchMap, takeUntil, pairwise } from 'rxjs/operators';

@Component({
    selector: 'app-canvas',
    templateUrl: './canvas.component.html',
    styleUrls: ['./canvas.component.scss'],
})
export class CanvasComponent implements AfterViewInit, OnInit {

    @ViewChild('canvas') public canvas: ElementRef;
    @ViewChild('canvas2') public canvas2: ElementRef;

    @Input() public width = 200;
    @Input() public height = 60;
    @Input() confidentialityCanvas: boolean = false;

    @Input() clear: boolean = false;
    @Output() cleared: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() draw: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() dataUrlChanged: EventEmitter<any> = new EventEmitter<any>();
    firstMouseClick: boolean = true;

    private cx: CanvasRenderingContext2D;

    ngOnInit(): void {
        setInterval(_ => { if (this.clear) this.clearCanvas() }, 0);
    }

    public ngAfterViewInit() {
        const canvasEl: HTMLCanvasElement = this.confidentialityCanvas ? this.canvas2.nativeElement : this.canvas.nativeElement;
        this.cx = canvasEl.getContext('2d');

        canvasEl.width = this.width;
        canvasEl.height = this.height;


        this.cx.fillStyle = "gray";
        this.cx.font = "8px Arial";
        this.cx.fillText("Your sign", (canvasEl.width / 2) - 17, (canvasEl.height / 2) + 8);

        this.cx.lineWidth = 3;
        this.cx.lineCap = 'round';
        this.cx.strokeStyle = '#000';


        this.captureEvents(canvasEl);
    }

    private captureEvents(canvasEl: HTMLCanvasElement) {
        fromEvent(canvasEl, 'mousedown')
            .pipe(
                switchMap((e) => {
                    return fromEvent(canvasEl, 'mousemove')
                        .pipe(
                            takeUntil(fromEvent(canvasEl, 'mouseup')),
                            takeUntil(fromEvent(canvasEl, 'mouseleave')),
                            pairwise() /* Return the previous and last values as array */
                        )
                })
            ).subscribe((res: [MouseEvent, MouseEvent]) => {
                const rect = canvasEl.getBoundingClientRect();
                const prevPos = {
                    x: res[0].clientX - rect.left,
                    y: res[0].clientY - rect.top
                };

                const currentPos = {
                    x: res[1].clientX - rect.left,
                    y: res[1].clientY - rect.top
                };

                this.drawOnCanvas(prevPos, currentPos);
            });
    }

    private drawOnCanvas(prevPos: { x: number, y: number }, currentPos: { x: number, y: number }) {
        if (!this.cx) { return; }
        this.cx.beginPath();
        this.draw.emit(true);
        if (prevPos) {
            this.cx.moveTo(prevPos.x, prevPos.y); // from
            this.cx.lineTo(currentPos.x, currentPos.y);
            this.cx.stroke();
        }

        var canvas = this.confidentialityCanvas ? document.getElementById("canvasToExportData") : document.getElementById("canvasToExport");
        var dataURL = (canvas as any).toDataURL("image/png");
        this.dataUrlChanged.emit(dataURL);
    }


    clearCanvas(): void {
        const canvasEl: HTMLCanvasElement = this.canvas2?.nativeElement || this.canvas?.nativeElement;
        const context = canvasEl.getContext('2d');
        context.clearRect(0, 0, canvasEl.width, canvasEl.height);
        this.cleared.emit(false);
        this.draw.emit(false);
    }
}
