import { Pipe, PipeTransform } from '@angular/core';
import { IConstantItem } from 'src/app/core/models/available-types.interface';
import * as _ from 'lodash';


@Pipe({ name: 'orderType', pure: false })
export class OrderTypePipe implements PipeTransform {
    transform(value: any, array: IConstantItem[]) {
        if (!array) return '';
        const elem = array.find(el => el.id === value)
        if(!elem) return '';
        return _.startCase(_.toLower(elem.name.split('_').join(' ')));
    }
}