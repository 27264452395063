import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { LocalStorageService } from './local-storage.service';

@Injectable()
export class PaymentTermsService extends ApiService {

    public paymentTerms$: BehaviorSubject<any> = new BehaviorSubject<any>(null);

    constructor(
        http: HttpClient,
        localStorageBrowser: LocalStorageService
    ) {
        super(http, localStorageBrowser);
        this.getPaymentTerms('').toPromise();
    }

    getPaymentTerms(q: string = ''): Observable<any> {
        return this.request({
            path: `payment-terms`,
            method: 'GET',
            query: {
                q
            }
        }).pipe(tap(el => this.paymentTerms$.next(el)));
    }
    createPaymentTerms(body): Observable<any> {
        return this.request({
            path: `payment-terms`,
            method: 'POST',
            body: body
        })
    }
}
