import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { formConstant } from '../../../../config';
import { ConstantService } from 'src/app/core/services/constant.service';
import { IAvailableTypes, IConstantItem } from 'src/app/core/models/available-types.interface';
import { IContactInfo } from 'src/app/modules/customer-management/models/contact-info';
import { UserService } from 'src/app/core/services/user.service';
import { DatePipe } from '@angular/common';

const CONTACT_TYPES = ['Agent', 'Source Warehouse', 'Destination Warehouse'];


@Component({
  selector: 'app-tax-report-generator-popup',
  templateUrl: './tax-report-generator-popup.component.html',
  styleUrls: ['./tax-report-generator-popup.component.scss']
})
export class TaxReportGeneratorPopup implements OnInit {

  form: FormGroup;
  textFieldMaxLength = formConstant.textFieldMaxLength;

  header: string = "Customer Registration - Add Contact";

  contactPersonTypes = CONTACT_TYPES;
  availableTypes: IAvailableTypes;

  isForUpdate: boolean = false;

  contactableId: number;

  info: IContactInfo;
  phoneCode: any[];

  constructor(
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<TaxReportGeneratorPopup>,
    private constSvc: ConstantService,
    public userSvc: UserService,
    private datePipe: DatePipe
  ) {

  }

  get types(): IConstantItem[] {
    if (!this.availableTypes) return;
    return this.availableTypes.ContactType;
  }

  ngOnInit(): void {
    this.form = this.initializeForm();
    this.subscribeOnAvailableTypes();
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onSubmit(): void {
    if (this.form.invalid) return;
    this.dialogRef.close(this.model);
  }

  initializeForm(): FormGroup {
    return this.formBuilder.group({
      dateFrom: ['', [Validators.required]],
      dateTo: ['', [Validators.required]],
      // type: ['', [Validators.required]]
    });
  }

  subscribeOnAvailableTypes(): void {
    this.constSvc.availableTypes.subscribe(data => {
      if (!data) return;
      this.availableTypes = data;
    })
  }

  compareWithFn(listOfItems, selectedItem) {
    return listOfItems && selectedItem && listOfItems.id === selectedItem.id;
  }

  get model(): any {
    return {
      dateFrom: this.form.get('dateFrom').value && this.form.get('dateFrom').value !== null ? this.datePipe.transform(this.form.get('dateFrom').value, 'yyyy-MM-dd') : '',
      dateTo: this.form.get('dateTo').value && this.form.get('dateTo').value !== null ? this.datePipe.transform(this.form.get('dateTo').value, 'yyyy-MM-dd') : '',
      // type: this.form.get('type').value,
    }
  }
}
