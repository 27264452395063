import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from '../../../core/services/api.service';

@Injectable()
export class PaymentService extends ApiService {
    createPayment(orderId: number, body): Observable<any> {
        return this.request({
            method: 'POST',
            path: `order/${orderId}/payments/status`,
            body: body
        });
    }

    getClientSecret(orderId: number): Observable<any> {
        return this.request({
            method: 'POST',
            path: `order/${orderId}/payments`,
        });
    }

    getShipmentConsolClientSecret(shipmentConsolId: number): Observable<any> {
        return this.request({
            method: 'POST',
            path: `shipment-consol/${shipmentConsolId}/payments`,
        });
    }

    createShipmentConsolPayment(shipmentConsolId: number, body): Observable<any> {
        return this.request({
            method: 'POST',
            path: `shipment-consol/${shipmentConsolId}/payments/status`,
            body: body
        });
    }
}