import {Component, OnInit} from '@angular/core';
import {InvoiceTemplateBase} from '../invoce-template-base';

@Component({
    selector: 'eci-import-air-invoice-form',
    templateUrl: './import-air-invoice-form.component.html',
    styleUrls: ['./import-air-invoice-form.component.scss']
})
export class ImportAirInvoiceFormComponent extends InvoiceTemplateBase implements OnInit {

    ngOnInit(): void {
        this.model = {
            ...this.template,
            ...this.invoice,
            customerCompany: {
                ...this.template.customerCompany,
                ...this.invoice.customerCompany
            }
        };
    }

}
