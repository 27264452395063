import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable()
export class CategoriesService extends ApiService {

    public categoriesFilters$: BehaviorSubject<any> = new BehaviorSubject<any>(null);

    getAvailableCategories(page: number = 1, limit: number = 1000, q: string = ''): Observable<any> {
        return this.request({
            path: `categories`,
            method: 'GET',
            query: {
                page: page,
                limit: limit,
                q: q
            }
        })
    }

    getAllAvailableCategories(): Observable<any> {
        return this.request({
            path: `categories/get-all`,
            method: 'GET',
        })
    }

    updateRate(id, body): Observable<any> {
        return this.request({
            path: `categories/${id}`,
            method: 'PUT',
            body: body
        })
    }

    createCategory(body): Observable<any> {
        return this.request({
            path: `categories`,
            method: 'POST',
            body: body
        })
    }

    createCategoryRate(body): Observable<any> {
        return this.request({
            path: `categories/add-rate`,
            method: 'POST',
            body: body
        })
    }

    updateCategoryWithRates(body): Observable<any> {
        return this.request({
            path: `categories/update-rates`,
            method: 'POST',
            body: body
        })
    }

    getCategories(page: number = 1, limit: number = 100, categoryId: string, q: string = '', orderField: string = '', orderDirection: string = '', countryCode?: string, enableRate: boolean = true,): Observable<any> {
        return this.request({
            method: 'GET',
            path: `categories`,
            query: {
                q: q,
                limit: limit,
                page: page,
                categoryId,
                countryCode: countryCode,
                orderField: orderField,
                orderDirection: orderDirection,
                enableRate
            }
        });
    }

    getExistingCategories(q: string = ''): Observable<any> {
        return this.request({
            method: 'GET',
            path: `categories/get-existing-categories`,
            query: {
                q: q,
            }
        });
    }

    getCategoriesWithRates(page: number = 1, limit: number = 100, categoryId: string, q: string = '', orderField: string = '', orderDirection: string = '', countryCode?: string, enableRate: boolean = true,): Observable<any> {
        return this.request({
            method: 'GET',
            path: `categories/categories_with_rates`,
            query: {
                q: q,
                limit: limit,
                page: page,
                categoryId,
                countryCode: countryCode,
                orderField: orderField,
                orderDirection: orderDirection,
                enableRate
            }
        });
    }

    getCategoriesRates(page: number = 1, limit: number = 100, categoryId: string, q: string = '', orderField: string = '', orderDirection: string = '', countryCode?: string, enableRate: boolean = true): Observable<any> {
        return this.request({
            method: 'GET',
            path: `categories/rates`,
            query: {
                q: q,
                limit: limit,
                page: page,
                categoryId,
                countryCode: countryCode,
                orderField: orderField,
                orderDirection: orderDirection,
                enableRate
            }
        });
    }

    getCategoryById(id: number): Observable<any> {
        return this.request({
            path: `categories/${id}`,
            method: 'GET',
        });
    }

    deleteCategoryById(id: number | number[]): Observable<any> {
        return this.request({
            path: `categories/${id}`,
            method: 'DELETE',
        });
    }

    exportToExel(page: number = 1, limit: number = 100, categoryId: string, q: string = '', orderField: string = '', orderDirection: string = '', countryCode?: string, enableRate: boolean = true): any {
        this.requestFile({
            path: `categories/download/generate-xlsx`,
            method: 'GET',
            query: {
                q: q,
                limit: limit,
                page: page,
                categoryId,
                countryCode: countryCode,
                orderField: orderField,
                orderDirection: orderDirection,
                enableRate
            }
        }, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet').subscribe(res => {
            const a = document.createElement('a')
            const objectUrl = URL.createObjectURL(res)
            a.href = objectUrl
            a.download = 'Categories.xlsx';
            a.click();
            URL.revokeObjectURL(objectUrl);
            // const fileURL = URL.createObjectURL(res);
            // window.open(fileURL, '_blank');
        });
    }
}
