import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ReportingToolService {

    private _selectedCountry = null;
    private _selectedCompany = null;
    private _selectedShop = null;
    private _userCompany = null;
    public userNewCodeEmitter = new BehaviorSubject<any>(null);

    constructor() {
    }

    get userCompany(): any {
        return this._userCompany;
    }

    set userCompany(value: any) {
        this._userCompany = value;
    }

    get selectedCountry(): any {
        return this._selectedCountry;
    }

    set selectedCountry(value: any) {
        this._selectedCountry = value;
    }

    get selectedCompany(): any {
        return this._selectedCompany;
    }

    set selectedCompany(value: any) {
        this._selectedCompany = value;
    }

    get selectedShop(): any {
        return this._selectedShop;
    }

    set selectedShop(value: any) {
        this._selectedShop = value;
    }
}
