import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { formConstant } from '../../../../config';
import { IAvailableTypes, IConstantItem } from 'src/app/core/models/available-types.interface';
import { ICountry, IState } from 'src/app/core/models/country.interface';
import { ConstantService } from 'src/app/core/services/constant.service';
import { UserService } from 'src/app/core/services/user.service';
import { IPickupInfo } from 'src/app/modules/customers-order-management/models/order-details.model';
import { DatePipe } from '@angular/common';
import { cellPhoneValidator } from 'src/app/core/helpers/helpers.function';
import { CityService } from 'src/app/core/services/city.service';
import { FilterPipe } from '../../pipes/filter.pipe';
import { AddCityPopup } from '../add-city-popup/add-city-popup.component';
import { NotificationService } from 'src/app/core/services/notification.service';

@Component({
  selector: 'app-new-pickup-info-popup',
  templateUrl: './new-pickup-info-popup.component.html',
  styleUrls: ['./new-pickup-info-popup.component.scss'],
  providers: [
    DatePipe,
    FilterPipe
  ]
})
export class NewPickupInfoPopupDialog implements OnInit {

  form: FormGroup;
  textFieldMaxLength = formConstant.textFieldMaxLength;
  info: IPickupInfo;
  showAlert: boolean = false;

  // locales = LOCATION_TYPES;
  // wareHouseTypes = WAREHOUS_TYPES;
  // counties = COUNTRIES;

  availableTypes: IAvailableTypes;
  countries: ICountry[];
  states: IState[] = [];
  ALlcountries: ICountry[];
  Allstates: IState[] = [];
  isCreateMode: boolean;
  cities: any[] = [];

  formConstant = formConstant;

  countryId: string;
  searchCountry: FormControl = new FormControl('');
  searchState: FormControl = new FormControl('');
  searchCity: FormControl = new FormControl('');

  constructor(
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<NewPickupInfoPopupDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private constSvc: ConstantService,
    public userSvc: UserService,
    private datePipe: DatePipe,
    private citySvc: CityService,
    public filterPipe: FilterPipe,
    private dialog: MatDialog,
    private notify: NotificationService
  ) {
    this.isCreateMode = data['isCreateMode'];
    this.countryId = data['countryId'];
    this.showAlert = data['showAlert'];
  }

  get warehouseTypes(): IConstantItem[] {
    if (!this.availableTypes) return;
    return this.availableTypes.WarehouseType;
  }

  get locales(): IConstantItem[] {
    if (!this.availableTypes) return;
    return this.availableTypes.WarehouseLocation;
  }

  get orderTypes(): IConstantItem[] {
    if (!this.availableTypes) return;
    return this.availableTypes.CompanyType; // TODO NEED TO CHANGE WHEN WILL HAVE ENUM
  }

  get customerTypes(): IConstantItem[] {
    if (!this.availableTypes) return;
    return this.availableTypes.CompanyType;
  }

  get orderStatuses(): IConstantItem[] {
    if (!this.availableTypes) return;
    return this.availableTypes.CompanyType; // TODO NEED TO CHANGE TO ENUM WHEN IT WILL BE AVAILABLE
  }

  get paymentStatuses(): IConstantItem[] {
    if (!this.availableTypes) return;
    return this.availableTypes.CompanyType; // TODO NEED TO CHANGE TO ENUM WHEN IT WILL BE AVAILABLE
  }

  get isAdmin(): boolean {
    return this.userSvc.isAdmin;
  }

  ngOnInit(): void {
    this.form = this.initializeForm(this.info);

    this.subcribeOnAvailableTypes();
    this.form.get('country').setValue(this.countryId || this.info?.address?.countryId || this.info?.address?.state?.country?.code);
    // TO DO - WTF?? WHY USING HERE sessionStorage???????
    this.form.get('dueDate').setValue(!!this.info?.dueDate ? new Date(this.info?.dueDate) : this.datePipe.transform(sessionStorage.getItem('orderDueDate'), 'yyy-MM-dd'));

    if (this.isCreateMode && !this.info?.address?.city) {
      // TO DO - WTF?? WHY USING HERE sessionStorage???????
      this.form.get('city').setValue(sessionStorage.getItem("city"));
    }


    this.searchCountry.valueChanges.subscribe(value => {
      let searchText = value.toLowerCase();
      this.countries = this.ALlcountries.filter(it => {
        return it.name.toLowerCase().substring(0, searchText.length) == searchText;
      });
    });

    this.searchState.valueChanges.subscribe(value => {
      let searchText = value.toLowerCase();
      this.states = this.Allstates.filter(it => {
        return it.name.toLowerCase().substring(0, searchText.length) == searchText;
      });
    });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onSubmit(): void {
    if (this.form.invalid) {
      return;
    }
    this.info = this.model;
    this.dialogRef.close(this.info);
  }

  initializeForm(info: IPickupInfo): FormGroup {
    return this.formBuilder.group({
      name: [info?.name || '', [Validators.required]],
      pickupCode: [info?.pickupCode || ''],
      note: [info?.note || '',],
      telNo: [info?.telNo || '', [Validators.required, cellPhoneValidator()]],
      // phoneCode: [info?.countryPhoneCode || ''],
      agentContactDetails: [info?.agentContactDetails || ''],
      dueDate: [!!info?.dueDate ? new Date(info?.dueDate) : new Date(), [Validators.required]],
      state: [info?.address?.state || '', [Validators.required]],
      address: [info?.address?.name || '', [Validators.required]],
      zipCode: [info?.address?.zipCode || '', [Validators.required, Validators.minLength(formConstant.minZipCodeLength)]],
      country: [{ disabled: !!this.countryId, value: this.countryId || info?.address?.countryId }, [Validators.required]],
      city: [info?.address?.city || '', [Validators.required]],
    });
  }

  subcribeOnAvailableTypes(): void {
    this.constSvc.availableTypes.subscribe(data => {
      if (!data) return;
      this.availableTypes = data;
    });

    this.constSvc.getAvailableCountries().subscribe(data => {
      if (!data) return;
      this.countries = data;
      this.ALlcountries = data;
      if (this.countries) {
        const selectedCountry = this.countries.find(item => item.code == this.countryId || item.code === this.info?.address?.state?.country?.code);

        // TO DO - NEED TO CHANGE LOGIC FOR PHONE CODE
        if (!this.info?.telNo)
          this.form.get('telNo').setValue(selectedCountry ? selectedCountry?.phoneCode : selectedCountry?.phoneCode || this.form.get('telNo').value);

        this.setCities(selectedCountry);
      }
    });

    this.form.get('country').valueChanges.subscribe(value => {
      if (this.countries) {
        const selectedCountry = this.countries.find(item => item.code == value);
        this.setCities(selectedCountry);
        this.form.get('telNo').setValue(selectedCountry ? selectedCountry?.phoneCode : selectedCountry?.phoneCode || this.form.get('telNo').value);
      }


      // this.form.get('phoneCode').setValue(this.getPhoneCode(value));
      this.constSvc.getAvailableStatesById(value).subscribe(states => {
        if (!states) return;
        this.states = states;
        this.Allstates = states;

        let st = this.states.find(el => el.id == this.info?.address?.stateId)
        this.form.get('state').setValue(st ? st?.id : states[0]?.id || null);
      })
    })
  }

  getPhoneCode(country): string {
    const countryInfo = this.countries.find(el => el.code == country)
    return countryInfo ? countryInfo.phoneCode : '';
  }

  get model(): IPickupInfo {
    const countryInfo = this.countries.find(el => el.code == this.form.get('country').value);

    return {
      ...this.info,
      name: this.form.get('name').value,
      pickupCode: this.form.get('pickupCode').value,
      note: this.form.get('note').value,
      // countryPhoneCode: this.form.get('phoneCode').value,
      telNo: this.form.get('telNo').value,
      agentContactDetails: this.form.get('agentContactDetails').value,
      dueDate: this.form.get('dueDate').value != "Invalid Date" ? this.datePipe.transform(this.form.get('dueDate').value, 'yyy-MM-dd') : '',
      address: {
        stateId: this.form.get('state').value,
        name: this.form.get('address').value,
        zipCode: this.form.get('zipCode').value,
        city: this.form.get('city').value,
        countryId: countryInfo.id
      }
    }
  }

  get isCountryChoosed(): boolean {
    return this.form.get('country').value !== null;
  }

  isDatePickerValid(item: string): boolean {
    if (!item) return;
    const el = this.form.get(item);
    return el.value !== null;
  }

  setCities(selectedCountry: any): void {
    if (!selectedCountry) return;
    this.citySvc.getAllByCountryId(selectedCountry?.id).subscribe(cities => {
      if (!cities) return;
      this.cities = cities;
      if (!cities) {
        this.form.get('city').setValue(null);
        return;
      }
      let st = this.cities.find(el => el.city === this.info?.address?.city)
      this.form.get('city').setValue(st ? st.city : cities[0].city || null);
    });
  }

  addNewCity(countryCode: string): void {
    if (!countryCode) {
        return;
    }
    let countryId = this.countries.find(el => el.code === countryCode).id;
    let dialogRef = this.dialog.open(AddCityPopup);

    dialogRef.afterClosed().subscribe(val => {
        if (!val) {
            return undefined;
        }

        this.citySvc.create(countryId, val).subscribe(res => {
            this.notify.showSuccessMessage('City created successfully');
            this.form.controls.cityName.setValue(val);
            this.setCities(countryId);
        });
    });
  }
}
