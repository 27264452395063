import { Component, Input, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { UserService } from '../../../core/services/user.service';

@Component({
  selector: 'final-purchase-invoice-edit-form.component',
  templateUrl: './final-purchase-invoice-edit-form.component.html',
  styleUrls: ['./final-purchase-invoice-edit-form.component.scss']
})
export class FinalPurchaseInvoiceEditForm implements OnInit {

  @Input() data;
  @Input() customerCompany;
  shippingDetails: any = 
    {
        tableDetails: [
        {
            index: 1,
            description: "Destination Airport Charges",
            units: 1,
            price: 206,
            discount: 10,
            total: 140
        },
        {
            index: 2,
            description: "Surcharge",
            units: 1,
            price: 300,
            discount: 10,
            total: 270
        },
        {
            index: 3,
            description: "ECI Transaction Custom Clearance Fee",
            units: 1,
            price: 40,
            discount: 10,
            total: 36
        }
    ],
    subTotal: 491,
    grandTotal: 308
  }

  constructor(
    public dialogRef: MatDialogRef<FinalPurchaseInvoiceEditForm>,
    public userSvc: UserService
  ) {

  }

  ngOnInit(): void {
    if (this.data) {
      this.data.shippingDetails = this?.shippingDetails;
    }
  }

  onSubmit(): void {
    // debugger;
    this.dialogRef.close(this.data);
  }
}
