export enum ShipmentConsolDocumentType {
    MasterAWB,
    ExportInvoice,
    PackingList,
    ShippingManifest,
    SignedPod,
    CMR,
    CustomDeclaration,
    ConsolManifest
}
