import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';
import { IWarehouseInfo } from 'src/app/modules/customer-management/models/warehouse';
import { IAgreement, ICompany } from 'src/app/modules/customer-registration/models/company.model';
import { environment } from 'src/environments/environment';
import { IAvailableTypes } from '../../models/available-types.interface';
import { ICountry } from '../../models/country.interface';
import { ConstantService } from '../../services/constant.service';
import { NotificationService } from '../../services/notification.service';
import { UploadFileService } from '../../services/upload-file.service';

const countriesListEurope = [
  { name: 'Austria', checked: false },
  { name: 'Belgium', checked: false },
  { name: 'Bulgaria', checked: false },
  { name: 'Cyprus', checked: false },
  { name: 'Denmark', checked: false },
  { name: 'Germany', checked: false },
  { name: 'Estonia', checked: false },
  { name: 'Finland', checked: false },
  { name: 'France', checked: false },
  { name: 'Greece', checked: false },
  { name: 'United Kingdom (before Brexit)', checked: false },
  { name: 'Hungary', checked: false },
  { name: 'Ireland', checked: false },
  { name: 'Italy', checked: false },
  { name: 'Croatia', checked: false },
  { name: 'Latvia', checked: false },
  { name: 'Lithuania', checked: false },
  { name: 'Luxembourg', checked: false },
  { name: 'Malta', checked: false },
  { name: 'Netherlands', checked: false },
  { name: 'Norway', checked: false },
  { name: 'Poland', checked: false },
  { name: 'Portugal', checked: false },
  { name: 'Romania', checked: false },
  { name: 'Slovenia', checked: false },
  { name: 'Spain', checked: false },
  { name: 'Czeck Republik', checked: false },
  { name: 'Sweden', checked: false },
]

const countriesListOutsideEurope = [
//   { name: 'Switzerland', checked: false },
  { name: 'After Brexit: United Kingdom', checked: false }
]

@Component({
  selector: 'eci-agreements',
  templateUrl: './agreements.component.html',
  styleUrls: ['./agreements.component.scss'],
  providers: [DatePipe]
})
export class AgreementsComponent implements OnInit {

  form: FormGroup;

  countries: number[] = [];
  signUrl: any;

  showLoader: boolean = false;

  info: ICompany;
  availableCountries: ICountry[];
  availableTypes: IAvailableTypes;

  isAllSelected: boolean = false;

  agreement: IAgreement;

  warehouses: IWarehouseInfo[];

  clear: boolean = false;
  draw: boolean = false;

  countriesListEurope = countriesListEurope;
  countriesListOutsideEurope = countriesListOutsideEurope;

  fullFileName: string;

  constructor(
    public dialogRef: MatDialogRef<AgreementsComponent>,
    private formBuilder: FormBuilder,
    private constSvc: ConstantService,
    private uploadFileSvc: UploadFileService,
    private datePipe: DatePipe,
    private notify: NotificationService
  ) { }

  ngOnInit(): void {
    this.countriesListEurope.forEach(el => el.checked = false);
    this.form = this.initializeForm(this.info);

    this.form.get('gender').valueChanges.subscribe(data => {
      this.form.get('name').setValue(`${data} ${this.form.get('nameRepresentative').value}`);
    });

    this.form.get('nameRepresentative').valueChanges.subscribe(data => {
      this.form.get('name').setValue(`${this.form.get('gender').value} ${data}`);
    });

    this.constSvc.availableTypes.subscribe(data => {
      this.availableTypes = data;
    });
    this.constSvc.availableCountries.subscribe(data => {
      this.availableCountries = data;
    });
  }

  confirm(): void {
    if (!this.signUrl) {
      this.notify.showError('Please sign agreements');
      return;
    }
    var blob = this.dataURItoBlob(this.signUrl); //Converts to blob using link above
    var form = new FormData();
    form.append("content", blob);

    this.uploadFileSvc.uploadFileFormData(form, 'uploads/agreements').subscribe(data => {
      const photo = JSON.parse(data);
      const file = photo['url'];
      this.fullFileName = `${file}`;
      this.dialogRef.close(this.model);
    });
  }

  initializeForm(info: ICompany): FormGroup {
    return this.formBuilder.group({
      gender: new FormControl('', [Validators.required, Validators.minLength(2)]),
      place: new FormControl(''),
      nameRepresentative: new FormControl('', [Validators.required, Validators.minLength(2)]),
      titleRepresentative: new FormControl('', [Validators.required, Validators.minLength(2)]),
      // currency: new FormControl('', [Validators.required]),
      name: new FormControl('', [Validators.required, Validators.minLength(2)]),
    });
  }

  get legalForm(): string {
    if (!this.availableTypes || !this.info) return;
    const type = this.availableTypes.CompanyType.find(el => el.id == this.info.type);
    return type?.name || 'error';
  }

  getCountry(id): string {
    if (!this.availableCountries || !this.availableCountries.find(el => el.code == id)) return '';
    return this.availableCountries.find(el => el.code == id).name;
  }

  onCountryChange(e, num): void {
    const index = this.countries.indexOf(num);

    if (index !== -1)
      this.countries.splice(index, 1);
    else this.countries.push(num);
  }

  isChecked(num): boolean {
    return !!this.countries.find(el => el == num);
  }

  selectAll(value): void {
    this.countriesListEurope.forEach(el => el.checked = value);
    this.isAllSelected = value;
  }

  changeStatusContry(country): void {
    country.checked = !country.checked;
  }


  // DEPRECATED/////////////////
  //Generate PDF
  generatePDF2(): void {
    // this.dialogRef.close(this.model);
    this.showLoader = true;
    let pdf;

    var HTML_Width = 500;
    var HTML_Height = 800;
    var top_left_margin = 15;
    var PDF_Width = HTML_Width + (top_left_margin * 2);
    var PDF_Height = 800;

    var elements = document.getElementsByClassName('addicon');
    while(elements.length > 0){
        elements[0].parentNode.removeChild(elements[0]);
    }

    for (let i = 1; i <= 8; i++) {
      html2canvas(document.getElementById(`exportToPdf${i}`), { allowTaint: true }).then(canvas => {
        var imgData = canvas.toDataURL("image/png", 1.0);
        if (i === 1) {
          pdf = new jsPDF('p', 'pt', [PDF_Width, PDF_Height]);
        } else pdf.addPage('600pt', 'portrait');

        switch (i) {
          case 3: pdf.addImage(imgData, 'JPG', top_left_margin, top_left_margin, HTML_Width, HTML_Height / 3); break;
          case 8: pdf.addImage(imgData, 'JPG', top_left_margin, top_left_margin, HTML_Width, 600); break;
          default: pdf.addImage(imgData, 'JPG', top_left_margin, top_left_margin, HTML_Width, HTML_Height);
        }
        if (i == 8) {
          this.uploadFileSvc.uploadFilePdf(pdf.output('blob'), 'uploads');
          this.uploadFileSvc.fileLoaded().subscribe(data => {
            if (!data) return;
            const photo = JSON.parse(data);
            pdf.save("Comissionary-Agreement.pdf", { returnPromise: true }).then(_ => {
              this.showLoader = false;
              const model = {
                ...this.info,
                agreement: {
                  id: photo.id
                }
              }
              this.dialogRef.close(model);
            });
          });
        }
      });
    }
  }
  /////////////////////////////////////////////

  get model(): any {
    return {
      sign: this.fullFileName, //"https://production.ecommin.zangula.net/uploads/assets/content-1626767938206-Screenshot_2021-07-20_at_93625_AM.png",
      info: {
        name: this.info?.name,
        address: {
          name: this.getCountry(this.info?.address?.state?.country?.code),
          country: this.getCountry(this.info?.address?.state?.country?.code),
          city: this.info?.address?.city
        }
      },
      designation: this.form.get('titleRepresentative').value,
      legalForm: this.legalForm,
      nameRepresentative: this.form.get('nameRepresentative').value,
      genderRepresentative: this.form.get('gender').value,
      dateNow: this.datePipe.transform(this.dateNow, 'short'),
      warehouses: this.warehouses?.map(warehouse => { return {
        name: (`${warehouse.warehouseId} / ${warehouse.name}`),
        address: {
          name: warehouse.address?.state?.name,
          country: warehouse.address?.state?.country?.name,
          city: warehouse.address?.city
        }
      }}),
      countries: {
        isEuropeanSelectAll: !this.countriesListEurope.find(el => !el.checked),
        europeanList: this.countriesListEurope,
        list: [...this.newCountryRows, ...this.countriesListOutsideEurope]
      }
    }
  }

  get dateNow(): any {
    return new Date();
  }

  newCountryRows: any[] = [];

  addRow(): void {
    this.newCountryRows.push({ name: '', checked: true });
  }

  get newCountryRowsLength(): number {
    return this.newCountryRows.length - 1;
  }

  changeClear(value): void {
    this.clear = value;
  }

  changeName(event, country): void {
    country.name = (document.getElementsByClassName(event)[0] as any)?.value;
  }

  dataURItoBlob(dataURI) {
    // convert base64/URLEncoded data component to raw binary data held in a string
    var byteString;
    if (dataURI?.split(',')[0]?.indexOf('base64') >= 0)
        byteString = atob(dataURI?.split(',')[1]);
    else
        byteString = unescape(dataURI?.split(',')[1]);

    // separate out the mime component
    var mimeString = dataURI?.split(',')[0]?.split(':')[1]?.split(';')[0];

    // write the bytes of the string to a typed array
    var ia = new Uint8Array(byteString.length);
    for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ia], {type:mimeString});
  }
}
