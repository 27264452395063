import { Pipe, PipeTransform } from "@angular/core";
import { ActivitySubscriptionRates } from "../enums/activity-subscription-rates";

@Pipe({ name: 'activityRangeTooltip' })
export class ActivityRangeTooltipPipe implements PipeTransform {
    transform(value: any) {
        switch (value) {
            case ActivitySubscriptionRates["Less than 10,000 Euro"]: return 'Note: It is deducted automatically by the system every month';
            case ActivitySubscriptionRates["Between 10,000 - 50,000 Euro"]: return 'Note: It is deducted automatically by the system every month';
            case ActivitySubscriptionRates["Between 50,000 - 100,000 Euro"]: return 'The 0.5% is calculated only from product price, not including VAT';
            case ActivitySubscriptionRates["Between 100,000 - 500,000 Euro"]: return 'Note: It is deducted automatically by the system every month';
            case ActivitySubscriptionRates["Between 500,000 - 1,000,000 Euro"]: return 'Note: It is deducted automatically by the system every month';
            case ActivitySubscriptionRates["Between 1,000,000 - 4,000,000 Euro"]: return 'Note: It is deducted automatically by the system every month';
            case ActivitySubscriptionRates["Between 4,000,000 - 10,000,000 Euro"]: return 'Note: It is deducted automatically by the system every month';
            case ActivitySubscriptionRates["10,000,000 Euro and above"]: return 'Note: It is deducted automatically by the system every month';
            default: return '';
        }
    }
}
