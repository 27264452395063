import { Pipe, PipeTransform } from "@angular/core";
import { ActivityTimeRage } from "../enums/activity-time-range.enum";

@Pipe({ name: 'activityRange' })
export class ActivityRangePipe implements PipeTransform {
    transform(value: any) {
        switch (value) {
            case ActivityTimeRage["Above 5 years"]: return 'Above 5 years';
            case ActivityTimeRage["Between 1-3 Years"]: return 'Between 1-3 Years';
            case ActivityTimeRage["Between 3-5 Years"]: return 'Between 3-5 Years';
            case ActivityTimeRage["Less than 6 month"]: return 'Less than 6 month';
            default: return ''
        }
    }
}