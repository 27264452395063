import { Injectable } from '@angular/core';
import { ApiService } from 'src/app/core/services/api.service';
import { HttpClient } from '@angular/common/http';
import { LocalStorageService } from 'src/app/core/services/local-storage.service';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class VbanAlertsService extends ApiService {

    constructor(
        public http: HttpClient,
        public storageBrowser: LocalStorageService
    ) {
        super(http, storageBrowser);
    }
    public totalAlertCount = 0;
    public amountAlertsWithoutDismissed$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
    public subject$: BehaviorSubject<number> = new BehaviorSubject<number>(null);
    getAll(page: number = 1, limit: number = 100, q: string = '', type: number[] = [], orderField: string = '', orderDirection: string = ''): Observable<any> {
        if (!type) type = [];
        return this.request({
            method: 'GET',
            path: `vban_alert`,
            query: {
                q: q,
                limit: limit,
                page: page,
                type: type,
                orderField: orderField,
                orderDirection: orderDirection
            }
        });
    }

    getWithoutDismissed(): Observable<any> {
        return this.request({
            method: 'GET',
            path: `vban_alert/get_without_dismissed`,
        }).pipe((res) => {
            res.subscribe((withoutDismissed) => this.amountAlertsWithoutDismissed$.next(withoutDismissed));
            return res;
        });
    }

    dismiss(id): Observable<any> {
        return this.request({
            path: `vban_alert/dismiss/${id}`,
            method: 'POST',
            body: {}
        }).pipe(map(value => {
            this.updateTotalAlerts();
            return value;
        }));
        // }).pipe(map(items => items));
    }

    dismissAndUpdate(body): Observable<any> {
        return this.request({
            path: `vban_alert/dismiss_update`,
            method: 'POST',
            body: body
        }).pipe(map(items => items));
    }

    getAlertsDataCount(myCount) {
        this.totalAlertCount = myCount;
        this.subject$.next(this.totalAlertCount);
    }

    totalAlerts(total: number) {
        this.subject$.next(total);
    }

    onTotalAlerts(): Observable<number> {
        return this.subject$.asObservable();
    }

    private updateTotalAlerts(): void {
        this.getWithoutDismissed().subscribe(value => {
            this.subject$.next(value);
        });
    }
}
