import {Component, OnInit} from '@angular/core';
import {InvoiceTemplateBase} from '../invoce-template-base';

@Component({
    selector: 'eci-sale-invoice-form',
    templateUrl: './sale-invoice-form.component.html',
    styleUrls: ['./sale-invoice-form.component.scss']
})
export class SaleInvoiceFormComponent extends InvoiceTemplateBase implements OnInit {

    ngOnInit(): void {
        this.model = {
            ...this.template,
            ...this.invoice,
            customerCompany: {
                ...this.template.customerCompany,
                ...this.invoice.customerCompany
            }
        };
    }

    // The invoice is calculated based on the sales price and needs to be updated according to the cost price
    public updateProductSalePrice(product): void {
        product.price = product.discount > 0 ?
            (product.costPriceUnit - (product.costPriceUnit / 100 * product.discount)) :
            product.costPriceUnit;
    }
}
