import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TwoDigitDecimalNumberDirective } from './two-digit-decimal-number.directive';

@NgModule({
    imports: [
        CommonModule,
    ],
    declarations: [
        TwoDigitDecimalNumberDirective,
    ],
    exports: [
        TwoDigitDecimalNumberDirective,
    ],
})
export class TwoDigitDecimalModule { }
