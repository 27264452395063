import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ApiService} from './api.service';

@Injectable()
export class AuthService extends ApiService {
    public login(loginData): Observable<any> {
        return this.requestEcommerce({
            path: 'auth/login',
            method: 'POST',
            body: loginData
        });
    }
}
