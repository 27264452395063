import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from '../../../core/services/api.service';

@Injectable()
export class OrderInvoicesService extends ApiService {

    regenerateShipmentInvoice(id): Observable<any> {
        return this.request({
            method: 'POST',
            path: `shipment-consol/regenerate-invoices/${id}`,
        });
    }
}