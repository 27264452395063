import { Pipe, PipeTransform } from '@angular/core';
import { ConsolShipmentInvoiceType } from '../enums/consol-shipment-invoice-type.enum';

@Pipe({ name: 'consolShipmentInvoiceType' })
export class ConsolShipmentInvoiceTypePipe implements PipeTransform {
    transform(value: number) {
        switch (Number(value)) {
            case ConsolShipmentInvoiceType.CONSOL_SHIPMENT_VAT_INVOICE: return 'VAT Invoice';
            case ConsolShipmentInvoiceType.SERVICE_INVOICE: return 'Service Invoice';
            case ConsolShipmentInvoiceType.SHIPPING_INVOICE: return 'Commercial Invoice';
            default: return 'Undefined';
        }
    }
}