export const formConstant = {
  textFieldMaxLength: 50,
  passwordMinLength: 8,
  mincontactableIdLength: 9,
  maxcontactableIdLength: 9,
  //  @todo Change after business requirement will be clear
  // Size in KB
  maxLogoSize: 100 * 1024,
  minZipCodeLength: 4,
  minTextLength: 2,
  IBANLength: 15,
  SWIFTLength: 8,
};
