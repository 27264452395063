import { Component, Input, OnInit } from '@angular/core';
import { IStep } from './../interfaces/step.interface';

@Component({
    selector: 'eci-stepper',
    templateUrl: './stepper.component.html',
    styleUrls: ['./stepper.component.scss']
})
export class StepperComponent implements OnInit {

    @Input() steps: IStep[] = [];


    get allStepsCompleted(): boolean {
        if (this.steps.length <= 0) {
            return false;
        } else {
            return !this.steps.find(el => !el.isCompleted);
        }
    }

    constructor() {
    }

    ngOnInit(): void {
    }

}
