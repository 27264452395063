import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
    selector: 'eci-categories',
    templateUrl: './categories.component.html',
    styleUrls: ['./categories.component.scss']
})
export class CategoriesComponent implements OnInit {

    @Output() onCategoryChange: EventEmitter<string> = new EventEmitter<string>();

    categories: string[] = ['laptop', 'desktop', 'audio', 'accessories', 'gaming', 'phones'];

    constructor() {
    }

    ngOnInit(): void {
    }

    /**
     * Capitalize the first letter of the input value
     */
    public capitalizeFirstLetter(value: string): string {
        return value.charAt(0)
            .toUpperCase() + value.slice(1);
    }
}
