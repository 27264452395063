import { Pipe, PipeTransform } from '@angular/core';
import { AuditLogLevel } from '../enums/audit-log-level.enum';

@Pipe({ name: 'registrationStatusIndVat' })
export class RegistrationStatusIndVatPipe implements PipeTransform {
    transform(value: number, isCompleted: boolean) {
        if (isCompleted) {
            return 'Completed'
        }
        switch (value) {
            case 0: return 'User Name & Password';
            case 1: return 'Main Details';
            case 2: return 'Business Details';
            case 3: return 'Online shop';
            case 4: return 'Summary';
            case 5: return 'Pay Registration fees';
            case 6: return 'Finish';
        }
    }
}
