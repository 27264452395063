import { DatePipe } from '@angular/common';
import { Component } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { NotificationService } from 'src/app/core/services/notification.service';

@Component({
  selector: 'eci-payments-gateway',
  templateUrl: './payments-gateway.component.html',
  styleUrls: ['./payments-gateway.component.scss'],
  providers: [DatePipe]
})
export class PaymentsGatewayComponent {

  header: string = "Payments Details";
  createMode: boolean = false;
  info: any;

  form: FormGroup;
  companyCode: number;

  constructor(
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<PaymentsGatewayComponent>,
    private notify: NotificationService,
  ) {
  }

  ngOnInit(): void {
    this.getInfo();
  }

  getInfo(): void {
    this.form = this.initializeForm(this.info);
  }

  initializeForm(info: any): FormGroup {
    return this.formBuilder.group({
      paymentGatewayCompany: new FormControl({ disabled: false, value: info?.paymentGatewayCompany}, [Validators.required]),
      paymentTransferCompany: new FormControl({ disabled: false, value: info?.paymentTransferCompany}, [Validators.required]),
    });
  }

  save(): void {
    if (this.form.invalid) {
      this.notify.showError('Not all fields are filled');
      this.form.markAllAsTouched();
      return;
    }
    this.dialogRef.close(this.prepareBody);
  }

  exit(): void {
    this.dialogRef.close();
  }

  get prepareBody(): any {
    return {
      ...this.info,
      ...this.form?.value,
    }
  }
}
