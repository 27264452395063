import { Pipe, PipeTransform } from '@angular/core';
import { VatOption } from '../enums/vat-option';

@Pipe({ name: 'vatOptionMode' })
export class VatOptionModePipe implements PipeTransform {
    transform(value: any) {
        switch (value) {
            case VatOption[VatOption.withVat]: return 'Quote With VAT';
            case VatOption[VatOption.withoutVat]: return 'Quote Without VAT';
        }
    }
}