import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';
import { ShipmentConsolDocumentType } from '../enums/shipment-consol-document-type.enum';

@Pipe({ name: 'shipmentConsolType' })
export class ShipmentConsoleTypePipe implements PipeTransform {
    transform(value: any) {
        switch (value) {
            case ShipmentConsolDocumentType.MasterAWB: return 'Master AWB';
            case ShipmentConsolDocumentType.ExportInvoice: return 'Export Invoice';
            case ShipmentConsolDocumentType.PackingList: return 'Packing List';
            case ShipmentConsolDocumentType.ShippingManifest: return 'Shipping Manifest';
            case ShipmentConsolDocumentType.CMR: return 'CMR';
            case ShipmentConsolDocumentType.SignedPod: return 'Signed Pod';
            case ShipmentConsolDocumentType.CustomDeclaration: return 'Custom Declaration';
            case ShipmentConsolDocumentType.ConsolManifest: return 'Consol Manifest';
            case ShipmentConsolDocumentType[ShipmentConsolDocumentType.MasterAWB]: return 'Master AWB';
            case ShipmentConsolDocumentType[ShipmentConsolDocumentType.ExportInvoice]: return 'Export Invoice';
            case ShipmentConsolDocumentType[ShipmentConsolDocumentType.PackingList]: return 'Packing List';
            case ShipmentConsolDocumentType[ShipmentConsolDocumentType.ShippingManifest]: return 'Shipping Manifest';
            case ShipmentConsolDocumentType[ShipmentConsolDocumentType.CMR]: return 'CMR';
            case ShipmentConsolDocumentType[ShipmentConsolDocumentType.SignedPod]: return 'Signed Pod';
            case ShipmentConsolDocumentType[ShipmentConsolDocumentType.CustomDeclaration]: return 'Custom Declaraton';
            case ShipmentConsolDocumentType[ShipmentConsolDocumentType.ConsolManifest]: return 'Consol Manifest';
        }
    }
}