export enum ShipmentConsolStatus {
    PAYMENT_PENDING = 1,
    PAYMENT_FAILED,
    PAID,
    IN_PROGRESS,
    CUSTOM_CLEARANCE_PROCESS,
    CUSTOMS_CLEARED,
    SHIPMENT_DELIVERED,
    CANCELED,
    DELIVERED,
}