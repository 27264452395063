import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable()
export class CouponsService extends ApiService {

    public couponFilters$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    private baseUrl = 'coupons'

    getAllCoupons(page: number = 1, limit: number = 100, q: string = ''): Observable<any> {
        return this.request({
            path: `${this.baseUrl}`,
            method: 'GET',
            query: {
                page: page,
                limit: limit,
                q: q
            }
        })
    }

    updateCouponsDetails(id, body): Observable<any> {
        return this.request({
            path: `${this.baseUrl}/${id}`,
            method: 'PUT',
            body: body
        })
    }

    createCoupons(body): Observable<any> {
        return this.request({
            path: `${this.baseUrl}`,
            method: 'POST',
            body: body
        })
    }

    applyCoupon(body): Observable<any> {
        return this.request({
            path: `${this.baseUrl}/apply-coupon`,
            method: 'POST',
            body: body
        })
    }

    assignCoupon(body): Observable<any> {
        return this.request({
            path: `${this.baseUrl}/assign-coupon`,
            method: 'POST',
            body: body
        })
    }

    delete(body: any): Observable<any> {
        return this.request({
            path: `${this.baseUrl}`,
            method: 'DELETE',
            body
        });
    }

    getCouponDetailsById(id: number): Observable<any> {
        return this.request({
            path: `${this.baseUrl}/${id}`,
            method: 'GET',
        });
    }

    getCouponHistory(id: number): Observable<any> {
        return this.request({
            path: `${this.baseUrl}/${id}/history`,
            method: 'GET',
        });
    }

    getCouponsByCouponGroupId(id: number, page: number = 1, limit: number = 100, q: string = ''): Observable<any> {
        return this.request({
            path: `coupon-groups/${id}/coupons`,
            method: 'GET',
            query: {
                page: page,
                limit: limit,
                q: q
            }
        });
    }
}