import {Component, OnInit} from '@angular/core';
import {ReportingToolService} from '../../../reporting-tool.service';


@Component({
    selector: 'eci-ioss-oss',
    templateUrl: './ioss-oss.component.html',
    styleUrls: ['./ioss-oss.component.scss']
})
export class IossOssComponent implements OnInit {

    constructor(
        public reportingToolService: ReportingToolService
    ) {}

    ngOnInit(): void {
        this.reportingToolService.selectedCompany = null;
        this.reportingToolService.selectedShop = null;
        this.reportingToolService.selectedCountry = null;
    }
}
