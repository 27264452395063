import { Pipe, PipeTransform } from "@angular/core";
import { PlaceStatus } from "../enums/place-status.enum";

@Pipe({ name: 'placeStatus' })
export class PlaceStatusPipe implements PipeTransform {
    transform(value: any) {
        switch (value) {
            case PlaceStatus.Confirmed: return 'Confirmed';
            case PlaceStatus.Failed: return 'Failed';
            case PlaceStatus.Pending: return 'Pending';
            default: return ''
        }
    }
}