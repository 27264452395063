import { DatePipe } from '@angular/common';
import { Component } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { NotificationService } from 'src/app/core/services/notification.service';
import { IPayoneerDetails } from 'src/app/modules/customer-management/models/payoneer-details';

@Component({
  selector: 'eci-payoneer-payouts',
  templateUrl: './payoneer-payout.component.html',
  styleUrls: ['./payoneer-payout.component.scss'],
  providers: [DatePipe]
})
export class PayoneerPayoutComponent {

  header: string = "Payoneer Payout Details";
  createMode: boolean = false;
  info: any;
  payeeId: string = '';
  clientReferenceId: string = '';
  minimumPayoutAmount: number = 0;
  shopList: any = [];
  shopId: string = '';

  form: FormGroup;
  isDialog: boolean = true;

  companyCode: number;

  constructor(
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<PayoneerPayoutComponent>,
    private notify: NotificationService,
  ) {
  }

  ngOnInit(): void {
    this.getInfo();
  }

  getInfo(): void {
    this.form = this.initializeForm(this.info);
  }

  initializeForm(info: IPayoneerDetails): FormGroup {
    return this.formBuilder.group({
      clientReferenceId: new FormControl({ disabled: false, value: this.clientReferenceId}, [Validators.required]),
      payeeId: new FormControl({ disabled: false, value: this.payeeId}, [Validators.required]),
      currency: new FormControl({ disabled: false, value: this.info.payout_method.currency}, [Validators.required]),
      amount: new FormControl({ disabled: false, value: 0}, [Validators.required, Validators.min(this.minimumPayoutAmount)]),
      description: new FormControl({ disabled: false, value: ''}),
      shopName: new FormControl({ disabled: false, value: ''}, [Validators.required]),
    });
  }

  save(): void {
    if (this.form.invalid) {
      this.notify.showError('Not all fields are filled');
      this.form.markAllAsTouched();
      return;
    }
    this.dialogRef.close(this.prepareBody);
  }

  exit(): void {
    if (!this.isDialog) {
      history.back();
    } else this.dialogRef.close();
  }

  changeShop(event):void {
    const selectedShopData = this.shopList.filter( it => { 
      return it.shopName === event; 
    });
    this.shopId = selectedShopData[0]?.shopId || '';
  }

  get prepareBody(): IPayoneerDetails {
    return {
      // ...this.info,
      // ...this.form?.value,
      client_reference_id: this.form.get('clientReferenceId').value,
      payee_id: this.form.get('payeeId').value,
      description: this.form.get('description').value,
      currency: this.form.get('currency').value,
      amount: this.form.get('amount').value,
      shopName: this.form.get('shopName').value,
      shopId: this.shopId,
    }
  }

  setValidators(): void {
    this.form.controls.clientReferenceId.setValidators([Validators.required]);
    this.form.controls.payeeId.setValidators([Validators.required]);
    this.form.controls.description.setValidators([Validators.required]);
    this.form.controls.currency.setValidators([Validators.required]);
    this.form.controls.shopName.setValidators([Validators.required]);
    this.form.controls.amount.setValidators([Validators.required, Validators.min(this.minimumPayoutAmount)]);
  }
}
