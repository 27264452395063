import { Pipe, PipeTransform } from '@angular/core';
import { CronJobType } from '../enums/cron-job-type';

@Pipe({ name: 'cronJobType' })
export class CronJobTypePipe implements PipeTransform {
    transform(value: any) {
        switch (value) {
            case CronJobType.Daily: return 'Daily';
            case CronJobType.EveryFewMinutes: return 'Every few minutes';
            case CronJobType.Hourly: return 'Hourly';
            case CronJobType.Minute: return 'Every minute';
            case CronJobType.Monthly: return 'Monthly';
            case CronJobType.Weekly: return 'Weekly';
            default: return 'None';
        }
    }
}
