import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { Router } from '@angular/router';
import { IUserInfo } from './../models/user-info.model';
import { LocalStorageService } from './local-storage.service';
import { ApiService } from './api.service';
import { Roles } from 'src/app/shared/enums/role-types.enum';

@Injectable({
    providedIn: 'root',
})
export class UserService extends ApiService {
    user$: BehaviorSubject<IUserInfo> = new BehaviorSubject<IUserInfo>(undefined);

    isTestUserRegistration$ = new BehaviorSubject<boolean>(undefined);

    private _isSaleOrderConsolPopupShowed$ = new BehaviorSubject<boolean>(true);

    get isSaleOrderConsolPopupShowed(): boolean {
        return this._isSaleOrderConsolPopupShowed$.getValue();
    }

    set isSaleOrderConsolPopupShowed(value: boolean) {
        this._isSaleOrderConsolPopupShowed$.next(value);
    }

    get userId(): number {
        const user = this.user$.getValue();
        return user && user.id;
    }

    constructor(
        public http: HttpClient,
        private router: Router,
        private storageService: LocalStorageService,
    ) {
        super(http, storageService);
    }

    public get isDelicated(): boolean {
        if (!this.userStorage) return false;
        return this.userStorage.role == Roles.DelicateAdmin || this.userStorage.role == Roles.DelicateCustomer;
    }

    get userStorage(): any {
        return this.storageService.get('user');
    }

    get modeStorage(): any {
        return this.storageService.get('mode');
    }

    get user(): any {
        return this.user$.getValue();
    }

    get companyName(): string {
        return this.storageBrowser.get('user').company.name;
    }

    get company(): any {
        return this.storageBrowser.get('user').company;
    }

    get isAdmin(): boolean {
        if (!this.userStorage) return;
        return this.userStorage.role === Roles.Admin;
    }

    get isIndVat(): boolean {
        if (!this.userStorage) return;
        return this.userStorage.role === Roles.IndVatAccount;
    }

    get isOfflineCustomer(): boolean {
        if (!this.userStorage) return;
        return this.userStorage.role === Roles.OfflineShopOwner;
    }

    get isDelegatedAdmin(): boolean {
        if (!this.userStorage) return;
        return this.userStorage.role === Roles.DelicateAdmin;
    }

    // getUserInfo(): Observable<IUserInfo> {
    //     const res = this.http.get<any>(`${environment.api_url}/Admin/User/LoginInfo`).pipe(
    //         map(res => res.Body),
    //         switchMap((userInfo: IUserInfo) => {
    //             this.user$.next(userInfo);
    //             return of(userInfo);
    //         }),
    //         tap((res) =>
    //         {
    //             this.userTypesSvc.getUserTypes().toPromise().then(userTypes => {
    //                 this.userAccService.getUserDetails(res.UserID, true).toPromise().then(userDetails => {
    //                     this.userAccService.setUserPermissions(res, userTypes, userDetails);
    //                     this.getUserFormats(userTypes, userDetails);
    //                 })
    //             });
    //         }),
    //     );
    //     return res;
    // }

    logOut(): void { //Promise<any> {
        this.resetAfterLogout();
        // return this.http.post<any>(`${environment.api_url}/api/Project/LogOut`, {}).toPromise()
        //     .then(() => this.resetAfterLogout())
        //     .catch(() => this.resetAfterLogout());
    }

    resetAfterLogout(): void {
        this.storageService.clear();
        sessionStorage.clear();
        this.user$.next(null);
        this.router.navigate(['/auth/login']);
    }

    updateAccess(id: number, isEnabled: boolean): Observable<any> {
        return this.request({
            method: 'POST',
            path: `users/updateAccess`,
            body: {
                id: id,
                isEnabled: isEnabled
            }
        });
    }


    updateStripeEnvironmentSetting(id: number, stripeEnvironmentSetting: boolean): Observable<any> {
        return this.request({
            method: 'POST',
            path: `users/updateStripeEnvironmentSetting`,
            body: {
                id: id,
                stripeEnvironmentSetting: stripeEnvironmentSetting
            }
        });
    }

    updateRecurringPaymentStatus(companyId: number, isEnabled: boolean): Observable<any> {
        return this.request({
            method: 'POST',
            path: `company/update-recurring-payment-status`,
            body: {
                companyId,
                isEnabled
            }
        });
    }

    updateMarketplaceStatus(companyId: number, isEnabled: boolean): Observable<any> {
        return this.request({
            method: 'POST',
            path: `company/update-marketplace-status`,
            body: {
                companyId,
                isEnabled
            }
        });
    }

    updateModeStatus(companyId: number, isLiveMode: boolean): Observable<any> {
        return this.request({
            method: 'POST',
            path: `company/update-mode-status`,
            body: {
                companyId,
                isLiveMode
            }
        });
    }


    getCompanyWithCurrentUser(userId: number): Observable<any> {
        return this.request({
            method: 'POST',
            path: `users/company`,
            body: {
                id: userId,
            }
        });
    }

    updateUser(id: number, body): Observable<any> {
        return this.request({
            method: 'PUT',
            path: `users/${id}`,
            body: body
        });
    }

    updateUserSelf(body): Observable<any> {
        return this.request({
            method: 'PUT',
            path: `users`,
            body: body
        });
    }

    isPasswordValid(body): Observable<any> {
        return this.request({
            method: 'POST',
            path: `users/password/valid`,
            body: body
        });
    }

    getPackageTypeByCompanyId(companyId:number): Observable<any> {
        return this.request({
            method:'GET',
            path:`users/package-type/${companyId}`
        })
    }

    getAll(page: number = 1, limit: number = 100, q: string = '', orderField: string = '', orderDirection: string = ''): Observable<any> {
        return this.request({
            method: 'GET',
            path: `users`,
            query: {
                q: q,
                limit: limit,
                page: page,
                orderField: orderField,
                orderDirection: orderDirection
            }
        });
    }

    getAllIndVat(
        page: number = 1,
        limit: number = 100,
        q: string = '',
        showIncompleted: boolean = false,
        orderField: string = '',
        orderDirection: string = '',
        dateFrom: string = '',
        dateTo: string = ''
    ): Observable<any> {
        return this.request({
            method: 'GET',
            path: `users/indvat`,
            query: {
                page: page,
                limit: limit,
                showIncompleted,
                q: q,
                orderField: orderField,
                orderDirection: orderDirection,
                dateFrom,
                dateTo
            }
        });
    }

    register(body: any): Observable<any> {
        return this.requestFormData({
            method: 'POST',
            path: `users/registration`,
            body
        });
    }

    createPackage(body: any): Observable<any> {
        return this.requestFormData({
            method: 'POST',
            path: `packages`,
            body
        });
    }

    emailCheck(email: string): Observable<any> {
        return this.request({
            method: 'POST',
            path: `users/check-registration-email/${email}`
        });
    }

    updateComissionaryAgreements(body: any): Observable<any> {
        return this.requestFormData({
            method: 'POST',
            path: `users/update-comissionary-agreements`,
            body
        });
    }

    resendRegistrationLink(id: any): Observable<any> {
        return this.request({
            method: 'POST',
            path: `users/resend-registration-email/${id}`,
        });
    }

    deleteUser(id: any): Observable<any> {
        return this.request({
            method: 'DELETE',
            path: `users/deleteInCompleteUser/${id}`,
        });
    }

    downloadTaxInstructions(): Observable<any> {
        const headers = new HttpHeaders();
        headers.append('Accept', '*')
            .append('responseType', 'application/octet-stream');

        const fileUrl = `/assets/docx/empact.Platfrom.Onboarding.Instructions.docx`;

        return this.http.get(fileUrl, { headers: headers, responseType: 'blob' });
    }

    validateTestUserPwd(password: string): Observable<boolean> {
        return this.request({
            method: 'POST',
            path: `users/validate-test-user-password`,
            body: {
                password
            }
        });
    }

    updateWorkingMode(workingMode: boolean): Observable<boolean> {
        return this.request({
            method: 'POST',
            path: `users/update-working-mode`,
            body: {
                workingMode
            }
        });
    }
}
