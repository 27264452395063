import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { FormGroup , FormControl } from '@angular/forms';
import {LocalStorageService} from './../../../core/services/local-storage.service'
import { environment } from './../../../../environments/environment';
const IS_LIVE_MODE = environment.is_live_mode;

@Component({
  selector: 'eci-mode-select-popup',
  templateUrl: './mode-select-popup.component.html',
  styleUrls: ['./mode-select-popup.component.scss']
})
export class ModeSelectPopupComponent implements OnInit {
  selectedMode: boolean;  
  modeEditForm: FormGroup;
  public toggleForm:boolean;

  constructor(public dialogRef: MatDialogRef<ModeSelectPopupComponent>,
    public storageBrowser: LocalStorageService) { }

  ngOnInit(): void {
    this.modeEditForm = new FormGroup({          
      'completed': new FormControl()
      });    

    this.selectedMode = (this.storageBrowser.get('mode') === null) ? IS_LIVE_MODE : this.storageBrowser.get('mode');
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
  
  onOkClick(): void {
    this.dialogRef.close({'mode' : this.selectedMode});
  }

}
