import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { AuthType } from './../core/enums/auth-type.enum';

@Component({
  selector: 'ecommerce-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss']
})
export class AuthComponent implements OnInit {

  contentHeader: string;
  contentText: string;
  type: AuthType = AuthType.Login;
  AuthType = AuthType;
  token: string;

  constructor(
    private dialogRef: MatDialogRef<AuthComponent>
  ) { }

  ngOnInit(): void {
    this.setContent(this.type);
  }

  setContent(type: AuthType): void {
    switch (type) {
      case AuthType.Registration: {
        this.contentHeader = 'Looks like you are new here!';
        this.contentText = 'Lorem ipsum dolor sit amet, consectetur adipiscing elit';
      } break;
      case AuthType.Login: {
        this.contentHeader = 'Login';
        this.contentText = 'Lorem ipsum dolor sit amet, consectetur adipiscing elit';
      } break;
      case AuthType.ForgotPassword: {
        this.contentHeader = 'Forgot Password?';
        this.contentText = 'Lorem ipsum dolor sit amet, consectetur adipiscing elit';
      } break;
      case AuthType.RestorePassword: {
        this.contentHeader = 'Restore Password';
        this.contentText = 'Lorem ipsum dolor sit amet, consectetur adipiscing elit';
      } break;
    }
  }

  updateType(newType: AuthType): void {
    this.type = newType;
    this.setContent(newType);
  }

  close(): void {
    this.dialogRef.close();
  }
}
