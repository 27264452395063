export enum PaymentProcessors {
    PayPal,
    Due,
    Stripe,
    Payoneer,
    "Flagship Merchant Services",
    "Payline Data",
    Square,
    Rapyd,
    Other
}
