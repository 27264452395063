import { Component, Input, OnInit } from '@angular/core';
import { IProductFilterProp } from '../../core/models/product.interface';

@Component({
    selector: 'eci-filter',
    templateUrl: './filter.component.html',
    styleUrls: ['./filter.component.scss']
})
export class FilterComponent implements OnInit {
    @Input() isDisplay: boolean = false;
    public filter: object = {};
    public filterOptions: any[] = [
        {
            title: 'Brand',
            property: [
                {name: 'Dell'},
                {name: 'HP'},
                {name: 'Acer'},
                {name: 'Apple'},
                {name: 'MSI'},
                {name: 'Asus'},
                {name: 'Lenovo'},
            ]
        },
        {
            title: 'Price',
            property: [
                {name: '$1000 or more (24)', min: 1000},
                {name: '$500 - $1000 (14)', min: 500, max: 1000},
                {name: '$100 - $500 (12)', min: 100, max: 500},
                {name: '$100 or less (4)', min: 0, max: 100},
            ]
        },
        {
            title: 'Processor',
            property: [
                {name: 'All Intel (51)'},
                {name: 'All AMD Processors (28)'},
                {name: 'Intel Core i9 (1)'},
                {name: 'Intel Core i7 (14)'},
                {name: 'Intel Core i5 (23)'},
            ]
        },
        {
            title: 'Product Line',
            property: [
                {name: 'Inspiron (45)'},
                {name: 'Vostro (15)'},
                {name: 'XPS (3)'},
                {name: 'G Series (8)'},
                {name: 'Alienware (8)'},
            ]
        },
        {
            title: 'Screen Size',
            property: [
                {name: '43.18 cm (17 inch) (1)'},
                {name: '40.60 cm (16 inch) (4)'},
                {name: '38.10 cm (15 inch) (49)'},
                {name: '35.56 cm (14 inch) (19)'},
                {name: '33.02 cm (13 inch) (6)'},
            ]
        }
    ];

    constructor() {
    }

    ngOnInit(): void {
    }

    onFilter(filterKey: string, value: string): void {
       this.filter[filterKey] = value;
    }

    isChecked(prop: IProductFilterProp, filterKey: string): boolean {
        return (this.filter.hasOwnProperty(filterKey)) ? prop.name === this.filter[filterKey] : false;
    }
}
