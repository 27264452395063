import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'eci-banner',
    templateUrl: './banner.component.html',
    styleUrls: ['./banner.component.scss']
})
export class BannerComponent implements OnInit {
    @Input() header: string = '';
    @Input() text: string = '';
    @Input() hidden: boolean = true;

    get isHome(): boolean {
        return this.router.url.includes('/home-page');
    }

    constructor(
        public router: Router,
        ) {
    }

    ngOnInit(): void {
    }

}
