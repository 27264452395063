export class PageParams {
    pageSize: number = 100;
    length: number = 0;
    availableSizes = [
        5,
        10,
        25,
        100
    ];
    pageIndex: number = 0;
}