import {Component, OnInit} from '@angular/core';
import {PageParams} from 'src/app/core/models/page-params.model';
import {MatDialog} from '@angular/material/dialog';
import {UserService} from 'src/app/core/services/user.service';
import {FormControl} from '@angular/forms';
import {IAvailableTypes, IConstantItem} from 'src/app/core/models/available-types.interface';
import {ConstantService} from 'src/app/core/services/constant.service';
import {DatePipe} from '@angular/common';
import {Sort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {NotificationService} from '../../../core/services/notification.service';
import {ConfirmPopupDialog} from '../../../shared/popups/confirm-popup/confirm-popup.component';
import {IOrderListItem} from '../../sales-order-management/models/order-list-item';
import {debounceTime} from 'rxjs/operators';
import {ISaleOrder} from '../../sales-order-management/models/sale-order.interface';
import {ConsolidateShipmentsDetailsComponent} from './consolidate-shipments-details/consolidate-shipments-details.component';
import {Router} from '@angular/router';
import {ConsolidateShipmentService} from '../../../core/services/consolidate-shipments.service';
import { BehaviorSubject } from 'rxjs';
import { SOTypeConsolSelectPopupPopupComponent } from './so-type-consol-select-popup/so-type-consol-select-popup.component';
import { RegistrationService } from '../../customer-registration/services/registration.service';

@Component({
    selector: 'eci-consolidate-shipments-management',
    templateUrl: './consolidate-shipments-management.component.html',
    styleUrls: ['./consolidate-shipments-management.component.scss'],
    providers: [DatePipe]
})
export class ConsolidateShipmentsManagementComponent implements OnInit {

    displayedColumns: string[] = [
        'dateCreated',
        'consolId',
        'shippingDate',
        'destination',
        'shipped',
        'delivered',
        'consolidateWeight',
        'mawb',
        'deliveryDate',
        'status',
    ];
    displayedColumnsAdmin: string[] = [
        'checkboxes',
        'dateCreated',
        'handled',
        'consolId',
        'customer',
        'shippingDate',
        'destination',
        'shipped',
        'delivered',
        'consolidateWeight',
        'mawb',
        'deliveryDate',
        'status',
    ];
    dataSource;
    selectedCountry: number = 1;
    public amountNotHandledConsolShipment$: BehaviorSubject<number> = new BehaviorSubject<number>(0);

    pageParams: PageParams = new PageParams();
    availableTypes: IAvailableTypes;

    ordersList: IOrderListItem[];

    showLoader: boolean = false;

    //#region Search params

    fromData: FormControl = new FormControl('');
    toData: FormControl = new FormControl('');
    searchStr: FormControl = new FormControl('');
    orderType: FormControl = new FormControl(null);
    orderStatus: FormControl = new FormControl(null);
    handleStatus: FormControl = new FormControl('All');
    customerType: FormControl = new FormControl(null);
    searchCustomerName: FormControl = new FormControl('');
    customerName: FormControl = new FormControl('');
    currentSort: Sort;
    searchorderType: FormControl = new FormControl('');
    searchorderStatus: FormControl = new FormControl('');
    searchcustomerType: FormControl = new FormControl('');
    deliveryFromDate: FormControl = new FormControl('');
    deliveryToDate: FormControl = new FormControl('');

    listOfSelectedItems: any[] = [];
    companyList: any[];
    previousePageCustomer: number = 0;

    //#endregion

    pageChanged(e: PageParams): void {
        this.pageParams.pageIndex = e.pageIndex;
        this.pageParams.pageSize = e.pageSize;
        this.userSvc.isAdmin || this.userSvc.isDelegatedAdmin ? this.getList(e.pageIndex + 1, e.pageSize, this.searchStr.value) : this.getList(e.pageIndex + 1, e.pageSize, this.searchStr.value, this.userSvc.company.id);

        // this.getList(e.pageIndex + 1, this.userSvc.company.id);
    }

    constructor(
        private dialog: MatDialog,
        public userSvc: UserService,
        private constSvc: ConstantService,
        private datePipe: DatePipe,
        private router: Router,
        private consolShipmentSvc: ConsolidateShipmentService,
        private notify: NotificationService,
        private regSvc: RegistrationService
    ) {
    }

    get orderTypes(): IConstantItem[] {
        if (!this.availableTypes) {
            return;
        }
        return this.availableTypes.OrderType;
    }

    get orderStatuses(): IConstantItem[] {
        if (!this.availableTypes) {
            return;
        }
        return this.availableTypes.OrderStatus;
    }

    get companyType(): IConstantItem[] {
        if (!this.availableTypes) {
            return;
        }
        return this.availableTypes.CompanyType;
    }

    ngOnInit(): void {
        if (this.userSvc.isAdmin || this.userSvc.isDelegatedAdmin) {
            this.getList(1, 100, '');
        } else {
            this.handleStatus.setValue(null);
            this.getList(1, 100, '', this.userSvc.company.id);
        }
        this.constSvc.availableTypes.subscribe(data => {
            this.availableTypes = data;
        });
        // this.constSvc.getAvailableTypes();

        this.searchStr.valueChanges.pipe(debounceTime(500)).subscribe(value => {
            this.search();
        });

        this.getListCompanies();
        this.searchCustomerName.valueChanges.pipe(debounceTime(500)).subscribe(value => {
            this.previousePageCustomer = 0;
            this.getListCompanies();
        });
    }
    getListCompanies(q: string = this.searchCustomerName.value): void {
        q = this.customerName.value ? this.searchCustomerName.value : q;
        this.regSvc.getCompanyListForSales(q).subscribe(data => {
            if (!data) {
                return;
            }
            this.companyList = data;
        });
    }
    clearSelection() {
        this.customerName.reset();
        this.getListCompanies(this.searchCustomerName.value);
    }
    getList(
        page: number = 1,
        limit: number = 100,
        q: string = this.searchStr.value,
        companyId: number = this.customerName.value,
        orderField: string = this.currentSort?.active || '',
        orderDir: string = this.currentSort?.direction.toUpperCase() || ''
    ): void {
        this.showLoader = true;
        this.consolShipmentSvc.getShipments(page, limit, q, companyId, this.prepareSearchParams, orderField, orderDir).subscribe(data => {
            this.showLoader = false;
            if (!data) {
                return;
            }
            this.dataSource = data.items;
            this.ordersList = data.items;
            this.pageParams.pageSize = data.meta.itemsPerPage;
            this.pageParams.pageIndex = data.meta.currentPage - 1;
            this.pageParams.length = data.meta.totalItems;
            this.consolShipmentSvc.getAmountConsolHandled(false).subscribe(count => { sessionStorage.setItem('consol_count', count); });

            // sessionStorage.setItem('consol_count', data.meta.totalItems);
        });
    }

    navigateToSaleOrders(): void {
        this.dialog.open(SOTypeConsolSelectPopupPopupComponent);
        // this.userSvc.isSaleOrderConsolPopupShowed = false;
        // if (this.userSvc.isIndVat) {
        //     this.router.navigateByUrl('/sales-order-management/indvat-sales-order-management');
        // } else {
        //     this.router.navigateByUrl('/sales-order-management');
        // }
    }

    search(): void {
        this.getList(1, 100, this.searchStr.value, this.userSvc.isAdmin || this.userSvc.isDelegatedAdmin ?  this.customerName.value : this.userSvc.company.id);
    }

    isDataNotCorrect(item: FormControl): boolean {
        return item.value === null;
    }

    get prepareSearchParams(): any {
        return {
            orderTypes: this.orderType.value ? this.orderType.value : '',
            companyTypes: this.customerType.value ? this.customerType.value : '',
            orderStatuses: this.orderStatus.value ? this.orderStatus.value : '',
            handleStatus: this.handleStatus.value !== null ? !!this.handleStatus.value : '',
            dateTo: this.toData.value && this.toData.value !== null ? this.datePipe.transform(this.toData.value, 'yyyy-MM-dd') : '',
            dateFrom: this.toData.value && this.fromData.value !== null ? this.datePipe.transform(this.fromData.value, 'yyyy-MM-dd') : '',
            deliveryDateTo: this.deliveryToDate.value && this.deliveryToDate.value !== null ? this.datePipe.transform(this.deliveryToDate.value, 'yyyy-MM-dd') : '',
            deliveryDateFrom: this.deliveryFromDate.value && this.deliveryFromDate.value !== null ? this.datePipe.transform(this.deliveryFromDate.value, 'yyyy-MM-dd') : '',
        };
    }

    sortData(sort: Sort): void {
        const data = this.ordersList.slice();
        if (!sort.active || sort.direction === '') {
            this.ordersList = data;
            this.dataSource = new MatTableDataSource<IOrderListItem>(this.ordersList);
            return;
        }

        this.currentSort = sort;
        this.getList(this.pageParams?.pageIndex + 1, this.pageParams?.pageSize,
            this.searchStr.value || '', this.userSvc.isAdmin || this.userSvc.isDelegatedAdmin ? '' : this.userSvc.company.id);
    }

    isAdmin() {
        return this.userSvc.isAdmin;
    }

    handleSwitch() {
        const ids = this.listOfSelectedItems.map(o => o.id);
        this.showLoader = true;
        this.consolShipmentSvc.switchHandled(ids).subscribe(data => {
            this.showLoader = false;
            this.listOfSelectedItems = [];
            this.getList();
            // this.consolShipmentSvc.getAmountConsolHandled(false).subscribe(count => { this.amountNotHandledConsolShipment$.next(count); });
        });
    }

    handleDelete() {
        const ids = this.listOfSelectedItems.map(o => o.id);
        if (ids && ids?.length > 0) {
            const dialogRef = this.dialog.open(ConfirmPopupDialog);
            dialogRef.componentInstance.message = 'Please confirm deleting';
            dialogRef.componentInstance.rightBtnText = 'Delete';
            dialogRef.afterClosed().subscribe(value => {
                if (!value) return;
                this.consolShipmentSvc.delete(ids).subscribe(() => {
                    this.notify.showSuccessMessage("Shipments deleted successfully");
                    this.listOfSelectedItems = [];
                    this.getList(1, 100, '');
                }, () => {
                    this.notify.showError("Something went wrong");
                })
            });
        } else {
            this.notify.showError("Please select any product!");
        }
    }
}
