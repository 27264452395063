import { Pipe, PipeTransform } from "@angular/core";
import { ActivitySubscriptionRates } from "../enums/activity-subscription-rates";

@Pipe({ name: 'activityRangeLabel' })
export class ActivityRangeLabelPipe implements PipeTransform {
    transform(value: any) {
        switch (value) {
            case ActivitySubscriptionRates["Less than 10,000 Euro"]: return 'Less than 10,000 Euro';
            case ActivitySubscriptionRates["Between 10,000 - 50,000 Euro"]: return 'Between 10,000 - 50,000 Euro';
            case ActivitySubscriptionRates["Between 50,000 - 100,000 Euro"]: return 'Between 50,000 - 100,000 Euro';
            case ActivitySubscriptionRates["Between 100,000 - 500,000 Euro"]: return 'Between 100,000 - 500,000 Euro';
            case ActivitySubscriptionRates["Between 500,000 - 1,000,000 Euro"]: return 'Between 500,000 - 1,000,000 Euro';
            case ActivitySubscriptionRates["Between 1,000,000 - 4,000,000 Euro"]: return 'Between 1,000,000 - 4,000,000 Euro';
            case ActivitySubscriptionRates["Between 4,000,000 - 10,000,000 Euro"]: return 'Between 4,000,000 - 10,000,000 Euro';
            case ActivitySubscriptionRates["10,000,000 Euro and above"]: return '10,000,000 Euro and above';
            default: return '';
        }
    }
}