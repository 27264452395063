import {Component, OnInit} from '@angular/core';
import {FormControl} from '@angular/forms';
import {MatDialog} from '@angular/material/dialog';
import { NotificationService } from '../../../core/services/notification.service';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { InvoiceManagerType } from 'src/app/shared/enums/invoice-manager-type.enum';
import {SaleOrderInvoiceType} from '../../../shared/enums/sale-order-invoice-type.enum';
import {InvoiceFormComponent} from '../components/invoice-forms/invoice-form.component';
import {IHistoryClose, InvoiceHistoryComponent} from '../components/invoice-history/invoice-history.component';
import {InvoiceManagementService} from '../invoice-management.service';

export interface IActionInvoice {
    action: 'view' | 'edit' | 'history';
    item: any;
}

export const INVOICE_TYPE_LIST = [
    { id: SaleOrderInvoiceType.IMPORT_INVOICE, name: 'Import Invoice', type: InvoiceManagerType.Shipment },
    { id: SaleOrderInvoiceType.DOMESTIC_INVOICE, name: 'Domestic Invoice', type: InvoiceManagerType.Shipment },
    { id: SaleOrderInvoiceType.EXPORT_INVOICE, name: 'Export Invoice', type: InvoiceManagerType.Shipment },
    { id: SaleOrderInvoiceType.COMMERCIAL_INVOICE, name: 'Commercial Invoice', type: InvoiceManagerType.SaleOrder },
    { id: SaleOrderInvoiceType.VAT, name: 'Vat Invoice', type: InvoiceManagerType.ConsolShipment },
    { id: SaleOrderInvoiceType.PROFORMA_INVOICE, name: 'Proforma Invoice', type: InvoiceManagerType.Shipment },
    { id: SaleOrderInvoiceType.SELL_INVOICE, name: 'Sell Invoice', type: InvoiceManagerType.SaleOrder },
    { id: SaleOrderInvoiceType.IMPORT_INVOICE_AIR, name: 'Service Invoice (IMP)', type: InvoiceManagerType.Shipment },
    { id: SaleOrderInvoiceType.EXPORT_INVOICE_AIR, name: 'Export Invoice Air', type: InvoiceManagerType.Shipment },
    { id: SaleOrderInvoiceType.DOMESTIC_INVOICE_AIR, name: 'Domestic Invoice Air', type: InvoiceManagerType.Shipment },
    { id: SaleOrderInvoiceType.MONTHLY_SUBSCRIPTION_PAYMENT, name: 'Monthly Subscription Payment Invoice', type: InvoiceManagerType.Other },
    { id: SaleOrderInvoiceType.REGISTRATION_FEES, name: 'Registration Fees Invoice', type: InvoiceManagerType.Other },
    { id: SaleOrderInvoiceType.CANCEL_NOTE, name: 'Cancel Note', type: InvoiceManagerType.SaleOrder },
    { id: SaleOrderInvoiceType.FINAL_PURCHASE_INVOICE, name: 'Final Purchase Invoice', type: InvoiceManagerType.Shipment },
    { id: SaleOrderInvoiceType.SHIPPING_INVOICE, name: 'Shipping Invoice', type: InvoiceManagerType.ConsolShipment },
    { id: SaleOrderInvoiceType.SERVICE_INVOICE, name: 'Service Invoice', type: InvoiceManagerType.ConsolShipment },
    { id: SaleOrderInvoiceType.CONSOL_SHIPMENT_VAT_INVOICE, name: 'Consolidate Shipment Vat Invoice', type: InvoiceManagerType.ConsolShipment },
];

@Component({
    selector: 'eci-editor',
    templateUrl: './editor.component.html',
    styleUrls: ['./editor.component.scss']
})
export class EditorComponent implements OnInit {

    showLoader: boolean = false;
    searchTemplateStr: FormControl = new FormControl('');
    displayedColumns: string[] = [
        'templateName',
        'actions',
    ];

    invoiceTypeList = [];
    invoiceTypeListViewed = [];

    selectedTab: number = 0;

    constructor(
        public invoiceManagementService: InvoiceManagementService,
        private dialog: MatDialog,
        private notify: NotificationService,
    ) {
    }

    ngOnInit(): void {
        this.invoiceTypeList= INVOICE_TYPE_LIST.sort((a, b) => {
            return a.name.localeCompare(b.name);
        });
        this.onTabChanged(0);
        this.searchTemplateStr.valueChanges.subscribe(val => {
            this.invoiceTypeListViewed = this.invoiceTypeList.filter(
                i => {
                    return i.name.toLowerCase().includes(val.replace(/^\s+/g, '').toLowerCase()) && i.type === this.selectedTab;
                }
            );
        });
    }

    onAction(e: IActionInvoice) {
        switch (e.action) {
            case 'view':
                this.invoiceManagementService.getTemplate(SaleOrderInvoiceType[e.item.id]);
                break;
            case 'edit':
                this.editTemplate(e.item.id);
                break;
            case 'history':
                this.showHistory(e.item.id);
                break;
        }
    }

    editTemplate(id: number, historyId?: string) {
        this.invoiceManagementService.getTemplateData(SaleOrderInvoiceType[id], historyId).subscribe(templateData => {
            const dialogRef = this.dialog.open(InvoiceFormComponent, {
                maxHeight: '80vh',
                width: '890px',
                panelClass: 'inv-man-dialog'
            });
            dialogRef.componentInstance.type = id;
            dialogRef.componentInstance.invoice = {};
            dialogRef.componentInstance.template = {note: templateData.data?.note, ...templateData.data?.template};
            dialogRef.componentInstance.isInvoice = false;

            dialogRef.afterClosed().subscribe(value => {
                if (!value) return;
                this.invoiceManagementService.setTemplateData(SaleOrderInvoiceType[id], value, historyId).subscribe(res => {
                    this.notify.showSuccessMessage('Update successfully');
                });
            })
        });
    }

    private showHistory(id: number) {
        this.invoiceManagementService.getTemplateHistory(SaleOrderInvoiceType[id]).subscribe(history => {
            const dialogRef = this.dialog.open<InvoiceHistoryComponent, any, IHistoryClose>(InvoiceHistoryComponent, {
                maxHeight: '80vh',
                width: '890px',
                panelClass: 'inv-man-dialog'
            });

            dialogRef.componentInstance.history = history;
            dialogRef.componentInstance.invoiceName = (INVOICE_TYPE_LIST.find(tl => tl.id === id)).name;
            dialogRef.afterClosed().subscribe(value => {
                this.historyAction(value, id);
            })
        });
    }

    private historyAction(value: IHistoryClose, id: number) {
        if (!value) return;
        switch (value.event) {
            case 'active':
                this.invoiceManagementService.setTemplateHistoryActive(SaleOrderInvoiceType[id], value.data.id)
                    .subscribe(res => {
                        this.notify.showSuccessMessage('Update successfully');
                    });
                break;
            case 'view':
                this.invoiceManagementService.getTemplate(SaleOrderInvoiceType[id], value.data.id);
                break;
            case 'edit':
                this.editTemplate(id, value.data.id);
                break;
        }
    }

    onTabChanged(index: number): void {
        switch (index) {
            case 0: this.invoiceTypeListViewed = this.invoiceTypeList.filter(val => val.type === InvoiceManagerType.SaleOrder); break;
            case 1: this.invoiceTypeListViewed = this.invoiceTypeList.filter(val => val.type === InvoiceManagerType.Shipment); break;
            case 2: this.invoiceTypeListViewed = this.invoiceTypeList.filter(val => val.type === InvoiceManagerType.ConsolShipment); break;
            case 3: this.invoiceTypeListViewed = this.invoiceTypeList.filter(val => val.type === InvoiceManagerType.Other); break;
        }

        console.log(index);
        console.log(this.selectedTab);
    }
}
