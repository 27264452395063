import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from '../../../core/services/api.service';

@Injectable()
export class GlobalSettingsService extends ApiService {

    getSettingByName(name: string): Observable<any> {
        return this.request({
            path: 'settings/get-settings-details',
            method: 'POST',
            body: {
                name
            }
        });
    }

    getSettingByNames(names: string[]): Observable<any[]> {
        return this.request({
            path: 'settings/get-settings-details-list',
            method: 'POST',
            body: {
                names
            }
        });
    }

    createShipmentCommissionsSetting(body: any): Observable<any> {
        return this.request({
            path: 'settings/create-shipment-commissions-setting',
            method: 'POST',
            body
        });
    }

    createUpdateSettingByName(body: any): Observable<any> {
        return this.request({
            path: 'settings/create-update-settings',
            method: 'POST',
            body
        });
    }

    createUpdateAllSettingByName(body: any): Observable<any> {
        return this.request({
            path: 'settings/update-shipment-commissions-setting',
            method: 'POST',
            body
        });
    }

    addUpdateEuVatNumber(body): Observable<any> {
        return this.request({
            path: 'eu-countries-vat-number/add-update-vat-number',
            method: 'POST',
            body: body
        })
    }

    getVatDetails(countryId: number | string = '', page: number = 1, limit: number = 100, query: string = ''): Observable<any> {
        return this.request({
            path: 'eu-countries-vat-number/get-vat-details',
            method: 'GET',
            query: {
                q: query,
                countryId: countryId,
                page: page,
                limit: limit,
            }
        });
    }

    getVatDetailsByCompany(companyId: number | string = '', page: number = 1, limit: number = 100, query: string = ''): Observable<any> {
        return this.request({
            path: 'eu-countries-vat-number/get-vat-details',
            method: 'GET',
            query: {
                q: query,
                companyId,
                page,
                limit,
            }
        });
    }

    vatNumberDelete(id): Observable<any> {
        return this.request({
            path: `eu-countries-vat-number/${id}`,
            method: 'DELETE',
        });
    }
}
