import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiService } from 'src/app/core/services/api.service';
import { ReportType } from '../../../shared/enums/report-type.enum';

@Injectable()
export class TaxReportService extends ApiService {

    getOrdersDataWithReportsType(
        page: number = 1,
        limit: number = 100,
        searchParams?: any,
        orderField: string = '',
        orderDirection: string = '',
        companyId: string = '',
    ): Observable<any> {
        return this.request({
            method: 'GET',
            path: `tax-reports/get/orders-with-report-type`,
            query: {
                limit,
                page,
                dateTo: searchParams?.dateTo,
                dateFrom: searchParams?.dateFrom,
                reportType: searchParams?.reportType,
                orderField,
                orderDirection,
                customerIds: searchParams?.customerIds ? searchParams?.customerIds : '',
                companyId,
                isIndTax: searchParams.isIndTax
            }
        });
    }

    getTaxReportRepTool(
        page: number = 1,
        limit: number = 100,
        searchParams?: any,
        orderField: string = '',
        orderDirection: string = '',
        companyId: string = '',
    ): Observable<any> {
        return this.request({
            method: 'GET',
            path: `tax-reports/get/tax-reports-rep-tool`,
            query: {
                limit,
                page,
                dateTo: searchParams?.dateTo,
                dateFrom: searchParams?.dateFrom,
                reportType: searchParams?.reportType,
                orderField,
                orderDirection,
                customerIds: searchParams?.customerIds ? searchParams?.customerIds : '',
                companyId,
                isIndTax: searchParams?.isIndTax,
                country: searchParams?.country ? searchParams?.country : ''
            }
        });
    }

    getTotoalColumnOfOrdersDataWithReportsType(
        page: number = 1,
        limit: number = 100,
        q: string = '',
        searchParams?: any,
        orderField: string = '',
        orderDirection: string = '',
        includingCancelled: boolean = false,
        companyId: string = ''
    ): Observable<any> {
        return this.request({
            method: 'GET',
            path: `tax-reports/get/column-total-orders-with-report-type`,
            query: {
                // q: q,
                limit: limit,
                page: page,
                dateTo: searchParams?.dateTo,
                dateFrom: searchParams?.dateFrom,
                reportType: searchParams?.reportType,
                orderField: orderField,
                orderDirection: orderDirection,
                customerIds: searchParams?.customerIds ? searchParams?.customerIds : '',
                includingCancelled: includingCancelled ? includingCancelled : false,
                companyId: companyId
            }
        });
    }

    public generatePdf(body): any {
        this.requestPdf({
            path: `tax-reports/download/generate-pdf`,
            method: 'POST',
            body: body
        }).subscribe(res => {
            const fileURL = URL.createObjectURL(res);
            window.open(fileURL, '_blank');
        });
    }
    public generatePdfVat(body): any {
        this.requestPdf({
            path: `tax-reports/download/generate-pdf-indvat`,
            method: 'POST',
            body: body
        }).subscribe(res => {
            const fileURL = URL.createObjectURL(res);
            window.open(fileURL, '_blank');
        });
    }

    public generateXl(body): Observable<any> {
        return this.requestFile({
            path: `tax-reports/download/generate-xlsx`,
            method: 'POST',
            body: body
        }, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet').pipe(map(res => {
            const a = document.createElement('a')
            const objectUrl = URL.createObjectURL(res)
            a.href = objectUrl
            a.download = `${ReportType[body.reportType]}_Tax_report.xlsx`;
            a.click();
            URL.revokeObjectURL(objectUrl);
        }));
    }

    public generateXlIndVat(body): Observable<any> {
        return this.requestFile({
            path: `tax-reports/download/generate-xlsx-indvat`,
            method: 'POST',
            body: body
        }, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet').pipe(map(res => {
            const a = document.createElement('a')
            const objectUrl = URL.createObjectURL(res)
            a.href = objectUrl
            a.download = `${ReportType[body.reportType]}_Tax_report.xlsx`;
            a.click();
            URL.revokeObjectURL(objectUrl);
        }));
    }

    generateReport(body): Observable<any> {
        return this.request({
            method: 'POST',
            path: `tax-reports`,
            body: body
        });
    }

    getTaxReportById(id: number): Observable<any> {
        return this.request({
            path: `tax-reports/${id}`,
            method: 'GET',
        });
    }

    downloadTaxReport(id: number): Observable<any> {
        return this.request({
            path: `tax-reports/${id}`,
            method: 'POST',
        });
    }

    public downloadInvoice(orderId): Observable<any> {
        return this.requestPdf({
            path: `sale-orders/download`,
            method: 'POST',
            body: {
                invoiceId: orderId,
                type: 'PDF'
            }
        }).pipe(map(res => {
            const fileURL = URL.createObjectURL(res);
            window.open(fileURL, '_blank');
        }));
    }

    public downloadSalesInvoices(body): Observable<any> {
        return this.requestFile({
            path: `tax-reports/download/download_sales_invoices`,
            method: 'POST',
            body: body
        }).pipe(map(res => {
            const a = document.createElement('a')
            const objectUrl = URL.createObjectURL(res)
            a.href = objectUrl
            a.download = `sale_invoices_${body.dateFrom.toString()}_${body.dateTo.toString()}.zip`;
            a.click();
            URL.revokeObjectURL(objectUrl);
        }));

    }

    public downloadSalesInvoicesIndVat(body): Observable<any> {
        return this.requestFile({
            path: `tax-reports/download/download_sales_invoices_indvat`,
            method: 'POST',
            body: body
        }).pipe(map(res => {
            const a = document.createElement('a')
            const objectUrl = URL.createObjectURL(res)
            a.href = objectUrl
            a.download = `sale_invoices_${body.dateFrom.toString()}_${body.dateTo.toString()}.zip`;
            a.click();
            URL.revokeObjectURL(objectUrl);
        }));

    }
    //#endregion
}
