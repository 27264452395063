import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'eci-rating-bar',
    templateUrl: './rating-bar.component.html',
    styleUrls: ['./rating-bar.component.scss']
})
export class RatingBarComponent implements OnInit {
    _rating: number;
    get rating(): number {
        return this._rating;
    }

    @Input() set rating(value: number) {
        this._rating = value;
        this.partStarPosition = Math.floor(value);
    }

    @Input() public starLength: number = 5;
    @Output() onRatingChange: EventEmitter<number> = new EventEmitter<number>();

    public partStarPosition: number = 0;
    @Input() active: boolean = false;

    constructor() {
    }

    ngOnInit(): void {
    }
    /**
     * Generate array of ratings: set to 1 if you want to fill the star, and 0 if the star is empty
     * */
    public getStars(): boolean[] {
        return Array.from({length: this.starLength}, (v, i) => (i < this.rating));
    }

    /**
     * Checking the current rating and returning part of the number (1 number after the semicolon)
     **/
    public fractionDigits() {
        return (Number(this.rating) === 0) ? '' : Number(this.rating)
            .toFixed(1);
    }

    /**
     * Set star
     **/
    public onRating(e: MouseEvent, i: number) {
        if (this.active) {
            ++i;
            this.onRatingChange.emit(i);
            this.rating = i;
        }
        e.stopPropagation();
    }

}
