import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from 'src/app/core/services/api.service';
import { LocalStorageService } from 'src/app/core/services/local-storage.service';
import { NotificationService } from 'src/app/core/services/notification.service';
import { IPayinHistoryItem } from '../models/payin-details-item.interface';

@Injectable()
export class PayoneerPayinHistoryService extends ApiService {

    constructor(
        public http: HttpClient,
        public storageBrowser: LocalStorageService,
        private notify: NotificationService
    ) {
        super(http, storageBrowser);
    }
  
    getPayinHistory(page: number = 1, limit: number = 100, q: string = '', searchParams?: any, orderField: string = '', orderDirection: string = '', isAllocatedStatus: string = ''): Observable<any> {
        return this.request({
            method: 'GET',
            path: `payoneer-payin-history`,
            query: {
                q: q,
                limit: limit,
                page: page,
                customerName: searchParams?.customerName,
                isAllocated: isAllocatedStatus,
                dateTo: searchParams?.dateTo,
                dateFrom: searchParams?.dateFrom,
                orderField: orderField,
                orderDirection: orderDirection
            }
        });
    }
    CreatePayinHistory(body): Observable<any> {
        return this.request({
            path: `payoneer-payin-history/vban`,
            method: 'POST',
            body: body
        });
    }
    
    createfileData(body, currentTotalValue): Observable<any> {
        return this.request({
            path: `payoneer-payin-history/facilitator`,
            method: 'POST',
            body: {data: body, currentTotalValue }
        })
    }

    updateDeallocated(vbanAccount: any, companyId: any): Observable<any> {
        return this.request({
            path: `payoneer-payin-history/update-de-allocate`,
            body:{vbanAccount:vbanAccount,companyId:companyId},
            method: 'POST',
        });
    }

    getPayoutLogsHistory(id: number, page: number = 1, limit: number = 100, q: string = '', searchParams?: any, orderField: string = '', orderDirection: string = ''): Observable<any> {
        return this.request({
            method: 'GET',
            path: `payoneer-payout-history-logs/${id}`,
            query: {
                q: q,
                limit: limit,
                page: page,
                payoutStatus: searchParams?.payoutStatus,
                dateTo: searchParams?.dateTo,
                dateFrom: searchParams?.dateFrom,
                orderField: orderField,
                orderDirection: orderDirection
            }
        });
    }

    getPayoutHistoryById(id: number): Observable<IPayinHistoryItem> {
        return this.request({
            path: `payoneer-payout-history/${id}`,
            method: 'GET',
        });
    }

    addPayoutHistory(body): Observable<any> {
        return this.request({
            path: `payoneer-payout-history/`,
            method: 'POST',
            body: body
        });
    }

    deleteVbanById(id: number | number[]): Observable<any> {
        return this.request({
            path: `payoneer-payout-history/${id}`,
            method: 'DELETE',
        });
    }
}